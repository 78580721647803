import React, {useContext} from 'react'
import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {CollectionContext} from "../container"
import {useTranslation} from "react-i18next"

export default function CardTitle({error}) {
	const {t} = useTranslation()
	const pageSub = useContext(CollectionContext)
	let {state, setState} = useSubscription(pageSub)
	const {title, type_id, description} = state
	return (
		<Card sectioned>
			<FormLayout>
				<TextField
					label={t('Title')}
					value={title}
					onChange={v => setState({title: v})}
				/>
				{/*<TextField*/}
				{/*	label={t('Type ID')}*/}
				{/*	value={type_id}*/}
				{/*	onChange={v => setState({type_id: v.toUpperCase()})}*/}
				{/*/>*/}
				<TextField
					label={t('Description')}
					value={description}
					multiline={3}
					onChange={v => setState({description: v})}
				/>
			</FormLayout>
		</Card>
	)
}
