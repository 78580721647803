import React from 'react'
import {EmptyState} from "@shopify/polaris"
import empty from 'images/empty-1.svg'
import styled from 'styled-components'

const Empty = styled.div`
	.Polaris-EmptyState__Image {
		margin-left: 0 !important;
	}
	.Polaris-EmptyState__ImageContainer {
		width: 50%;
	}
`

export default function EmptyPage({title, description, primaryAction, image}) {
	return (
		<Empty>
			<EmptyState
				heading={title}
				action={primaryAction}
				image={image || empty}
			>
				{description && <p>{description}</p>}
			</EmptyState>
		</Empty>
	)
}