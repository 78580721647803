import React, {useState} from 'react'
import {Modal, Select, Card, FormLayout, TextField} from "@shopify/polaris"
import ViewCustomer from "pages/orders/views/customer"
import ViewSeller from "pages/orders/views/seller"
import ViewSummary from "pages/orders/views/summary"
import {createLabel, updateOrder} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {useTranslation} from "react-i18next"
import {navigate} from "@reach/router"

export default function ReviewOrder({order, handleToggle, forceUpdate, open}) {
	const [saving, setSaving] = useState(false)
	const [shipping_carrier, setShippingCarrier] = useState('multrans')
	const {t} = useTranslation()
	const {order_status, _id, shipping_tracking_number, title} = order
	const handleConfirm = () => {
		setSaving(true)
		updateOrder({
			order_status: 'printing',
			shipping_carrier: shipping_carrier,
			_id: _id
		}).then(res => {
			console.log(res, 'confirm res')
			if (res.data.success) {
				forceUpdate()

				showToast({
					message: 'Xác nhận đơn hàng thành công'
				})
				if (shipping_carrier === 'multrans') {
					createLabel({title: title}).then(res => {
						console.log(res, 'create label res')
					})
				}
			} else {
				showToast({
					message: 'Xác nhận đơn hàng không thành công, vui lòng thử lại',
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
			handleToggle()
		})
	}

	const handleSave = (status) => {
		setSaving(true)
		updateOrder({
			order_status: status,
			_id: _id
		}).then(res => {
			console.log(res, 'confirm res')
			if (res.data.success) {
				showToast({
					message: 'Cập nhật thành công'
				})
			} else {
				showToast({
					message: 'Cập nhật không thành công, vui lòng thử lại',
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
			handleToggle()
		})
	}

	const secondaryActions = [
		{
			content: 'Đóng',
			onAction: handleToggle,
			plain: true
		}
	]

	let primaryAction

	if (order_status === 'open') {
		primaryAction = {
			content: 'Xác nhận đơn hàng',
			onAction: handleConfirm,
			loading: saving,
			disabled: saving || !shipping_carrier
		}
	}
	if (order_status === 'shipping') {
		primaryAction = {
			content: 'Giao thành công',
			onAction: () => handleSave('shipped'),
			loading: saving,
			disabled: saving
		}
		secondaryActions.push({
			content: 'Giao không thành công',
			onAction: () => handleSave('incompleted'),
			outline: true,
			loading: saving,
			disabled: saving
		})
	}
	return (
		<Modal
			open={open}
			onClose={handleToggle}
			title={'Chi tiết đơn hàng'}
			primaryAction={primaryAction}
			secondaryActions={secondaryActions}
		>
			<Modal.Section>
				{order_status !== 'shipping' && <ViewSummary quickView={true} order={order}/>}
				{order_status !== 'shipping' && <ViewSeller order={order}/>}
				<ViewCustomer order={order}/>
				{/*<Card title={order_status === 'open' ? 'Đơn vị vận chuyển' : 'Vận chuyển'}>*/}
				{/*	<Card.Section>*/}
				{/*		<FormLayout>*/}
				{/*			<FormLayout.Group>*/}
				{/*				<Select*/}
				{/*					label={'Đơn vị vận chuyển'}*/}
				{/*					labelHidden={order_status === 'open'}*/}
				{/*					value={shipping_carrier}*/}
				{/*					options={[*/}
				{/*						{label: "Chọn đơn vị vận chuyển", value: ''},*/}
				{/*						{label: "Multrans", value: 'multrans'},*/}
				{/*						{label: "Other", value: 'other'}*/}
				{/*					]}*/}
				{/*					onChange={v => setShippingCarrier(v)}*/}
				{/*				/>*/}
				{/*				{shipping_tracking_number && <TextField*/}
				{/*					label={'Tracking number'}*/}
				{/*					value={shipping_tracking_number}*/}
				{/*					disabled*/}
				{/*					labelAction={shipping_tracking_number ? {*/}
				{/*						content: 'Xem tracking',*/}
				{/*						onAction: () => window.open(`https://webtrack.dhlglobalmail.com/?trackingnumber=${shipping_tracking_number}`, '_blank')*/}
				{/*					} : undefined}*/}
				{/*				/>}*/}
				{/*			</FormLayout.Group>*/}
				{/*		</FormLayout>*/}
				{/*	</Card.Section>*/}
				{/*</Card>*/}
			</Modal.Section>
		</Modal>
	)
}
