import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchCustomer, initialState, CustomerContext, updateCustomer, deleteCustomers} from "./container"
import {showConfirm} from "plugins/confirm"
import CardOverview from "./components/cardOverview"
import CardAddresses from "./components/cardAddresses"
import CardLogin from "./components/cardLogin"
import CardID from "./components/cardID"
import Can from "authentication/can"

const EditCustomer = (props) => {
	const pageSub = createSubscription({id: props.customerID, ...initialState})

	return <CustomerContext.Provider value={pageSub}>
		<EditCustomerView {...props}/>
	</CustomerContext.Provider>

}

const EditCustomerView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(CustomerContext)
	let {setState, state} = useSubscription(pageSub)
	useEffect(() => {
		document.title = t('Cập nhật người bán hàng')
		const fetchData = async () => {
			const result = await fetchCustomer(props.customerID)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.customerID])

	const handleSave = () => {
		let error = []
		const required = ['first_name', 'email', 'phone', 'seller_id']
		required.map(f => {
			if (!state[f]) error.push(f)
		})

		if (state.seller_id && state.seller_id.length < 3) {
			error.push('seller_id')
			setMandatoryFields(error)
			return showToast({
				message: 'Mã khách hàng tối thiểu là 3 ký tự',
				error: true
			})
		}

		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}
		setSaving(true)
		updateCustomer(state).then((res) => {
			if (res.data.success) {
				return showToast({
					message: 'Cập nhật thành công'
				})
			} else {
				return showToast({
					message: res.data.message,
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleDelete = () => {
		const {_id, title} = state
		showConfirm({
			title: `Delete ${title}?`,
			message: t('Are you sure you want to delete this seller? This can\'t be undone.'),
			confirm: t('Delete'),
			cancel: t('Cancel'),
			danger: true
		}).then(res => {
			if (res) {
				deleteCustomers([_id], true)
			}
		})
		return false
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="customers:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<StickyActions
											actions={(
												<ButtonGroup>
													<Button onClick={() => navigate('/sellers')}>{t('Discard')}</Button>
													<Button
														primary
														onClick={handleSave}
														loading={saving}
													>
														{t('Save')}
											</Button>
												</ButtonGroup>
											)}
										/>
										<Page
											title={t('Edit seller')}
											breadcrumbs={[{content: t('Customers'), onAction: () => navigate('/sellers')}]}
										>
											<Layout>
												<Layout.Section>
													<CardLogin error={mandatoryFields}/>
													<CardID error={mandatoryFields}/>
													<CardOverview error={mandatoryFields}/>
													<CardAddresses error={mandatoryFields}/>
												</Layout.Section>
											</Layout>
											<PageActions
												primaryAction={{
													content: t('Save'),
													onAction: handleSave,
													loading: saving
												}}
												secondaryActions={[
													{
														content: t('Delete'),
														destructive: true,
														onAction: handleDelete

													}
												]}
											/>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
						no={() => <Redirect to={`/sellers/view/${props.customerID}`} noThrow />}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditCustomer
