const rules = {
	admin: {
		// dynamic: {
		// 	"posts:edit": ({userId, postOwnerId}) => {
		// 		if (!userId || !postOwnerId) return false
		// 		return userId === postOwnerId
		// 	}
		// }
		static: [
			"orders:delete"
		]
	},
	general_manager: {
		static: [
			"posts:list",
			"posts:create",
			"posts:edit",
			"posts:delete",
			"documents:create",
			"documents:edit",
			"documents:delete",
			"documents:list",
			"media:create",
			"media:edit",
			"media:delete",
			"media:list",
			"blogs:list",
			"blogs:create",
			"blogs:edit",
			"blogs:delete",
			"pages:list",
			"pages:create",
			"pages:edit",
			"pages:delete",
			"products:list",
			"products:create",
			"products:edit",
			"products:delete",
			"collections:list",
			"collections:create",
			"collections:edit",
			"collections:delete",
			"inventory:list",
			"inventory:update",
			"customers:list",
			"customers:create",
			"customers:edit",
			"customers:delete",
			"orders:list",
			"orders:create",
			"orders:edit",
			"orders:delete",
			"discounts:list",
			"discounts:create",
			"discounts:edit",
			"discounts:delete",
			"videos:list",
			"videos:create",
			"videos:edit",
			"videos:delete",
			"galleries:list",
			"galleries:create",
			"galleries:edit",
			"galleries:delete",
			"reviews:list",
			"reviews:delete",
			"landing:home",
			"landing:about",
			"landing:contact",
			"settings:files",
			"users:list",
			"users:create",
			"users:edit",
			"users:getSelf",
			"contacts:list",
			"contacts:delete",
			"subscribers:list",
			"subscribers:delete",
			"tags:list",
			"tags:create",
			"tags:edit",
			"tags:delete",
		]
	},
	orders_manager: {
		static: [
			"products:list",
			"products:create",
			"products:edit",
			"products:delete",
			"tags:list",
			"tags:create",
			"tags:edit",
			"tags:delete",
			"collections:list",
			"collections:create",
			"collections:edit",
			"collections:delete",
			"customers:list",
			"customers:create",
			"customers:edit",
			"customers:delete",
			"orders:list",
			"orders:create",
			"orders:view",
			"orders:edit",
			"orders:confirm",
			"profile:update"
		]
	},
	printer: {
		static: [
			"orders:list",
			"orders:view",
			"items:list",
			"items:edit",
			"profile:update"
		]
	},
	finisher: {
		static: [
			"orders:list",
			"orders:view",
			"items:list",
			"items:edit",
			"profile:update"
		]
	},
	warehouse_manager: {
		static: [
			"orders:list",
			"orders:view",
			"profile:update"
		]
	},
	seller: {
		static: [
			"orders:list",
			"orders:create",
			"orders:edit",
			"orders:delete",
			"profile:update"
		]
	},
	accountant: {
		static: [
			"orders:list",
			"orders:view",
			"customers:list",
			"customers:view",
			"profile:update"
		]
	},
	visitor: {
		static: []
	}
}

export default rules
