import React, {useContext, useEffect, useState} from 'react'
import {Modal, FormLayout, TextField, Spinner} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {OrderContext, updateOrder} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {useSubscription} from "global-state-hook"
import {fetchCustomersList} from "pages/customers/container"
import AdvanceSelect from "components/advanceSelect"

const PickSeller = ({open, handleToggle}) => {
	const [loading,setLoading] = useState(false)
	const orderSub = useContext(OrderContext)
	const {setState, state} = useSubscription(orderSub)
	const [items, setItems] = useState([])
	const [data, setData] = useState({
		id: '',
		first_name: '',
		last_name: '',
		phone: '',
		email: '',
		seller_id: ''
	})
	const handleSave = () => {
		const {seller_id, id, first_name, last_name, phone, email} = data

		setState({
			seller_id: seller_id,
			seller: {
				id: id,
				first_name: first_name,
				last_name: last_name,
				phone: phone,
				email: email
			}
		})
		handleToggle()
	}

	useEffect(() => {
		const fetchData = async () => {
			const result = await fetchCustomersList({limit: 200})
			console.log(result, 'result')
			if (result && result.data && result.data.length) {
				setItems(result.data)
			}
		}
		fetchData()
	}, [])

	console.log(items, 'items')

	const {t} = useTranslation()
	return <div>
		<Modal
			open={open}
			onClose={handleToggle}
			title="Chọn seller"
			primaryAction={{
				content: 'Chọn',
				onAction: handleSave,
				disabled: !data.id
			}}
			secondaryActions={[
				{
					content: 'Hủy',
					onAction: handleToggle,
				},
			]}
		>
			<Modal.Section>
				<FormLayout>
					<AdvanceSelect
						allowClear
						label={t('Select a seller')}
						value={data.id}
						onChange={v => {
							const found = items.find(item => item._id === v)
							if (found) setData({...found})
						}}
						showSearch
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						options={items.map(item => {
							return {
								label: `${item.first_name} ${item.last_name} - ${item.seller_id}`,
								value: item._id
							}
						})}
					/>
				</FormLayout>
			</Modal.Section>
		</Modal>
	</div>
}
export default PickSeller
