import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Layout, Page, PageActions,} from "@shopify/polaris"
import {navigate, Redirect} from "@reach/router"
import {AuthConsumer} from "authentication/auth"
import {useTranslation} from "react-i18next"
import {createSubscription, useSubscription} from "global-state-hook"
import {GeneralContext, generalInitialState} from "pages/settings/container"
import CardInformation from "./components/cardInformation"
import {updateSetting, fetchSetting} from "pages/settings/container"
import {showToast} from "plugins/toast"

const GeneralSettings = () => {

	const pageSub = createSubscription(generalInitialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('General settings')
	})

	return <GeneralContext.Provider value={pageSub}>
		<SettingsView/>
	</GeneralContext.Provider>
}

const SettingsView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const pageSub = useContext(GeneralContext)
	let {setState, state} = useSubscription(pageSub)

	useEffect(() => {
		const fetchData = async () => {
			const result = await fetchSetting({type: 'general'})
			setState({
				...generalInitialState,
				...result
			})
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleUpdate = () => {
		setSaving(true)
		updateSetting(state).then(() => {
			showToast({
				message: t('Settings updated')
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: 'Something went wrong, please try again',
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	return (
		<AuthConsumer>
			{({authenticated}) => {
				return authenticated ? (
					<Fragment>
						<Page
							title={t('Settings')}
							primaryAction={{
								content: t('Update'),
								onAction: handleUpdate,
								loading: saving
							}}
						>
							<Layout>
								<CardInformation />
							</Layout>
							<PageActions
								primaryAction={{
									content: t('Update'),
									onAction: handleUpdate,
									loading: saving
								}}
							/>
						</Page>
					</Fragment>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default GeneralSettings
