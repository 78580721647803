import React, {Fragment, useEffect, useState} from 'react'
import {Modal, Card, Link, Spinner, Banner, Badge} from "@shopify/polaris"
import {fetchOrderByTracking, updateOrder} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {useTranslation} from "react-i18next"
import styled from "styled-components"
import Table from "components/table"
import OrderStatusBadge from "modules/orderStatus"
const Wrapper = styled.div`
	position: relative;
	.loading {
		background: #fff;
		z-index: 111;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Polaris-Banner {
		box-shadow: none;
		border-radius: 0;
		&:after {
			box-shadow: none;
			border-radius: 0;
		}
	}
`

export default function UpdateOrderStatus({code, userRole, active, handleToggle}) {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [data, setData] = useState({})

	const {t} = useTranslation()
	useEffect(() => {
		const fetchData = async () => {
			const result = await fetchOrderByTracking(code)
			if (result.success && result.data) {
				setData(result.data)
				setReady(true)
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, active])
	const {order_status, _id, title, shipping_carrier, shipping_address, shipping_tracking_number} = data
	const handleSave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		let newStatus = 'shipping'
		setSaving(true)
		updateOrder({order_status: newStatus, _id: _id}).then((res) => {
			showToast({
				message: 'Cập nhật thành công'
			})
			setTimeout(() => {
				return handleClose()
			}, 200)
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		setData({})
		setReady(false)
		handleToggle()
	}

	let fullname, fullAddress
	if (data && shipping_address) {
		const {first_name, last_name, phone, city, address, address_2, state, country_code, zip_code} = shipping_address
		fullname = `${first_name} ${last_name}`
		fullAddress = [address, address_2, city, state, country_code, zip_code].filter(v => v !== '')
	}

	return (
		<Fragment>
			<Modal
				title={`Chi tiết đơn hàng ${title && title}`}
				open={active}
				onClose={handleClose}
				primaryAction={order_status === 'storing' ? {
					content: 'Chuyển đi',
					onAction: handleSave,
					loading: saving
				} : undefined}
				secondaryActions={[
					{
						content: 'Đóng',
						onAction: handleClose,
						disabled: saving
					}
				]}
			>
				<Wrapper>
					{!ready && (
						<div className="loading">
							<Spinner accessibilityLabel="Spinner example" size="large" color="teal"/>
						</div>
					)}
					<Card>
						{order_status === 'storing' && (
							<Card.Section>
								<Table bordered>
									<tbody>
									<tr>
										<th>Mã đơn hàng:</th>
										<td>
											<div style={{display: 'flex'}}>
												<Link url={`/orders/view/${_id}`} external>{title}</Link> <OrderStatusBadge order_status={order_status}/>
											</div>
										</td>
									</tr>
									<tr>
										<th>Đơn vị vận chuyển:</th>
										<td>{shipping_carrier.toUpperCase()}</td>
									</tr>
									<tr>
										<th>Địa chỉ nhận hàng:</th>
										<td>
											<p><strong>{fullname}</strong> - {shipping_address.phone}</p>
											<p>{fullAddress.join(', ')}</p>
										</td>
									</tr>
									<tr>
										<th>Mã tracking:</th>
										<td><Badge>{shipping_tracking_number}</Badge></td>
									</tr>
									</tbody>
								</Table>
							</Card.Section>
						)}
						{order_status !== 'storing' && (
							<Banner title="Không thể chuyển đi" status={'warning'}>
								<p>Đơn hàng này chưa được hoàn thành, vui lòng kiểm tra lại.</p>
							</Banner>
						)}
					</Card>
				</Wrapper>
			</Modal>
		</Fragment>
	)
}
