import React, {Fragment, useEffect, useState} from 'react'
import {Modal, Card, FormLayout, Spinner, TextField, Form, Banner, Link} from "@shopify/polaris"
import {fetchOrderItem, updateOrderItem} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {useTranslation} from "react-i18next"
import styled from "styled-components"
import Table from 'components/table'
import ItemStatusBadge from "modules/itemStatus"
import OrderStatusBadge from "modules/orderStatus"
const Wrapper = styled.div`
	position: relative;
	.loading {
		background: #fff;
		z-index: 111;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Polaris-Banner {
		box-shadow: none;
		border-radius: 0;
		&:after {
			box-shadow: none;
			border-radius: 0;
		}
	}
`

export default function FinishItem({code, active, handleToggle}) {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [data, setData] = useState({})
	const [number, setNumber] = useState(1)

	const {t} = useTranslation()
	useEffect(() => {
		// document.addEventListener('keypress', keypressHandler)
		if (code) {
			handleFetchData(code)
		}
	}, [active, code])

	const handleSave = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setSaving(true)
		console.log(code, 'code')
		if (!code) return false
		updateOrderItem({item_sku: code, quantity: number}).then((res) => {
			console.log(res, 'order res')
			showToast({
				message: 'Item đã được cập nhật'
			})
			setTimeout(() => {
				return handleClose()
			}, 200)
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		setData({})
		setReady(false)
		// document.removeEventListener('keypress', keypressHandler)
		handleToggle()
	}
	const handleFetchData = async (item_sku) => {
		const result = await fetchOrderItem(item_sku)
		if (result.success) {
			setData(result.data)
			setReady(true)
		} else {
			handleClose()
			return showToast({
				message: result.message,
				error: true
			})
		}
		return false
	}
	const keypressHandler = (event) => {
		if (event.keyCode === 13) {
			handleSave()
		}
	}

	const {item_sku, quantity, status, order_id, order_title, item_id, quantity_finished, title, _id, order_status} = data

	const itemQuantity = parseInt(quantity)
	return (
		<Fragment>
			<Modal
				title={`Xác nhận đã hoàn thiện Item ${item_sku ? item_sku : ''}`}
				open={active}
				onClose={handleClose}
				primaryAction={status === 'printed' ? {
					content: 'Xác nhận',
					disabled: saving || number < 1,
					loading: saving,
					onAction: handleSave
				} : undefined}
				secondaryActions={[
					{
						content: 'Đóng',
						onAction: handleToggle
					}
				]}
			>
				<Form onSubmit={handleSave}>
					<Wrapper>
						{!ready && (
							<div className="loading">
								<Spinner accessibilityLabel="Spinner example" size="large" color="teal"/>
							</div>
						)}
						<Card>
							{status === 'printed' && (
								<Fragment>
									<Card.Section>
										<Table bordered>
											<tbody>
											<tr>
												<th width={100}>Đơn hàng:</th>
												<td colSpan={3}>
													<div style={{display: 'flex'}}>
														<Link external url={`/orders/edit/${order_id}`}>{order_title}</Link>
														<OrderStatusBadge order_status={order_status}	/>
													</div>
												</td>
											</tr>
											<tr>
												<th width={100}>Mã Item:</th>
												<td colSpan={3}>
													<div style={{display: 'flex'}}>
														<Link external url={`/items/edit/${_id}`}>{item_sku}</Link>
														<ItemStatusBadge status={status} />
													</div>
												</td>
											</tr>
											<tr>
												<th width={100}>Sản phẩm:</th>
												<td colSpan={3}>{title} ({item_id})</td>
											</tr>
											<tr>
												<th width={100}>Số lượng:</th>
												<td>{quantity} cái</td>
												<th width={100}>Đã hoàn thiện:</th>
												<td>{quantity_finished} cái</td>
											</tr>
											</tbody>
										</Table>
									</Card.Section>
									<Card.Section>
										<FormLayout>
											<FormLayout.Group>
												<TextField
													label={'Đã hoàn thiện'}
													type={'number'}
													suffix={`/${quantity} cái`}
													max={itemQuantity}
													min={1}
													autoFocus
													value={number.toString()}
													onChange={v => {
														if (v >= itemQuantity) v = quantity
														setNumber(parseInt(v))
													}}
												/>
												<div />
											</FormLayout.Group>
										</FormLayout>
									</Card.Section>
								</Fragment>
							)}
							{status === 'pending' && (
								<Banner title="Chưa in xong" status={'warning'}>
									<p>Item này chưa in xong, vui lòng kiểm tra lại.</p>
								</Banner>
							)}
							{status === 'done' && (
								<Banner title="Đã hoàn thiện" status={'success'}>
									<p>Item này đã được hoàn thiện xong</p>
								</Banner>
							)}
						</Card>
					</Wrapper>
				</Form>
			</Modal>
		</Fragment>
	)
}
