import React from 'react'
import styled from 'styled-components'
import {Spinner} from '@shopify/polaris'

const Wrapper = styled.div`
	position: relative;
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,.7);
		z-index: 9;
		display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.Polaris-DropZone__Container {
		> .Polaris-Stack {
			padding: 1rem;
		}
	}
	.Polaris-Thumbnail--sizeLarge {
		width: ${e => e.multiple ? '8rem !important' : '100%'};
		max-height: ${e => e.height ? e.height : '100%'};
	}
	.Polaris-Stack--vertical {
		flex-wrap: ${e => e.multiple ? 'wrap' : 'nowrap'};
	}
	.Polaris-ButtonGroup {
		justify-content: center;
		margin-top: 1rem;
	} 
	.Polaris-Thumbnail:after {
		box-shadow: none;
	}
	.Polaris-TextContainer {
		margin-top: 5px;
	}
	
`

const UploadContainer = ({loading, multiple, children, height}) => {
	return (
		<Wrapper multiple={multiple || undefined} height={height}>
			{loading && (
				<div className="overlay">
					<Spinner size="large" color="teal" />
				</div>
			)}
			{children}
		</Wrapper>
	)
}

export default UploadContainer
