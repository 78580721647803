import React, {Fragment} from 'react'
import {Navigation} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import NavSection from "modules/navSection"
import {AuthConsumer} from "authentication/auth"
import {
	HomeMajorMonotone,
	ProfileMinor,
	CustomerPlusMajorMonotone,
	OrdersMajorMonotone,
	CustomersMajorMonotone,
	ProductsMajorMonotone,
	PrintMajorMonotone,
	LabelPrinterMajorMonotone,
	PackageMajorMonotone,
	CategoriesMajorMonotone,
	CollectionsMajorMonotone
} from '@shopify/polaris-icons'

const AppNavigation = () => {
	const {t} = useTranslation()
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				const mainItems = [
					{
						label: t('Dashboard'),
						icon: HomeMajorMonotone,
						url: '/'
					}
				]
				const manageItems = []
				if (['orders_manager', 'admin'].includes(user.role)) {
					manageItems.push({
						label: t('Thẻ tag'),
						icon: CollectionsMajorMonotone,
						url: '/manage/tags',
					})
				}
				if (user.role === 'admin') {
					manageItems.push({
						label: t('Nhân viên'),
						icon: CustomerPlusMajorMonotone,
						url: '/manage/users',
					})
				}
				if (['orders_manager', 'admin', 'accountant'].includes(user.role)) {
					mainItems.push({
						label: t('Orders'),
						icon: OrdersMajorMonotone,
						url: '/orders',
						subNavigationItems: [
							{
								label: t('All'),
								url: '/orders',
							},
							{
								label: t('Pending'),
								url: '/orders/pending',
							},
							{
								label: t('Đang in'),
								url: '/orders/printing',
							},
							{
								label: t('Đang hoàn thiện'),
								url: '/orders/packing',
							},
							{
								label: t('Đang lưu kho'),
								url: '/orders/storing',
							},
							{
								label: t('Orders shipping'),
								url: '/orders/shipping',
							},
							{
								label: t('Thành công'),
								url: '/orders/shipped',
							},
							{
								label: t('Không thành công'),
								url: '/orders/undelivered',
							},
							{
								label: t('Orders canceled'),
								url: '/orders/canceled',
							}
						]
					})
				}
				if (['orders_manager', 'admin'].includes(user.role)) {
					mainItems.push({
						label: t('Sellers'),
						icon: CustomersMajorMonotone,
						url: '/sellers'
					})
					mainItems.push({
						label: t('Products'),
						icon: ProductsMajorMonotone,
						url: '/products',
						subNavigationItems: [
							{
								label: t('All'),
								url: '/products',
							},
							{
								label: t('Types'),
								url: '/types',
							}
						]
					})
				}
				if (user.role === 'accountant') {
					mainItems.push({
						label: t('Sellers'),
						icon: CustomersMajorMonotone,
						url: '/sellers'
					})
				}
				if (['finisher', 'printer', 'warehouse_manager'].includes(user.role)) {
					mainItems.push({
						label: t('Orders'),
						icon: OrdersMajorMonotone,
						url: '/orders',
						subNavigationItems: [
							{
								label: t('All'),
								url: '/orders',
							},
							{
								label: t('Pending'),
								url: '/orders/pending',
							},
							{
								label: t('Đang in'),
								url: '/orders/printing',
							},
							{
								label: t('Đang hoàn thiện'),
								url: '/orders/packing',
							},
							{
								label: t('Đang lưu kho'),
								url: '/orders/storing',
							},
							{
								label: t('Orders shipping'),
								url: '/orders/shipping',
							}
						]
					})
				}

				return authenticated ? (
					<Navigation location="/">
						<NavSection
							separator
							items={mainItems}
						/>
						{['printer', 'admin', 'finisher'].includes(user.role) && (
							<NavSection
								separator
								title={'Sản xuất'}
								items={[
									{
										label: t('Tất cả'),
										icon: CategoriesMajorMonotone,
										url: '/items'
									},
									{
										label: t('Chờ in'),
										icon: LabelPrinterMajorMonotone,
										url: '/items/pending'
									},
									{
										label: t('Đã in'),
										icon: PrintMajorMonotone,
										url: '/items/printed'
									},
									{
										label: t('Đã hoàn thiện'),
										icon: PackageMajorMonotone,
										url: '/items/done'
									}
								]}
								fill={!['orders_manager', 'admin'].includes(user.role)}
							/>
						)}
						{['orders_manager', 'admin'].includes(user.role) && <NavSection
							items={manageItems}
							title={t('Manage')}
							separator
							fill
						/>}
						<NavSection
							items={[
								{
									label: t('Tài khoản'),
									icon: ProfileMinor,
									url: '/manage/profile'
								}
							]}
						/>
					</Navigation>
				) : null
			}}
		</AuthConsumer>
	)
}
export default AppNavigation
