import React from 'react'
import styled from 'styled-components'
import {Icon} from "@shopify/polaris"
import {ImageMajorMonotone, PlayCircleMajorMonotone} from "@shopify/polaris-icons"
import {getImageUrl} from "../helpers/utils"

const Thumb = styled.div`
    border-radius: 0;
    width: ${e => e.large ? '10rem' : '5rem'};
    height: ${e => e.large ? '10rem' : '5rem'};
    display: flex;
    border: .1rem solid var(--p-border-subdued,#dfe3e8);
    background: #f9fafb;
    fill: #c4cdd5;
    path {
    	fill: currentColor;
    }
    img {
    	width: 100%;
    }
    .Polaris-Icon {
        width: ${e => e.large ? '3.5rem' : '2rem'};
        height: ${e => e.large ? '3.5rem' : '2rem'};
    }
`
const Media = ({img, large = false, video = false}) => (
	<Thumb large={large} className={'Polaris-Avatar'}>
		{img ? (
			<img src={getImageUrl(img,'s')} alt=""/>
		) : <Icon source={video ? PlayCircleMajorMonotone : ImageMajorMonotone}/>}
	</Thumb>
)
export default Media