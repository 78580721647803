import React from 'react'
import {Card, FormLayout, TextContainer, TextStyle} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {Link} from "@reach/router"

export default function ViewSeller({order}) {
	const {seller, seller_id, _id} = order
	const {t} = useTranslation()
	const fullname = [seller.first_name, seller.last_name].filter(v => v !== '')

	return (
		<Card title={t('Seller')}>
			<Card.Section>
				{seller.id ? (
					<TextContainer>
						<Link to={'/sellers/edit/' + seller.id}>{fullname.join(' ')} ({seller_id})</Link> - {seller.email}
						<p>{t('Phone')}: {seller.phone ? seller.phone : t('No phone number')}</p>
					</TextContainer>
				) : <p>Chưa chọn seller</p> }
			</Card.Section>
		</Card>
	)
}
