import React from 'react'
import {Badge} from "@shopify/polaris"
import styled from 'styled-components'
import {ITEM_STATUS} from "helpers/defines"

const Wrapper = styled.div`
	display: inline-block;
	.Polaris-Badge {
		&--statusCritical {
			background: var(--p-text-critical,#bf0711);
			span {
				color: #fff;
			}
		}
	}
`

export default function ItemStatusBadge({status}) {
	let itemProgress, itemStatus
	switch (status) {
		case 'printed':
			itemStatus = ''
			itemProgress = 'complete'
			break
		case 'done':
			itemStatus = 'success'
			itemProgress = 'complete'
			break
		default:
			itemStatus = 'attention'
			itemProgress = 'inomplete'
			break
	}
	return (
		<Wrapper>
			<Badge progress={itemProgress} status={itemStatus}>{ITEM_STATUS[status]}</Badge>
		</Wrapper>
	)
}
