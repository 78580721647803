import React from 'react'
import {Badge} from "@shopify/polaris"
import styled from 'styled-components'
import {ORDER_STATUS} from "helpers/defines"

const Wrapper = styled.div`
	.Polaris-Badge {
		&--statusCritical {
			background: var(--p-text-critical,#bf0711);
			span {
				color: #fff;
			}
		}
	}
`

export default function OrderStatusBadge({order_status}) {
	let orderProgress, orderStatus
	switch (order_status) {
		case 'open':
			orderProgress = 'incomplete'
			orderStatus = 'attention'
			break
		case 'processing':
		case 'printing':
			orderProgress = 'incomplete'
			orderStatus = 'info'
			break
		case 'packing':
		case 'finishing':
		case 'storing':
			orderProgress = 'partiallyComplete'
			orderStatus = 'info'
			break
		case 'shipping':
			orderProgress = 'partiallyComplete'
			orderStatus = 'info'
			break
		case 'shipped':
			orderProgress = 'complete'
			orderStatus = 'success'
			break
		case 'returned':
		case 'incompleted':
			orderProgress = 'incomplete'
			orderStatus = ''
			break
		case 'closed':
			orderProgress = 'complete'
			orderStatus = ''
			break
		case 'canceled':
			orderStatus = 'critical'
			break
		default:
			break
	}
	return (
		<Wrapper>
			<Badge progress={orderProgress} status={orderStatus}>{ORDER_STATUS[order_status]}</Badge>
		</Wrapper>
	)
}
