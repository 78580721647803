import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {fetchOrdersList, searchOrders, deleteOrders, updateMultiple, updateOrder, createLabel} from "./container"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "pages/orders/listView"
import {Badge, Button, Caption, ResourceItem, TextContainer, TextStyle, Icon, ButtonGroup} from "@shopify/polaris"
import {navigate} from "@reach/router"
import TagsList from "components/tagsList"
import moment from "moment"
import OrderStatusBadge from "modules/orderStatus"
import {LocationsMinor, CustomersMinor} from '@shopify/polaris-icons';
import Table from "components/table"
import {showConfirm} from "plugins/confirm"
import {Confirm} from "pages/orders/edit"
import {showToast} from "plugins/toast"
import {authContext} from "authentication/auth"

const OrdersListOpen = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [tags, setTags] = useState([])
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [forceUpdate, setForceUpdate] = useState(false)
	const [saving, setSaving] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [currentOrder, setCurrentOrder] = useState({})

	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	const userAuth = useContext(authContext)
	useEffect(() => {
		document.title = 'Đơn hàng đang chờ'
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, tagsList, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.order_status = 'open'
					result = await searchOrders(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage, order_status: 'open'}, source.token)
				}
				if (result) {
					console.log(result,'result')
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}

				// tagsList = await fetchTagsList({limit: 100})
				if (tagsList && tagsList.data) {
					setTags(tagsList.data)
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search, forceUpdate])

	const handleSearch = () => {
		const params = getParams()
		params.order_status = 'open'
		;(async () => {
			const result = await searchOrders(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}
	const handleUpdateItems = (newItems) => {
		console.log(newItems, 'newItems')
		setData({...data, items: newItems})
	}

	const renderItem = (item) => {
		const {_id, title, created_at, line_items, order_status, tags, notes, shipping_address} = item
		let variation
		switch (order_status) {
			case 'closed':
				variation = 'subdued'
				break
			case 'canceled':
				variation = 'negative'
				break
			default:
				break
		}

		const {first_name, last_name, phone, address, address_2, city, state, country_code, zip_code} = shipping_address
		const fullAddress = [address, address_2, city, state, country_code, zip_code].filter(v => v !== '')
		const lastNote = notes && notes.length > 0 && notes.slice(-1).pop()

		const now = moment()

		let className = ['item-wrapper']

		const diff = now.diff(new Date(created_at), 'hours')
		if (diff >= 24) className.push('overdue')
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/orders/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className={className.join(' ')}>
					<div className="column title">
						<TextContainer>
							<div style={{display: 'flex', flexFlow: 'row wrap'}}>
								<TextStyle variation={variation}><strong>{title}</strong> &nbsp;</TextStyle>
								<OrderStatusBadge order_status={order_status}/>
							</div>
							<div style={{paddingBottom: 10}}><Caption>{moment(created_at).format('DD-MM-YY HH:mm')}</Caption></div>
							{lastNote && lastNote.type !== 'auto' && <TextStyle variation={lastNote.type && lastNote.type === 'error' && 'negative'}>{lastNote.message}</TextStyle>}
							<TagsList tags={tags}/>
						</TextContainer>
					</div>
					<div className="column" style={{width: '32%'}}>
						<Table>
							<tbody>
							{line_items.map(item => {
								return (
									<tr key={item._id}>
										<th>{item.title}:&nbsp;&nbsp; &nbsp;</th>
										<td>
											<ButtonGroup>
												<Button url={item.design} external plain>Design</Button>
												<Button url={item.mockup} external plain>Mockup</Button>
												{item.laser && <Button url={item.laser} external plain>Laser</Button>}
											</ButtonGroup>
										</td>
									</tr>
								)
							})}
							</tbody>
						</Table>
					</div>
					<div className="column" style={{width: '35%'}}>
						<div className={'customer'}>
							<div><Icon source={CustomersMinor}/> <strong>{`${first_name} ${last_name}`}</strong> - {phone}</div>
							<div><Icon source={LocationsMinor}/> <Caption>{fullAddress.join(', ')}</Caption></div>
						</div>
					</div>
					{userAuth && ['admin','orders_manager'].includes(userAuth.user.role) && (
						<div className="column" style={{width: '12%'}}>
							<Button
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									setCurrentOrder(item)
									handleToggle()
								}}
							>
								Xác nhận
							</Button>
						</div>
					)}
				</div>
			</ResourceItem>
		)
	}

	const headers = [
		{
			id: 'items',
			title: 'Items',
			width: 32
		},
		{
			id: 'seller',
			title: t('Thông tin nhận hàng'),
			width: 35
		},
		{
			id: 'quick_action',
			title: '',
			width: 12
		}
	]
	const promotedBulkActions = [
		{
			content: 'Xác nhận',
			onAction: () => {
				showConfirm({
					title: 'Xác nhận đơn hàng',
					message: t('Bạn có chắc chắn muốn xác nhận những đơn hàng này?'),
					confirm: t('Xác nhận'),
					cancel: t('Đóng'),
				}).then(res => {
					if (res) {
						updateMultiple(selectedItems, 'printing')
					}
				})
				return false
			}
		},
		{
			content: 'Xóa đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Xóa đơn hàng'),
					message: t('Bạn có chắc chắn muốn xóa những đơn hàng này?'),
					confirm: t('Delete'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						deleteOrders(selectedItems)
					}
				})
				return false
			}
		},
		{
			content: 'Hủy đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Hủy đơn hàng'),
					message: t('Bạn có chắc chắn muốn hủy những đơn hàng này?'),
					confirm: t('Hủy đơn hàng'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						updateMultiple(selectedItems, 'canceled')
					}
				})
				return false
			}
		},
	]

	const handleToggle = useCallback(() => setOpenConfirm((openConfirm) => !openConfirm), [])
	const handleToggleUpdate = useCallback(() => setForceUpdate(!forceUpdate), [forceUpdate])
	const handleSave = (data) => {
		setSaving(true)
		const {shipping_carrier} = data
		updateOrder(data).then((res) => {
			showToast({
				message: 'Xác nhận thành công'
			})
			handleToggleUpdate()
			if (shipping_carrier === 'multrans') {
				const {title} = currentOrder
				createLabel({title: title}).then(res => {
					if (!res || !res.data.success) {
						showToast({
							message: `Lỗi không tạo được Label cho đơn hàng ${title}`,
							error: true
						})
					}
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
			handleToggle()
		})
	}

	return (
		<div>
			<OrdersListView
				title={'Đơn hàng đang chờ'}
				data={data}
				handleUpdateItems={handleUpdateItems}
				handleSearch={handleSearch}
				appliedFilters={appliedFilters}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
				setAppliedFilters={setAppliedFilters}
				renderItem={renderItem}
				dataHeader={headers}
				dataFilters={[]}
				promotedBulkActions={promotedBulkActions}
			/>
			{openConfirm && <Confirm id={currentOrder._id} handleToggle={handleToggle} handleSave={handleSave} open={openConfirm} loading={saving} />}
		</div>
	)
}
export default OrdersListOpen
