import React from 'react'
import {Router} from "@reach/router"
import CustomersList from "pages/customers/list"
import CreateCustomer from "pages/customers/create"
import EditCustomer from "pages/customers/edit"
import ViewCustomer from "pages/customers/view"

export default function CustomerPages() {
  return (
	<Router>
	  <CustomersList path="/" />
	  <CreateCustomer path="/new" />
	  <EditCustomer path="/edit/:customerID" />
	  <ViewCustomer path="/view/:customerID" />
	</Router>
  )
}
