import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {fetchOrdersList, searchOrders} from "./container"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "pages/orders/listView"
import {ChoiceList} from "@shopify/polaris"
const OrdersList = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [tags, setTags] = useState([])
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [forceUpdate, setForceUpdate] = useState(false)
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = t('Orders')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, tagsList, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					result = await searchOrders(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}

				console.log(result,'result')

				// tagsList = await fetchTagsList({limit: 100})
				if (tagsList && tagsList.data) {
					setTags(tagsList.data)
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search, forceUpdate])


	const handleSearch = async () => {
		const params = getParams()
		const result = await searchOrders(params, source.token)
		setData({...result, ready: true, isSearched: true})
	}
	const handleUpdateItems = (newItems) => {
		console.log(newItems, 'newItems')
		setData({...data, items: newItems})
	}

	return (
		<OrdersListView
			title={'Đơn hàng'}
			data={data}
			handleUpdateItems={handleUpdateItems}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setAppliedFilters={setAppliedFilters}
			hasFilterShipping={true}
			highLight
		/>
	)
}
export default OrdersList
