import React, {useContext} from 'react'
import {Card} from '@shopify/polaris'
import TagSelect from 'components/tagsSelect'
import {useSubscription} from "global-state-hook"
import {OrderContext} from "../container"

const Tag = () => {
	const pageSub = useContext(OrderContext)
	let {state, setState} = useSubscription(pageSub)
	const {tags} = state
	return (
		<Card title={'Thẻ tag'}>
			<Card.Section>
				<TagSelect
					value={tags}
					onChange={v => {
						console.log(v)
						setState({tags: v ? v.map(v => v.value) : []})
					}}
					isMulti
				/>
			</Card.Section>
		</Card>
	)
}
export default Tag
