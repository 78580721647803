import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {createCustomer, initialState, CustomerContext} from "./container"
import {showToast} from "plugins/toast"
import {createSubscription, useSubscription} from "global-state-hook"
import CardLogin from "./components/cardLogin"
import CardOverview from "./components/cardOverview"
import CardAddresses from "./components/cardAddresses"
import CardID from "./components/cardID"
import Can from "authentication/can"

const CreateCustomer = () => {

	const pageSub = createSubscription(initialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('Add seller')
	})

	return <CustomerContext.Provider value={pageSub}>
		<CreateCustomerView/>
	</CustomerContext.Provider>
}


const CreateCustomerView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(CustomerContext)
	let {state} = useSubscription(pageSub)

	const handleSave = () => {

		let error = []
		const required = ['first_name', 'email', 'phone', 'seller_id']
		required.map(f => {
			if (!state[f]) error.push(f)
		})

		if (state.seller_id && state.seller_id.length < 3) {
			error.push('seller_id')
			setMandatoryFields(error)
			return showToast({
				message: 'Mã khách hàng tối thiểu là 3 ký tự',
				error: true
			})
		}

		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}

		setSaving(true)
		createCustomer(state).then((res) => {
			console.log(res, 'res')
			if (res.data.success && res.data._id) {
				showToast({
					message: 'Thêm seller thành công'
				})
				setTimeout(() => {
					return navigate('/sellers/edit/' + res.data._id)
				}, 1000)
			} else {
				showToast({
					message: res.data.message,
					error: true
				})
			}
		}).catch(error => {
			console.log(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
		})
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="customers:create"
						yes={() => (
							<Fragment>
								<StickyActions
									actions={(
										<ButtonGroup>
											<Button onClick={() => navigate('/sellers')}>{t('Discard')}</Button>
											<Button
												primary
												onClick={handleSave}
												loading={saving}
											>
												{t('Save')}
											</Button>
										</ButtonGroup>
									)}
								/>
								<Page
									title={t('Add seller')}
									breadcrumbs={[{content: t('Sellers'), onAction: () => navigate('/sellers')}]}
								>
									<Layout>
										<Layout.Section>
											<CardLogin error={mandatoryFields}/>
											<CardID error={mandatoryFields}/>
											<CardOverview error={mandatoryFields}/>
											<CardAddresses/>
										</Layout.Section>
									</Layout>
									<PageActions
										primaryAction={{
											content: t('Save'),
											loading: saving,
											onAction() {
												handleSave()
											}
										}}
										secondaryActions={[
											{
												content: t('Discard'),
												onAction: () => navigate('/sellers')

											}
										]}
									/>
								</Page>
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default CreateCustomer
