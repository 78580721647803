import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
	table {
		width: 100%;
	}
	th, td {
		padding: 1.2rem 0;
		text-align: left;
	}
	.text-right {
		text-align: right;
	}
	thead {
		th, td {
			border-bottom: .1rem solid var(--p-border-subdued,#dfe3e8);
			padding: 1.5rem 0;
		}
	}
	&.bordered {
		th, td {
			border-bottom: .1rem solid var(--p-border-subdued,#dfe3e8);
		}
		tr:last-child {
			th, td {
				border-bottom: none;
			}
		}
	}
`

const Table = (props) => {
	let classname = ''
	if (props.bordered) classname += ' bordered'
	return (
		<TableWrapper className={classname}>
			<table>
				{props.children}
			</table>
		</TableWrapper>
	)
}

export default Table