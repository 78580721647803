import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {createUser, initialState, UserContext} from "./container"
import {showToast} from "../../plugins/toast"
import {createSubscription, useSubscription} from "global-state-hook"
import CardLogin from "./components/cardLogin"
import CardInformation from "./components/cardInformation"
import CardRole from "./components/cardRole"
import Can from "authentication/can"

const CreateUser = () => {

	const pageSub = createSubscription(initialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('Add user')
	})

	return <UserContext.Provider value={pageSub}>
		<CreateUserView/>
	</UserContext.Provider>
}


const CreateUserView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(UserContext)
	let {state} = useSubscription(pageSub)

	const handleSave = () => {

		let error = []
		const required = ['first_name', 'email', 'password', 'role']
		required.map(f => {
			if (!state[f]) error.push(f)
		})
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}

		setSaving(true)
		createUser(state).then((res) => {
			if (!res.data.error) {
				showToast({
					message: 'User created'
				})
				setTimeout(() => {
					return navigate('/manage/users/edit/' + res.data.user._id)
				}, 1000)
			} else {
				return showToast({
					message: res.data.message,
					error: true
				})
			}
		}).catch(error => {
			console.log(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
		})
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="users:create"
						yes={() => (
							<Fragment>
								<StickyActions
									actions={(
										<ButtonGroup>
											<Button onClick={() => navigate('/manage/users')}>{t('Discard')}</Button>
											<Button
												primary
												onClick={handleSave}
												loading={saving}
											>
												{t('Save')}
											</Button>
										</ButtonGroup>
									)}
								/>
								<Page
									title={t('Add user')}
									breadcrumbs={[{content: t('Users'), onAction: () => navigate('/manage/users')}]}
								>
									<Layout>
										<Layout.Section>
											<CardLogin error={mandatoryFields}/>
											<CardInformation error={mandatoryFields}/>
											<CardRole error={mandatoryFields}/>
										</Layout.Section>
									</Layout>
									<PageActions
										primaryAction={{
											content: t('Save'),
											loading: saving,
											onAction() {
												handleSave()
											}
										}}
										secondaryActions={[
											{
												content: t('Discard'),
												onAction: () => navigate('/manage/users')

											}
										]}
									/>
								</Page>
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default CreateUser
