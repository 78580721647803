import React from 'react'
import {Card, FormLayout, TextContainer, TextStyle} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {countries} from "stores/countries"

export default function ViewCustomer({order}) {
	const {shipping_address, _id} = order
	const {t} = useTranslation()

	const shipping_name = [shipping_address.first_name, shipping_address.last_name].filter(v => v !== '')
	const country_found = countries.find(c => c.code === shipping_address.country_code)
	return (
		<Card title={t('Thông tin khách hàng')}>
			<Card.Section>
				{!shipping_address.first_name && <p>Chưa cập nhật thông tin khách hàng</p>}
				<TextContainer>
					{shipping_name && <p><strong>Name:</strong> {shipping_name.join(' ')}</p>}
					{shipping_address.address && <p><strong>Address 1:</strong> {shipping_address.address}</p>}
					{shipping_address.address_2 && <p><strong>Address 2:</strong> {shipping_address.address_2}</p>}
					{shipping_address.city &&
					<p><strong>City:</strong> {shipping_address.city}</p>}
					{shipping_address.state &&
					<p><strong>State:</strong> {shipping_address.state}</p>}
					{country_found && <p><strong>Country:</strong> {country_found.name}</p>}
					{shipping_address.zip_code && <p><strong>ZIP code:</strong> {shipping_address.zip_code}</p>}
					{shipping_address.phone && <p><strong>Phone:</strong> {shipping_address.phone}</p>}
				</TextContainer>
			</Card.Section>
		</Card>
	)
}
