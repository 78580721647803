import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {createOrder, initialState, OrderContext} from "core/pages/orders/container"
import {showToast} from "plugins/toast"
import {createSubscription, useSubscription} from "global-state-hook"
import Can from "authentication/can"
import Summary from "pages/orders/components/summary"
import Customer from "./components/customer"
import Seller from "./components/seller"
import CNote from "./components/cnote"
const CreateOrder = () => {

	const orderSub = createSubscription(initialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('Add order')
	})

	return <OrderContext.Provider value={orderSub}>
		<CreateOrderView/>
	</OrderContext.Provider>
}


const CreateOrderView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])
	const orderSub = useContext(OrderContext)
	let {state} = useSubscription(orderSub)

	const handleSave = () => {
		const {shipping_address, seller_id, line_items} = state
		if (!seller_id) {
			return showToast({
				message: 'Chưa chọn seller',
				error: true
			})
		}
		if (!line_items.length) {
			return showToast({
				message: 'Chưa thêm sản phẩm',
				error: true
			})
		}
		if (!shipping_address.zip_code) {
			return showToast({
				message: 'Chưa nhập đủ thông tin nhận hàng',
				error: true
			})
		}

		setSaving(true)
		createOrder(state).then((res) => {

			console.log(res, 'res')
			if (res.success && res.data._id) {
				showToast({
					message: 'Tạo đơn hàng thành công'
				})
				setTimeout(() => {
					return navigate('/orders/edit/' + res.data._id)
				}, 1000)
			} else {
				showToast({
					message: 'Không tạo được đơn hàng, vui lòng thử lại.',
					error: true
				})
			}
		}).catch(error => {
			console.log(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
		})
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				console.log(authenticated, 'authenticated')
				return authenticated ? (
					<Can
						role={user.role}
						perform="orders:create"
						yes={() => (
							<Fragment>
								<StickyActions
									actions={(
										<ButtonGroup>
											<Button onClick={() => navigate('/orders')}>{t('Discard')}</Button>
											<Button
												primary
												onClick={handleSave}
												loading={saving}
											>
												{t('Tạo đơn hàng')}
											</Button>
										</ButtonGroup>
									)}
								/>
								<Page
									title={t('Add order')}
									fullWidth
									breadcrumbs={[{content: t('Orders'), onAction: () => navigate('/orders')}]}
								>
									<Layout>
										<Layout.Section>
											<Summary user={user} />
											<Customer user={user} />
										</Layout.Section>
										<Layout.Section secondary>
											<Seller user={user} />
											<CNote />
										</Layout.Section>
									</Layout>
									<PageActions
										primaryAction={{
											content: t('Tạo đơn hàng'),
											loading: saving,
											onAction() {
												handleSave()
											}
										}}
										secondaryActions={[
											{
												content: t('Discard'),
												onAction: () => navigate('/orders')
											}
										]}
									/>
								</Page>
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default CreateOrder
