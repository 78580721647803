import React from 'react'
import {ResourceItem, ResourceList, TextStyle, Badge} from "@shopify/polaris"
import styled from 'styled-components'
import {navigate} from "@reach/router"
import NoResults from "modules/noResults"
import {useTranslation} from "react-i18next"
import moment from "moment"

const Wrapper = styled.div`
	position: relative;
	padding-top: 1rem;
	header {
		display: flex;
		padding: 0 2rem;
		padding-left: calc(40px + 2rem);
		justify-content: flex-end;
		position: absolute;
		width: 100%;
		top: 28px;
	}
	&.no-selection {
		.Polaris-ResourceList__ResourceListWrapper {
			padding-top: 5.6rem;
		}
		> header {
			padding-left: 2rem;
		}
	}
	.Polaris-ResourceList__ItemWrapper {
		&:nth-child(even) {
				background: #f6f6f8;
		}
	}
	.Polaris-ResourceList {
			border-top: .1rem solid var(--p-border-subdued,#dfe3e8);
	}
	.column {
		padding: 0 15px;
		width: 20%;
		z-index: 11;
		display: flex;
		align-items: center;
		&.title {
			flex: 1;
		}
		.Polaris-Avatar {
			margin-right: 1rem;
			border-radius: 0;
			img {
				border-radius: 0;
			}
		}
	}
	.title {
		svg {
			width: 16px;
			opacity: .7;
		}
		.unlock {
			color: green;
		}
	}
	.column-10 {
		width: 10%;
	}
	.column-15 {
		width: 15%;
	}
	.column-25 {
		width: 25%;
	}
	.column-30 {
		width: 30%;
	}
	.column-35 {
		width: 35%;
	}
	.column-45 {
		width: 45%;
	}
	.column-40 {
		width: 40%;
	}
	.column-50 {
		width: 50%;
	}
	// .has-header .Polaris-ResourceList-CheckableButton__Label {
	// 	display: none;
	// }
	.item-wrapper {
		display: flex;
		align-items: center;
	}
	.overdue {
		&:before {
			content: '';
			background: #ce1a1a26;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			bottom: 0;
			pointer-events: none;
		}
	}
	.Polaris-ResourceItem__Container {
		align-items: center;
	}
	.Polaris-ResourceItem__Actions {
		z-index: 11;
	}
	.Polaris-TextContainer>:not(:first-child) {
		margin-top: 0.6rem;
	}
	.customer {
		> div {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			+ div {
				margin-top: 10px;
			}
		}
		.Polaris-Icon {
			margin: 0;
			opacity: .5;
		}
	}
	th, td {
		padding: 1rem 0;
	}
	.Polaris-Badge__Content {
		white-space: nowrap;
	}
	@media (max-width: 767px) {
		.item-wrapper {
			flex-flow: column;
			align-items: flex-start;
		}
		.column {
			flex: 1;
			padding: 5px 0;
			width: 100% !important;
		}
		header {
			display: none;
		}
	}
`

export default function DataList({items, selectedItems, onSelectionChange, resourceName, headers, pageType, renderItem, promotedBulkActions, bulkActions, selectable, alternateTool}) {
	let className = []
	if (headers && headers.length > 0) {
		className.push('has-header')
	}
	if (!promotedBulkActions)  className.push('no-selection')

	const {t} = useTranslation()

	const itemMarkup = (item) => {
		const {_id, title, visibility} = item
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/${pageType}/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className="item-wrapper">
					<div className="column title">
						<h3>
							<TextStyle variation="strong">{title}</TextStyle>
							{!visibility && <Badge>{t('Hidden')}</Badge>}
						</h3>
					</div>
					{headers && headers.length > 0 && headers.map((col, i) => {
						let colName = item[col.id]
						if (col.id === 'created_at' || col.id === 'updated_at') colName = moment(colName).format('YYYY-MM-DD HH:mm A')
						if (col.id === 'language_code') colName = <Badge>{colName.toUpperCase()}</Badge>
						return (
							<div key={i} className="column" style={{width: col.width + '%'}}>
								<TextStyle>{colName}</TextStyle>
							</div>
						)
					})}
				</div>
			</ResourceItem>
		)
	}
	return (
		<Wrapper className={className.join(' ')}>
			{items.length > 0 && headers && headers.length && selectedItems && selectedItems.length < 1 && <header>
				{headers.map((item, i) => {
					return (
						<div key={i} className="column" style={{width: item.width + '%'}}>
							<TextStyle variation={'strong'}>{item.title}</TextStyle>
						</div>
					)
				})}
			</header>}
			<div>
				{items.length > 0 ? (
					<ResourceList
						resourceName={resourceName}
						items={items}
						renderItem={renderItem || itemMarkup}
						selectable={selectable}
						selectedItems={selectedItems || []}
						onSelectionChange={onSelectionChange}
						promotedBulkActions={promotedBulkActions}
						bulkActions={bulkActions}
						alternateTool={alternateTool}
					/>
				) : <NoResults resourceName={resourceName.plural}/>}
			</div>
		</Wrapper>
	)
}
