import React, {useContext, Fragment} from 'react'
import {Card, FormLayout, Layout, TextField, Button} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {CustomerContext} from "../container"
import {useTranslation} from "react-i18next"
import {provinceName, checkProvince} from "helpers/utils"
import AdvanceSelect from "components/advanceSelect"
import CountrySelect from "components/countrySelect"
import {cities} from "stores/addresses/cities"
import {districts} from "stores/addresses/districts"
import {wards} from "stores/addresses/wards"
import StateSelect from "components/usStateSelect"
const cities_list = Object.values(cities)
const districts_list = Object.values(districts)
const wards_list = Object.values(wards)

export default function CardAddresses({error}) {
  const pageSub = useContext(CustomerContext)
  let {state, setState} = useSubscription(pageSub)
  const {addresses} = state
  const {t} = useTranslation()

  const addressDefault = addresses[0]
  const {first_name, last_name, phone, city, wards, district, address, company, country_code, zip_code, province} = addressDefault
  const city_found = cities_list.find(v => v.name_with_type === city || v.name === city)
  const district_found = districts_list.find(v => v.name_with_type === district || v.name === district)
  const district_options = city_found ? districts_list.filter(v => v.parent_code === city_found.code) : []
  const wards_options = district_found ? wards_list.filter(v => v.parent_code === district_found.code) : []
  return (
    <Layout.AnnotatedSection
      title={t('Address')}
    >
      <Card>
        {/*<Card.Section>*/}
        {/*  <FormLayout>*/}
        {/*    <FormLayout.Group>*/}
        {/*      <TextField*/}
        {/*        label={t('First name')}*/}
        {/*        value={first_name}*/}
        {/*        onChange={v => {*/}
        {/*          addressDefault.first_name = v*/}
        {/*          setState({addresses})*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <TextField*/}
        {/*        label={t('Last name')}*/}
        {/*        value={last_name}*/}
        {/*        onChange={v => {*/}
        {/*          addressDefault.last_name = v*/}
        {/*          setState({addresses})*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </FormLayout.Group>*/}
        {/*    <FormLayout.Group>*/}
        {/*      <TextField*/}
        {/*        label={t('Phone number')}*/}
        {/*        value={phone}*/}
        {/*        onChange={v => {*/}
        {/*          addressDefault.phone = v*/}
        {/*          setState({addresses})*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <TextField*/}
        {/*        label={t('Company')}*/}
        {/*        value={company}*/}
        {/*        onChange={v => {*/}
        {/*          addressDefault.company = v*/}
        {/*          setState({addresses})*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </FormLayout.Group>*/}
        {/*  </FormLayout>*/}
        {/*</Card.Section>*/}
        <Card.Section>
          <FormLayout>
            <TextField
              label={t('Company')}
              value={company}
              onChange={v => {
                addressDefault.company = v
                setState({addresses})
              }}
            />
            <TextField
              label={t('Address')}
              value={address}
              onChange={v => {
                addressDefault.address = v
                setState({addresses})
              }}
              multiline={2}
            />
						{country_code === 'VN' ? (
              <AdvanceSelect
                label={t('City')}
                notFoundContent={t('No options')}
                value={city}
                onChange={v => {
                  addressDefault.city = v
                  addressDefault.district = ''
                  setState({addresses})
                }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={cities_list.map(c => {
                  return {
                    label: c.name,
                    value: c.name
                  }
                })}
              />
						) : (
              <TextField
                label={t('City')}
                value={city}
                onChange={v => {
                  addressDefault.city = v
                  setState({addresses})
                }}
              />
            )}
            {country_code === 'VN' && (
              <FormLayout.Group>
                <AdvanceSelect
                  label={t('District')}
                  notFoundContent={t('No options')}
                  value={district}
                  onChange={v => {
                    addressDefault.district = v
                    addressDefault.wards = ''
                    setState({addresses})
                  }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={district_options.map(c => {
                    return {
                      label: c.name,
                      value: c.name
                    }
                  })}
                />
                <AdvanceSelect
                  label={t('Wards')}
                  notFoundContent={t('No options')}
                  value={wards}
                  onChange={v => {
                    addressDefault.wards = v
                    setState({addresses})
                  }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={wards_options.map(c => {
                    return {
                      label: c.name,
                      value: c.name
                    }
                  })}
                />
              </FormLayout.Group>
            )}
            <TextField
              label={t('Country')}
              value={'Việt Nam'}
              disabled
            />
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  )
}
