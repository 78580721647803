import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {fetchOrdersList, searchOrders} from "./container"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "pages/orders/listView"
import {Badge, Button, ResourceItem, TextContainer, TextStyle} from "@shopify/polaris"
import {navigate} from "@reach/router"
import TagsList from "components/tagsList"
import moment from "moment"
import OrderStatusBadge from "modules/orderStatus"
import ShippingStatusBadge from "modules/shippingStatus"
const OrdersListShipping = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [tags, setTags] = useState([])
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [forceUpdate, setForceUpdate] = useState(false)
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = 'Đơn hàng đang vận chuyển'
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, tagsList, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.order_status = 'shipping'
					result = await searchOrders(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage, order_status: 'shipping'}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}

				// tagsList = await fetchTagsList({limit: 100})
				if (tagsList && tagsList.data) {
					setTags(tagsList.data)
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search, forceUpdate])


	const handleSearch = () => {
		const params = getParams()
		params.order_status = 'shipping'
		;(async () => {
			const result = await searchOrders(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}
	const handleUpdateItems = (newItems) => {
		console.log(newItems, 'newItems')
		setData({...data, items: newItems})
	}

	const renderItem = (item) => {
		const {_id, title, seller, seller_id, order_status, tags, notes, shipping_at, shipping_status} = item
		let variation
		switch (order_status) {
			case 'closed':
				variation = 'subdued'
				break
			case 'canceled':
				variation = 'negative'
				break
			default:
				break
		}
		const fullname = seller ? [seller.first_name, seller.last_name].filter(v => v !== '') : []

		const lastNote = notes && notes.length > 0 && notes.slice(-1).pop()

		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/orders/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className="item-wrapper">
					<div className="column title">
						<TextContainer>
							<TextStyle variation={variation}><strong>{title}</strong></TextStyle>
							{lastNote && lastNote.type !== 'auto' && <div><TextStyle variation={lastNote.type && lastNote.type === 'error' && 'negative'}>{lastNote.message}</TextStyle></div>}
							<TagsList tags={tags}/>
						</TextContainer>
					</div>
					<div className="column" style={{width: '20%'}}>
						<TextStyle>{moment(shipping_at).format('DD-MM-YY HH:mm')}</TextStyle>
					</div>
					<div className="column" style={{width: '25%'}}>
						{fullname.join(' ')}&nbsp;<Badge><strong>{seller_id}</strong></Badge>
					</div>
					<div className="column" style={{width: '30%'}}>
						<OrderStatusBadge order_status={order_status}/>
						<ShippingStatusBadge shipping_status={shipping_status}/>
					</div>
				</div>
			</ResourceItem>
		)
	}

	const headers = [
		{
			id: 'order',
			title: t('Đơn hàng'),
			width: 25
		},
		{
			id: 'created_at',
			title: 'Chuyển đi lúc',
			width: 20
		},
		{
			id: 'seller',
			title: t('Seller'),
			width: 25
		},
		{
			id: 'order_status',
			title: t('Trạng thái'),
			width: 30
		},
	]

	return (
		<OrdersListView
			title={'Đơn hàng đang vận chuyển'}
			data={data}
			handleUpdateItems={handleUpdateItems}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setAppliedFilters={setAppliedFilters}
			hasFilterShipping={true}
			renderItem={renderItem}
			dataHeader={headers}
		/>
	)
}
export default OrdersListShipping
