import React, {useEffect, useState} from 'react'
import {FormLayout, Spinner} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import AdvanceSelect from "./advanceSelect"
export default function ResourceSelector({source, value, mode, onChange, getData, label, placeholder, error, language_code, show_id}) {
	const {t} = useTranslation()
	const [data, setData] = useState({ready: false, items: []})
	const {fetchList} = source()

	useEffect(() => {
		const getInitialData = async () => {
			const result = await fetchList({limit: 500})
			console.log(result, 'result')
			if (result) {
				setData({items: result.data.data, ready: true})
			}
		}
		getInitialData()
	}, [language_code])
	const onSearch = (val) => {
		console.log('search:', val);
	}
	const {items, ready} = data
	if (!getData) getData = '_id'
	return (
		<AdvanceSelect
			mode={mode}
			error={error}
			allowClear
			placeholder={placeholder}
			label={label}
			value={value || undefined}
			onChange={onChange}
			notFoundContent={ready ? t('No results') : <Spinner accessibilityLabel="loading" size="small" color="teal" />}
			showSearch
			onSearch={onSearch}
			filterOption={(input, option) =>
				option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			options={items.map(item => {
				const title = show_id ? `${item.title} (${item.item_id.toUpperCase()})` : item.title
				return {
					label: title,
					value: item[getData]
				}
			})}
		/>
	)
}
