import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {deleteOrders, fetchOrdersList, searchOrders, updateMultiple} from "./container"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "pages/orders/listView"
import {showConfirm} from "plugins/confirm"
const OrdersListPrinting = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [tags, setTags] = useState([])
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [forceUpdate, setForceUpdate] = useState(false)
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = 'Đơn hàng đang sản xuất'
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, tagsList, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.order_status = 'printing'
					result = await searchOrders(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage, order_status: 'printing'}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}

				// tagsList = await fetchTagsList({limit: 100})
				if (tagsList && tagsList.data) {
					setTags(tagsList.data)
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search, forceUpdate])


	const handleSearch = () => {
		const params = getParams()
		params.order_status = 'printing'
		;(async () => {
			const result = await searchOrders(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}
	const handleUpdateItems = (newItems) => {
		console.log(newItems, 'newItems')
		setData({...data, items: newItems})
	}

	const headers = [
		{
			id: 'created_at',
			title: 'Xác nhận lúc',
			width: 20
		},
		{
			id: 'seller',
			title: t('Seller'),
			width: 25
		},
		{
			id: 'order_status',
			title: t('Trạng thái'),
			width: 15
		},
		{
			id: 'quick_action',
			title: '',
			width: 15
		}
	]
	const promotedBulkActions = [
		{
			content: 'Xóa đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Xóa đơn hàng'),
					message: t('Bạn có chắc chắn muốn xóa những đơn hàng này?'),
					confirm: t('Delete'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						deleteOrders(selectedItems)
					}
				})
				return false
			}
		},
		{
			content: 'Hủy đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Hủy đơn hàng'),
					message: t('Bạn có chắc chắn muốn hủy những đơn hàng này?'),
					confirm: t('Hủy đơn hàng'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						updateMultiple(selectedItems, 'canceled')
					}
				})
				return false
			}
		},
	]

	return (
		<OrdersListView
			title={'Đơn hàng đang sản xuất'}
			data={data}
			handleUpdateItems={handleUpdateItems}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setAppliedFilters={setAppliedFilters}
			promotedBulkActions={promotedBulkActions}
			dataHeader={headers}
			type={'print'}
			date={'confirmed_at'}
			highLight
		/>
	)
}
export default OrdersListPrinting
