import React, {useContext} from 'react'
import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {ProductContext} from "../container"
import {useTranslation} from 'react-i18next'

export default function CardTitle({error}) {
  const pageSub = useContext(ProductContext)
  let {state, setState} = useSubscription(pageSub)
  const {title, description, item_id} = state
  const {t} = useTranslation()
  return (
    <Card sectioned>
      <FormLayout>
        <TextField
          label={t('Title')}
          value={title}
          onChange={v => setState({title: v})}
          error={error.includes('title')}
        />
        <TextField
          label={t('Mã sản phẩm')}
          value={item_id.toUpperCase()}
          onChange={v => setState({item_id: v.toLowerCase()})}
          error={error.includes('item_id')}
        />
        <TextField
          label={t('Description')}
          value={description}
          multiline={3}
          onChange={v => setState({description: v})}
        />
      </FormLayout>
    </Card>
  )
}
