import {createSubscription, useSubscription} from "global-state-hook"
import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"
import {navigate} from "@reach/router"

export const initialState = {
	id: '',
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	phone: '',
	role: '',
	birthday: '',
	gender: '',
}

export const UserContext = createContext(null)


export const createUser = (state) => {
	return new Promise((resolve, reject) => {
		axios.post('/staffs', {
			...state
		})
			.then(resolve)
			.catch(reject)
	})
}
export const fetchUser = id => {
	return new Promise(resolve => {
		axios.get(`/staffs?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}
export const updateUser = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/staffs/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const deleteUsers = (ids, redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/staffs/delete?ids=${ids}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'Users deleted'
					})
					if (redirect) {
						navigate('/user')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const fetchUsersList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 20,
		page: 1,
	}, params)
	return new Promise(resolve => {
		axios.get('/staffs', {
			params: newParams,
			cancelToken: cancelToken
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(function (error) {
				console.log(error)
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const searchUsers = (params, cancelToken) => {
	console.log('run search')
	return new Promise(resolve => {
		axios.get('/staffs', {
			params: {
				page: 1,
				limit: 20,
				...params
			},
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const usersListSubscription = createSubscription({ready: false})
export const useUsers = () => {
	let {state, setState} = useSubscription(usersListSubscription)
	const fetchList = (params) => {
		const newParams = Object.assign({
			limit: 20,
			page: 1
		}, params)
		return new Promise(resolve => {
			axios.get('/staffs', {
				params: newParams
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	const search = (params) => {
		console.log('run search')
		return new Promise(resolve => {
			axios.get('/staffs', {
				params: {
					page: 1,
					limit: 20,
					...params
				}
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	return {state, setState, fetchList, search}
}
