import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardLogin from "./components/cardLogin"
import CardInformation from "./components/cardInformation"
import CardRole from "./components/cardRole"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchUser, initialState, UserContext, updateUser, deleteUsers} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"

const EditUser = (props) => {
	const pageSub = createSubscription({id: props.userID, ...initialState})

	return <UserContext.Provider value={pageSub}>
		<EditUserView {...props}/>
	</UserContext.Provider>

}


const EditUserView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(UserContext)
	let {setState, state} = useSubscription(pageSub)
	useEffect(() => {
		document.title = t('Edit user')
		const fetchData = async () => {
			const result = await fetchUser(props.userID)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userID])

	const handleSave = () => {
		let error = []
		const required = ['first_name', 'email', 'role']
		required.map(f => {
			if (!state[f]) error.push(f)
		})
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}
		setSaving(true)
		updateUser(state).then(() => {
			showToast({
				message: 'Cập nhật thành công'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleDelete = () => {
		const {_id} = state
		showConfirm({
			title: t('Delete this user'),
			message: t('Are you sure you want to delete this user? This can\'t be undone.'),
			confirm: t('Delete'),
			cancel: t('Cancel'),
			danger: true
		}).then(res => {
			if (res) {
				deleteUsers([_id], true)
			}
		})
		return false
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="users:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<StickyActions
											actions={(
												<ButtonGroup>
													<Button onClick={() => navigate('/manage/users')}>{t('Discard')}</Button>
													<Button
														primary
														onClick={handleSave}
														loading={saving}
													>
														{t('Save')}
											</Button>
												</ButtonGroup>
											)}
										/>
										<Page
											title={t('Edit user')}
											breadcrumbs={[{content: t('Users'), onAction: () => navigate('/manage/users')}]}
										>
											<Layout>
												<Layout.Section>
													<CardLogin error={mandatoryFields}/>
													<CardInformation error={mandatoryFields}/>
													<CardRole error={mandatoryFields}/>
												</Layout.Section>
											</Layout>
											<PageActions
												primaryAction={{
													content: t('Save'),
													onAction: handleSave,
													loading: saving
												}}
												secondaryActions={[
													{
														content: t('Delete'),
														destructive: true,
														onAction: handleDelete

													}
												]}
											/>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditUser
