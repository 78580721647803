import React, {useContext} from 'react'
import {Card, FormLayout, TextField, Layout, Select} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {CustomerContext} from "../container"
import {useTranslation} from "react-i18next"

export default function CardID({error, disabled}) {
	const pageSub = useContext(CustomerContext)
	let {state, setState} = useSubscription(pageSub)
	const {seller_id, seller_type} = state
	const {t} = useTranslation()
	return (
		<Layout.AnnotatedSection
			title={t('Phân loại seller')}
		>
			<Card sectioned>
				<FormLayout>
					<TextField
						label={t('Mã khách hàng')}
						placeholder={'Ví dụ: NDC'}
						value={seller_id.toUpperCase()}
						onChange={v => setState({seller_id: v.toUpperCase()})}
						error={error.includes('seller_id')}
						maxLength={3}
						showCharacterCount
						helpText={'Mã KH dùng là mã duy nhất để sử dụng trong một số trường hợp.'}
						disabled={disabled}
					/>
					<Select
						label={t('Loại khách hàng')}
						placeholder={'Lựa chọn'}
						value={seller_type}
						options={[
							{label: 'Shopbase', value: 'shopbase'},
							{label: 'Burger Prints', value: 'burger_prints'},
							{label: 'Khác', value: 'other'},
						]}
						onChange={v => setState({seller_type: v})}
						disabled={disabled}
					/>
				</FormLayout>
			</Card>
		</Layout.AnnotatedSection>
	)
}
