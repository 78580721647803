import React from 'react'
import {Router} from "@reach/router"
import ProductsList from "pages/products/list"
import CreateProduct from "pages/products/create"
import EditProduct from "pages/products/edit"

export default function ProductPages() {
  return (
	<Router>
	  <ProductsList path="/" />
	  <CreateProduct path="/new" />
	  <EditProduct path="/edit/:productID" />
	</Router>
  )
}
