import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"

export const generalInitialState = {
	email: '',
	phone: '',
	home_title: '',
	home_description: '',
	blog_title: '',
	blog_description: '',
	shop_title: '',
	shop_description: '',
	ga_code: '',
	fb_pixel: '',
	code_before_head: '',
	code_before_body: '',
	recaptcha: false,
	recaptcha_key: '',
	recaptcha_secret: '',
	store_name: '',
	store_address: '',
	type: 'general'
}

export const GeneralContext = createContext(generalInitialState)

export const langInitialState = {
	type: 'languages',
	items: []
}

export const LanguageContext = createContext(langInitialState)

export const fetchSetting = (params, cancelToken) => {
	return new Promise(resolve => {
		axios.get(`/settings`, {
			params: params,
			cancelToken: cancelToken
		})
			.then(response => {
				resolve(response.data)
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}

export const updateSetting = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/settings/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}
