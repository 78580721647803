import React  from 'react'
import {Card} from '@shopify/polaris'
import {useTranslation} from "react-i18next"

export default function ViewCNote({order}) {
	const {cnote, _id} = order
	const {t} = useTranslation()

	return (
		<Card sectioned title={t('Lưu ý của khách hàng')}>
			{cnote ? <p>{cnote}</p> : 'Không có lưu ý'}
		</Card>
	)
}
