import React, {useContext} from 'react'
import {Card, ChoiceList, Checkbox, FormLayout} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {useSubscription} from "global-state-hook";
import {ProductContext} from "../container"

export default function CardStatus() {
	const {t} = useTranslation()

	const pageSub = useContext(ProductContext)
	let {setState, state} = useSubscription(pageSub)
	const {visibility} = state
	return (
		<Card sectioned title={t('Status')}>
			<FormLayout>
				<ChoiceList
					choices={[
						{label: t('Visible'), value: true},
						{label: t('Hidden'), value: false}
					]}
					selected={[visibility]}
					onChange={v => setState({visibility: v[0]})}
				/>
			</FormLayout>
		</Card>
	)
}
