import React from 'react'
import {Modal, DropZone, Card, Caption, TextContainer} from '@shopify/polaris'
import {API_URL, ORDER_TEMPLATE} from 'stores/config'
import {showToast} from 'plugins/toast'
import UploadContainer from 'modules/uploadContainer'
import styled from 'styled-components'
import axios from 'axios'

const UploadedFile = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

class ImportOrders extends React.Component {
	state = {
		loading: false,
		files: [],
		rejectedFiles: [],
		hasError: false,
		openFileDialog: false
	}

	handleImport = () => {
		this.setState({loading: true})
		const {files} = this.state

		const data = new FormData()
		data.append('file', files[0])

		axios({
			method: 'post',
			url: API_URL + '/import',
			data: data
		})
			.then(res => {
				if (res.data.success) {
					showToast({
						message: 'Nhập đơn hàng thành công!'
					})
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				} else {
					this.props.handleChange()
					return showToast({
						message: res.data.message,
						error: true
					})
				}
			})
			.catch( (error) => {
				return showToast({
					message: 'Nhập đơn hàng không thành công, vui lòng thử lại hoặc báo cho quản trị viên.',
					error: true
				})
			})
			.finally(() => {
				this.setState({loading: false})
				this.setState({files: []})
				this.props.handleChange()
			})
	}

	render() {
		const {handleChange, open, label} = this.props
		const {loading, files} = this.state
		const sample_file = ORDER_TEMPLATE
		const uploadedFiles = files.length > 0 && (
			<UploadedFile>
				{files.map((file, index) => (
					<div key={index}>{file.name} <Caption>{file.size} bytes</Caption></div>
				))}
			</UploadedFile>
		)

		const fileUpload = !files.length && <DropZone.FileUpload/>

		return (
			<Modal
				open={open}
				onClose={() => handleChange()}
				title={label ? label : 'Nhập file excel'}
				primaryAction={{
					content: 'Tải lên',
					onAction: this.handleImport,
					loading: loading,
					disabled: this.state.files.length < 1
				}}
				secondaryActions={[
					{
						content: 'Hủy',
						onAction: () => handleChange(),
						disabled: loading
					}
				]}
			>
				<Card>
					<Card.Section>
						<TextContainer>
							<p>Tài file mẫu <a href={sample_file} target="_blank">tại đây</a></p>
							<p>Hãy chắc chắn rằng file excel của bạn đúng với file mẫu của chúng tôi.</p>
						</TextContainer>
					</Card.Section>
					<Card.Section>
						<UploadContainer loading={loading} height={'100px'}>
							<DropZone
								accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								onDrop={files => {
									this.setState({files}, () => this.handleImport())
								}}
								allowMultiple={false}
							>
								{uploadedFiles}
								{fileUpload}
							</DropZone>
						</UploadContainer>
					</Card.Section>
				</Card>
			</Modal>
		)
	}
}

export default ImportOrders
