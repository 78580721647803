import React, {useContext} from 'react'
import {Card, FormLayout, Button, TextStyle, Badge} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {CustomerContext} from "../container"
import {useTranslation} from "react-i18next"

const sellerTypes = {
  shopbase: 'Shopbase',
  burger_prints: 'BurgerPrints',
  other: 'Khác',

}

export default function CardSellerInformation() {
  const pageSub = useContext(CustomerContext)
  let {state, setState} = useSubscription(pageSub)
  const {addresses, email, first_name, last_name, seller_type, seller_id, total_order} = state
  const {t} = useTranslation()

  const addressDefault = addresses[0]
  const {phone, city, wards, district, address, company, country_code} = addressDefault

  const addressFull = [address, wards, district, city, country_code].filter(v => v !== '')
  return (
    <Card>
      <Card.Header title={'Thông tin chi tiết'}>
        <Button plain external url={`/orders?seller_id=${seller_id}`}>Xem đơn hàng <Badge status={'info'}>{total_order}</Badge></Button>
      </Card.Header>
      <Card.Section>
        <FormLayout>
          <TextStyle><strong>Họ & tên</strong>: {first_name + ' ' + last_name}</TextStyle>
          <TextStyle><strong>Email</strong>: {email}</TextStyle>
          {phone && <TextStyle><strong>Điện thoại</strong>: {phone}</TextStyle>}
          <TextStyle><strong>Seller ID</strong>: {seller_id}</TextStyle>
          <TextStyle><strong>Loại Seller</strong>: {sellerTypes[seller_type]}</TextStyle>
          {company && <TextStyle><strong>Công ty</strong>: {company}</TextStyle>}
          <TextStyle><strong>Địa chỉ</strong>: {addressFull.join(', ')}</TextStyle>
        </FormLayout>
      </Card.Section>
    </Card>
  )
}
