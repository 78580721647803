import $ from 'jquery'
import {showToast} from 'plugins/toast'
import moment from 'moment'
import {API_MEDIA, MEDIA_URL} from "../stores/config"

export function generateCodes(length) {
	if (!length) length = 10
	let randomString = function (length) {

		let text = ''

		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

		for (let i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length))
		}

		return text
	}

	// random string length
	const code = randomString(length)

	return code.toUpperCase()
}

export function generatePassword(length) {
	if (!length) length = 10
	let randomString = function (length) {

		let text = ''

		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()+_-=}{[]|:;\"/?.><,`~"

		for (let i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length))
		}

		return text
	}

	// random string length
	return randomString(length)
}

export function copyToClipboard(el) {
	// Get the text field
	const copyText = document.querySelector(el)

	// Select the text field
	copyText.select()

	// Copy the text inside the text field
	document.execCommand("copy")
}

export function mixOptions() {
	const r = []
	const arg = arguments
	const max = arg.length - 1

	function helper(arr, i) {
		for (let j = 0, l = arg[i].length; j < l; j++) {
			const a = arr.slice(0) // clone arr
			a.push(arg[i][j])
			if (i === max) {
				const o = {key: a.join('/')}
				a.forEach((v, k) => {
					o['option' + (k + 1)] = v
				})

				r.push(o)
			} else {
				helper(a, i + 1)
			}

		}
	}

	helper([], 0)
	return r
}

export function formatMoney(number) {
	number = Number(number).toLocaleString('en', {
		style: 'currency',
		currency: 'USD'
	})
	return number.replace(/\s/, '')
}

export function uploadImages(images, callback, completed) {
	const formData = new FormData()
	if (images.length < 1) return
	images.map(img => formData.append("files[]", img))
	const settings = {
		url: API_MEDIA,
		dataType: 'json',
		cache: false,
		contentType: false,
		processData: false,
		type: 'post',
		data: formData
	}
	$.ajax(settings).done(res => {
		const data = []
		if (res.success === true) {
			res.data.map((item, index) => data[index] = item.original)
			return callback(data)
		} else {
			return showToast({
				message: 'Cannot upload image, please try again',
				error: true
			})
		}

	}).fail(() => {
		console.log('Cannot upload images')
	}).always(() => {
		console.log('completed')
		completed()
	})
}

export function uploadVideos(videos, callback, completed) {
	const formData = new FormData()
	if (videos.length < 1) return
	videos.map(video => formData.append("files[]", video))
	formData.append('type', 'video')
	const settings = {
		url: API_MEDIA,
		dataType: 'json',
		cache: false,
		contentType: false,
		processData: false,
		type: 'post',
		data: formData
	}
	$.ajax(settings).done(res => {
		const data = []
		if (res.success === true) {
			res.data.map((item, index) => data[index] = item.original)
			return callback(data)
		} else {
			return showToast({
				message: 'Tải video lên không thành công!',
				error: true
			})
		}

	}).fail(() => {
		console.log('Cannot upload images')
	}).always(() => {
		console.log('completed')
		completed()
	})
}

export function getImageUrl(url, size) {
	let src
	if (!url) return
	const ext = url.split('.').pop()
	const file = url.split('.').slice(0, -1).join('.')
	switch (size) {
		case 'xs':
			src = `${file}_xs.${ext}`
			break
		case 's':
			src = `${file}_s.${ext}`
			break
		case 'm':
			src = `${file}_m.${ext}`
			break
		case 'l':
			src = `${file}_l.${ext}`
			break
		case 'full':
		default:
			src = url
			break
	}
	return MEDIA_URL + src
}

global.getImageUrl = getImageUrl

export function titleCase(str) {
	return str.toLowerCase().split(' ').map(function (word) {
		return (word.charAt(0).toUpperCase() + word.slice(1))
	}).join(' ')
}


export function getTimeRage(id) {
	let start, end
	moment.updateLocale('en', {
		week: {
			dow: 1
		}
	})
	const now = moment()
	const lastmonth = moment().subtract(1, 'month')
	const nextmonth = moment().add(1, 'month')
	const last3months = moment().subtract(3, 'months')
	switch (id) {
		case 'today':
			start = now
			end = now
			break
		case 'yesterday':
			start = moment().subtract(1, 'days')
			end = moment().subtract(1, 'days')
			break
		case 'current_week':
			start = moment().startOf('isoWeek')
			end = now
			break
		case 'last_week':
			start = moment().startOf('isoWeek').subtract(7, 'days')
			end = moment().startOf('isoWeek').subtract(1, 'days')
			break
		case 'current_month':
			start = moment().startOf('month')
			end = now
			break
		case 'last_month':
			start = moment(lastmonth).startOf('month')
			end = moment(lastmonth).endOf('month')
			break
		case 'last_3months':
			start = moment(last3months).startOf('month')
			end = moment(last3months).endOf('month')
			break
		case 'tomorrow':
			start = moment().add(1, 'day')
			end = moment().add(1, 'day')
			break
		case 'next_week':
			start = moment().startOf('isoWeek').add(7, 'days')
			end = moment().startOf('isoWeek').add(13, 'days')
			break
		case 'next_month':
			start = moment(nextmonth).startOf('month')
			end = moment(nextmonth).endOf('month')
			break
		default:
			break
	}

	return {
		start: start ? start.format('YYYY-MM-DD') : '',
		end: end ? end.format('YYYY-MM-DD') : ''
	}
}

const queryString = window.location.search
export const urlParams = new URLSearchParams(queryString)

export function getParams() {
	let params = {}
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	for (const entry of urlParams.entries()) {
		params[entry[0]] = entry[1]
	}
	return params
}

global.getParams = getParams

export function getParam(name) {
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	return urlParams.get(name)
}

global.getParam = getParam

export function updateParam(key, value, type = 'reload') {
	var {location} = window
	var baseUrl = [location.protocol, '//', location.host, location.pathname].join('')

	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)

	if (urlParams.has(key)) {
		if (value !== '' && value !== 'undefined') {
			urlParams.set(key, value)
		}
		if (value === '' || value === 'undefined') {
			urlParams.delete(key)
		}
	} else {
		if (value) urlParams.append(key, value)
	}

	if (type === 'reload') {
		window.location.href = baseUrl + '?' + urlParams.toString()
	} else {
		window.history.replaceState({}, "", baseUrl + '?' + urlParams.toString())
	}
	return false
}

global.updateParam = updateParam

export function clearAllParams() {
	var {location} = window
	var baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
	window.history.replaceState({}, "", baseUrl)
	return false
}

global.clearAllParams = clearAllParams

export function checkPermission(needles, haystack) {
	if (!Array.isArray(haystack)) return false
	if (haystack.includes('all')) return true
	for (var i = 0, len = needles.length; i < len; i++) {
		if ($.inArray(needles[i], haystack) == -1) return false
	}
	return true
}


export function generateID(number) {
	return (Math.random() * number).toString(8).substr(2, 12)
}

global.generateID = generateID

export function debounce(func, wait, immediate) {
	var timeout
	return function () {
		var context = this, args = arguments
		var later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

export function isEmpty(value) {
	if (Array.isArray(value)) {
		return value.length === 0
	} else {
		return value === '' || value == null
	}
}

export function disambiguateLabel(key, value) {
	switch (key) {
		case 'moneySpent':
			return `Money spent is between $${value[0]} and $${value[1]}`
		case 'taggedWith':
			return `Tagged with ${value}`
		case 'accountStatus':
			return value.map((val) => `Customer ${val}`).join(', ')
		default:
			return value
	}
}

export function getGreetingTime(m) {
	var g = null //return g

	if (!m || !m.isValid()) {
		return
	} //if we can't find a valid or filled moment, we return.

	var split_afternoon = 12 //24hr time to split the afternoon
	var split_evening = 17 //24hr time to split the evening
	var currentHour = parseFloat(m.format("HH"))

	if (currentHour >= split_afternoon && currentHour <= split_evening) {
		g = "afternoon"
	} else if (currentHour >= split_evening) {
		g = "evening"
	} else {
		g = "morning"
	}

	return g
}

export function keyToLabel(key) {
	return titleCase(key.replace(/_/gi, ' '))
}

export function provinceName(country_code) {
	let name
	switch (country_code.toLowerCase()) {
		case 'us':
			name = 'State'
			break
		case 'pt':
		case 'ru':
			name = 'Region'
			break
		case 'jp':
			name = 'Prefecture'
			break
		case 'au':
			name = 'State/territory'
			break
		case 'ae':
			name = 'Emirate'
			break
		default:
			name = 'Province'
			break
	}
	return name
}

export function checkProvince(country_code) {
	let visible
	console.log(country_code, 'country_code')
	if (!country_code) return false
	switch (country_code.toLowerCase()) {
		case 'jp':
		case 'ru':
		case 'pt':
		case 'it':
		case 'kr':
		case 'ca':
		case 'au':
		case 'es':
		case 'cn':
			visible = true
			break
		default:
			visible = false
			break
	}
	return visible
}

export function formatBytes(a, b = 2) {
	if (0 === a) return "0 Bytes"
	const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024))
	return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}

export function checkMediaType(file) {
	const videoExt = ['ogg', 'mp4', 'webm']
	const imageExt = ['png', 'jpg', 'gif', 'svg']
	const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
	if (file) {
		if (regex.test(file)) return 'video'
		if (file.includes('vimeo')) return 'video'
		const ext = file.split('.').pop()
		if (videoExt.includes(ext)) return 'video'
		if (imageExt.includes(ext)) return 'image'
	}
	return ''
}

export function saveSvgToPNG(svg, name, width = 100, height = 100) {
	const canvas = document.createElement("canvas")

	svg.setAttribute('width', width * 2)
	svg.setAttribute('height', height * 2)
	canvas.width = width * 2
	canvas.height = height * 2
	const data = new XMLSerializer().serializeToString(svg)
	const win = window.URL || window.webkitURL || window
	const img = new Image()
	const blob = new Blob([data], {type: 'image/svg+xml'})
	const url = win.createObjectURL(blob)
	img.onload = function () {
		canvas.getContext('2d').drawImage(img, 0, 0)
		win.revokeObjectURL(url)
		const uri = canvas.toDataURL('image/png').replace('image/png', 'octet/stream')
		const a = document.createElement('a')
		document.body.appendChild(a)
		a.style = 'display: none'
		a.href = uri
		a.download = name + '.png'
		a.click()
		window.URL.revokeObjectURL(uri)
		document.body.removeChild(a)
	}
	img.src = url
}

export function printHTML(target) {
	const frame = document.getElementById("datatoprint").contentWindow
	$("#datatoprint").on("load", function() {
		let head = $("#datatoprint").contents().find("head");
		let css = '<style>@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }/style>';
		$(head).append(css);
	})

	frame.document.open()
	frame.document.write(target.innerHTML)
	frame.document.close()
	frame.focus()
	frame.print()
}

global.moment = moment
