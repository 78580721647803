import React from 'react'
import {FormLayout} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import AdvanceSelect from "./advanceSelect"
import {US_States} from "stores/usStates"

export default function StateSelect({value, onChange}) {
	const {t} = useTranslation()

	return (
		<FormLayout>
			<AdvanceSelect
				allowClear
				label={t('State')}
				value={value || undefined}
				onChange={onChange}
				showSearch
				filterOption={(input, option) =>
					option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				options={Object.keys(US_States).map(item => {
					return {
						label: US_States[item],
						value: item
					}
				})}
			/>
		</FormLayout>
	)
}
