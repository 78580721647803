import React, {useState, useCallback} from 'react'
import {Page, Layout, Card, Caption, ResourceItem, TextStyle, Icon, ChoiceList, DatePicker} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {navigate, Redirect} from "@reach/router"
import {SkeletonList} from "modules/skeleton"
import {AuthConsumer} from "authentication/auth"
import DataList from "modules/dataList"
import Filters from "modules/filters"
import Pagination from "modules/pagination"
import {getTimeRage, updateParam} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import empty from 'images/order-empty.svg'
import Can from "authentication/can"
import {ExportMinor, ImportMinor} from '@shopify/polaris-icons'
import moment from "moment"
import ImportOrders from "components/importOrders"
import ExportOrders from "./components/ExportOrders"
import OrderStatusBadge from "modules/orderStatus"
import {icons} from 'stores/icons'

const d = new Date()
const current_month = d.getMonth()
const current_year = d.getFullYear()
const firstDay = moment().startOf('month').format('YYYY-MM-DD')
const now = moment().format('YYYY-MM-DD')

const OrdersListView = ({title, data, handleSearch, appliedFilters, setAppliedFilters, selectedItems, setSelectedItems, dataHeader, promotedBulkActions, sellerId}) => {
	const [openImport, setOpenImport] = useState(false)
	const [openExport, setOpenExport] = useState(false)
	const {ready, last_page, current_page, isSearched, to, total} = data
	const [customDate, setCustomDate] = useState({
		month: current_month,
		year: current_year,
		start: firstDay,
		end: now
	})

	const {t} = useTranslation()

	let items = data.data || []
	items = items && items.length && items.map(item => {
		item.id = item._id
		return item
	})

	const headers = [
		{
			id: 'order',
			title: t('Đơn hàng'),
			width: 33
		},
		{
			id: 'customer',
			title: t('Flatform order ID'),
			width: 20
		},
		{
			id: 'customer',
			title: t('Khách hàng'),
			width: 32
		},
		{
			id: 'order_status',
			title: t('Order status'),
			width: 15
		}
	]

	const handleChange = useCallback(() => setOpenImport(!openImport), [openImport]);
	const handleChangeExport = useCallback(() => setOpenExport(!openExport), [openExport]);

	const handleFiltersChange = useCallback(
		(key, value) => {
			// Reset current order
			updateParam('order', '', null)
			// Update new filter
			appliedFilters[key] = value
			setAppliedFilters({...appliedFilters})
			if (key === 'processed_at') {
				updateParam('start_at', getTimeRage(value).start, null)
				updateParam('end_at', getTimeRage(value).end, null)
			} else {
				updateParam(key, value + '', null)
			}

			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFilterDateChange = useCallback(
		(start, end) => {
			updateParam('start_at', start, null)
			updateParam('end_at', end, null)
			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFiltersRemove = useCallback(
		(key) => {
			// Reset current order
			updateParam('order', '', null)
			delete appliedFilters[key]
			setAppliedFilters({...appliedFilters})
			updateParam(key, '', null)

			if (key === 'processed_at') {
				updateParam('start_at', '', null)
				updateParam('end_at', '', null)
			}
			// Run search with new filters
			handleSearch()
		},
		[]
	)
	const handleMonthChange = (month, year) => {
		setCustomDate({...customDate, month: month, year: year})
	}
	const handleFiltersClearAll = () => {
		for (const obj of Object.keys(appliedFilters)) {
			delete appliedFilters[obj]
		}
		setAppliedFilters({...appliedFilters})
	}

	const renderItem = (item) => {
		const {_id, title, created_at, shipping_address} = item
		let {order_status} = item

		const fullname = [shipping_address.first_name, shipping_address.last_name].filter(v => v !== '')
		let variation
		switch(order_status) {
			case 'closed':
				variation = 'subdued'
				break
			case 'canceled':
				variation = 'negative'
				break
			default:
				break
		}

		if (order_status === 'printing' || order_status === 'finishing' || order_status === 'storing') order_status = 'processing'
		const {address, address_2, city, state, country_code, zip_code} = shipping_address
		const addressFull = [address, address_2, city, state, country_code, zip_code]
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/orders/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
				// media={media}
			>
				<div className="item-wrapper">
					<div className="column title">
						<h3>
							<TextStyle variation={variation}><strong>{title}</strong> <span className="icon lock">{order_status === 'open' ? icons.unlock : icons.locked}</span></TextStyle>
							<Caption>{moment(created_at).format('DD-MM-YY HH:mm')}</Caption>
						</h3>
					</div>
					<div className="column" style={{width: '20%'}}>
						<div>
							<TextStyle variation={'strong'}>{item.seller_order_id}</TextStyle>
						</div>
					</div>
					<div className="column" style={{width: '32%'}}>
						<div>
							<TextStyle variation={'strong'}>{fullname.join(' ')}</TextStyle>
							<Caption>{addressFull.join(', ')}</Caption>
						</div>
					</div>
					<div className="column" style={{width: '15%'}}>
						<OrderStatusBadge order_status={order_status}/>
					</div>
				</div>
			</ResourceItem>
		)
	}
	const {processed_at} = appliedFilters
	const filters = [
		{
			key: 'date',
			label: t('Thời gian'),
			filter: (
				<>
					<ChoiceList
						title={t('Thời gian')}
						titleHidden
						choices={[
							{
								label: 'Hôm nay',
								value: 'today'
							},
							{
								label: 'Ngày hôm qua',
								value: 'yesterday'
							},
							{
								label: 'Tuần này',
								value: 'current_week'
							},
							{
								label: 'Tuần trước',
								value: 'last_week'
							},
							{
								label: 'Tháng này',
								value: 'current_month'
							},
							{
								label: 'Tháng trước',
								value: 'last_month'
							},
							{
								label: 'Thời gian tùy chỉnh',
								value: 'custom'
							}
						]}
						selected={[processed_at]}
						onChange={v => {
							setCustomDate({
								...customDate,
								start: getTimeRage(v[0]).start ? getTimeRage(v[0]).start : firstDay,
								end: getTimeRage(v[0]).end ? getTimeRage(v[0]).end : now
							})
							handleFiltersChange('processed_at', v[0])
						}}
						// allowMultiple
					/>
					{processed_at === 'custom' && <div style={{paddingTop: 10}}>
						<DatePicker
							month={customDate.month}
							year={customDate.year}
							multiMonth
							allowRange
							onChange={v => {
								const start =  moment(v.start).format('YYYY-MM-DD')
								const end =  moment(v.end).format('YYYY-MM-DD')
								setCustomDate({
									...customDate,
									start: start,
									end: end
								})
								handleFilterDateChange(start, end)
							}}
							onMonthChange={handleMonthChange}
							disableDatesAfter={new Date()}
							selected={{
								start: new Date(customDate.start),
								end: new Date(customDate.end)
							}}
						/>
					</div>}
				</>
			),
			shortcut: true
		}
	]
	return (
		<AuthConsumer>
			{({authenticated, user, accessToken}) => {
				if (authenticated) {
					return (
						<Can
							role={user.role}
							perform="orders:list"
							yes={() => {
								return ready ? (
									<Page
										title={title ? title : 'Đơn hàng'}
										fullWidth
										primaryAction={{content: t('Create order'), onAction: () => navigate('/orders/new')}}
										secondaryActions={[
											{
												content: t('Import orders'),
												accessibilityLabel: 'Import orders',
												plain: true,
												icon: ImportMinor,
												onAction: handleChange
											},
											{
												content: t('Export orders'),
												accessibilityLabel: 'Export orders',
												plain: true,
												icon: ExportMinor,
												onAction: handleChangeExport
											}
										]}
									>
										{openImport && <ImportOrders accessToken={accessToken} handleChange={handleChange} open={openImport}/>}
										{openExport && <ExportOrders user={user} handleChange={handleChangeExport} open={openExport}/>}
										<Layout>
											<Layout.Section>
												{items.length > 0 || isSearched ? (
													<Card sectioned>
														<Filters
															filters={filters}
															handleSearch={handleSearch}
															appliedFilters={appliedFilters}
															handleFiltersChange={handleFiltersChange}
															handleFiltersRemove={handleFiltersRemove}
															handleFiltersClearAll={handleFiltersClearAll}
														/>
														<div className="results-count">
															{t('showing', {to: to, total: total, resourceName: 'đơn hàng'})}
														</div>
														<DataList
															resourceName={{singular: t('Đơn hàng'), plural: t('Đơn hàng')}}
															items={items}
															selectedItems={selectedItems}
															onSelectionChange={setSelectedItems}
															orderType={'orders'}
															renderItem={renderItem}
															promotedBulkActions={promotedBulkActions || undefined}
															headers={dataHeader ? dataHeader : headers}
														/>
														<Pagination
															pageNumber={current_page}
															lastPage={last_page}
															path={'orders'}
														/>
													</Card>
												) : <EmptyPage
													image={empty}
													description={'Chưa có đơn hàng nào'}
												/>}
											</Layout.Section>
										</Layout>
									</Page>
								) : <SkeletonList fullWidth/>
							}}
						/>
					)
				} else {
					return <Redirect to={'/login'} noThrow />
				}
			}}
		</AuthConsumer>
	)
}
export default OrdersListView
