import {createSubscription, useSubscription} from "global-state-hook"
import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"
import {navigate} from "@reach/router"

const address = {
	first_name: '',
	last_name: '',
	company: '',
	address: '',
	city: '',
	district: '',
	wards: '',
	phone: '',
	province: '',
	zip_code: '',
	country_code: 'VN'
}

export const initialState = {
	id: '',
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	phone: '',
	seller_id: '',
	seller_type: '',
	addresses: [
		{
			first_name: '',
			last_name: '',
			company: '',
			address: '',
			city: '',
			district: '',
			wards: '',
			phone: '',
			province: '',
			zip_code: '',
			country_code: 'VN'
		}
	],
	birthday: '',
	gender: '',
}

export const CustomerContext = createContext(null)


export const createCustomer = (state) => {
	return new Promise((resolve, reject) => {
		axios.post('/customers', {
			...state
		})
			.then(resolve)
			.catch(reject)
	})
}
export const fetchCustomer = id => {
	return new Promise(resolve => {
		axios.get(`/customers?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}
export const updateCustomer = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/customers/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const deleteCustomers = (ids, redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/customers/delete?ids=${ids}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'Đã xóa Seller'
					})
					if (redirect) {
						navigate('/sellers')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const fetchCustomersList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 20,
		page: 1
	}, params)
	return new Promise(resolve => {
		axios.get('/customers', {
			params: newParams,
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const searchCustomers = (params, cancelToken) => {
	console.log('run search')
	return new Promise(resolve => {
		axios.get('/customers', {
			params: {
				page: 1,
				limit: 20,
				...params
			},
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const customersListSubscription = createSubscription({ready: false})
export const useCustomers = () => {
	let {state, setState} = useSubscription(customersListSubscription)
	const fetchList = (params) => {
		const newParams = Object.assign({
			limit: 20,
			page: 1
		}, params)
		return new Promise(resolve => {
			axios.get('/customers', {
				params: newParams
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	const search = (params) => {
		console.log('run search')
		return new Promise(resolve => {
			axios.get('/customers', {
				params: {
					page: 1,
					limit: 20,
					...params
				}
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	return {state, setState, fetchList, search}
}
