import React, {useState, useCallback, useEffect} from 'react'
import {Filters, Button} from "@shopify/polaris"
import {updateParam, urlParams, clearAllParams, getParam, debounce} from "../helpers/utils"
import {useTranslation} from "react-i18next"
import styled from 'styled-components'

const FilterWrapper = styled.div`
	.clear {
		margin-top: 0.5rem;
	}
	&.no-filter {
		.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
			display: none;
		}
	}
`

export default function Filter({filters, handleSearch, appliedFilters, handleFiltersRemove, handleFiltersClearAll, currentFilters}) {
	const [queryValue, setQueryValue] = useState('')
	const {t} = useTranslation()
	useEffect(() => {
		if (urlParams.has('keyword')) {
			setQueryValue(getParam('keyword'))
		}
	}, [])

	const handleSearchChange = debounce((query) => {
		if (urlParams.has('page')) updateParam('page', '', null)
		updateParam('keyword', query, '')
		handleSearch()
	}, 500)

	const handleFiltersQueryChange = useCallback(
		(value) => {
			setQueryValue(value)
			handleSearchChange(value)
			// console.log(value, 'search value')
		},
		[]
	)
	const handleQueryValueRemove = useCallback(() => {
		setQueryValue('')
		handleSearchChange('')
	}, [])

	const handleClearAll = useCallback(() => {
		setQueryValue('')
		handleFiltersClearAll()
		clearAllParams()
		handleSearch()
	}, [])

	// const currentFilters = []
	if (!currentFilters) {
		currentFilters = []
		for (const [key, value] of Object.entries(appliedFilters)) {
			if (value === '') continue
			const obj = {
				key: key,
				label: `${t(key)} ${t('is')} ${t(value)}`,
				onRemove: handleFiltersRemove
			}
			currentFilters.push(obj)
		}
	}

	let classNames = []
	if (!filters.length) classNames.push('no-filter')

	return (
		<FilterWrapper className={classNames.join(' ')}>
			<Filters
				queryValue={queryValue}
				filters={filters}
				appliedFilters={currentFilters}
				onQueryChange={handleFiltersQueryChange}
				onQueryClear={handleQueryValueRemove}
				onClearAll={handleClearAll}
				queryPlaceholder={t('Search')}
			/>
			{currentFilters && currentFilters.length > 1 && <div className="clear">
				<Button size={'slim'} onClick={handleClearAll}>{t('Clear all filters')}</Button>
			</div>}
		</FilterWrapper>
	)
}