import React, {useCallback, useContext, useState} from 'react'
import {Card, Button, ButtonGroup, Link} from '@shopify/polaris'
import {OrderContext, updateOrder} from "../container"
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import AddItems from "../components/addItems"
import Table from "components/table"
import {itemInitalState} from "core/pages/orders/container"
import {saveSvgToPNG, printHTML} from "helpers/utils"
import {PrintMinor, SaveMinor, DeleteMinor, EditMinor} from '@shopify/polaris-icons'
import Barcode from "react-barcode"
import {showToast} from "plugins/toast"
import ItemStatusBadge from "modules/itemStatus"

export default function Summary({user}) {
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const [active, setActive] = useState(false)
	const [initialState, setInitialState] = useState(itemInitalState)
	const [currentIndex, setCurrentIndex] = useState(-1)
	const {line_items, _id, order_status, weight, width, height, length} = state
	const {t} = useTranslation()
	const handleToggle = useCallback(() => setActive((active) => !active), [])

	const handleClose = () => {
		setInitialState(itemInitalState)
		setCurrentIndex(-1)
		handleToggle()
	}

	const handleEdit = (index) => {
		setInitialState(line_items[index])
		setCurrentIndex(index)
		handleToggle()
	}

	const handleDelete = (index) => {
		line_items.splice(index, 1)
		if (_id) {
			updateOrder({_id: _id, order_status: order_status, line_items: line_items}).then((res) => {
				const result = res.data
				const {data, success} = result
				if (res && result && success) {
					const {width, height, length, weight} = data
					setState({line_items: data.line_items, width: width, height: height, weight: weight, length: length})
					showToast({
						message: 'Cập nhật thành công'
					})
				} else {
					showToast({
						message: 'Cập nhật không thành công, vui lòng thử lại',
						error: true
					})
				}
			}).catch((error) => {
				console.error(error)
			})
		} else {
			setState({line_items: line_items})
		}
	}

	const handleSaveBarCode = (item_sku) => {
		item_sku = item_sku.substring(0, 16)
		const svg = document.querySelector('#item-' + item_sku + ' svg')
		saveSvgToPNG(svg, item_sku, 440, 140)
	}

	const handlePrint = (item_sku) => {
		const content = document.querySelector('#item-' + item_sku.substring(0,16))
		printHTML(content)
	}


	return (
		<Card>
			<div style={{display: 'none'}}>
				<iframe id="datatoprint" />
				{line_items && line_items.length && line_items.map(item => {
					return (
						<div id={`item-${item.item_sku.substring(0,16)}`} key={item.item_sku}>
							<h3 style={{textAlign: 'center', margin: 0}}><strong>{item.title} x {item.quantity}</strong></h3>
							<Barcode
								key={item.item_sku}
								value={item.item_sku}
								width={2}
								height={100}
								fontSize={15}
							/>
						</div>
					)
				})}
			</div>
			<Card.Header title={t('Order items')}>
				{line_items.length < 1 && <Button plain onClick={handleToggle}>Thêm sản phẩm</Button>}
			</Card.Header>
			{line_items && line_items.length ? (
				<Card.Section subdued>
					<Table bordered>
						<thead>
						<tr>
							<th>Loại sản phẩm</th>
							<th>Đã in</th>
							{/*<th>Hoàn thiện</th>*/}
							{_id && <th colSpan={2}>Trạng thái</th>}
						</tr>
						</thead>
						<tbody>
						{line_items.map((item, index) => {
							const {mockup, design, laser, color, quantity, quantity_printed, item_id, title, quantity_finished, item_sku} = item

							let itemTitle = <span><span style={{fontWeight: 500}}>{title}</span> ({item_id.toUpperCase()})</span>
							if (order_status !== 'open') {
								itemTitle = <Link url={`/items/edit/${item._id}`} external><span style={{fontWeight: 500}}>{title}</span> ({item_id.toUpperCase()})</Link>
							}
							return (
								<tr key={index}>
									<td>
										{itemTitle}
										{color && <div style={{marginTop: 10}}>Màu: <strong>{color}</strong></div>}
									</td>
									<td width={100}>{quantity_printed ? quantity_printed.toString() : 0}/{quantity} cái</td>
									{/*<td width={100}>{quantity_finished ? quantity_finished.toString() : 0}/{quantity} cái</td>*/}
									{_id && <td width={150}>
										<ItemStatusBadge status={item.status} />
									</td>}
									<td width={300}>
										<ButtonGroup>
											<Button plain external url={design}>Thiết kế</Button>
											<Button plain external url={mockup}>Mockup</Button>
											{laser && <Button plain external url={laser}>Laser</Button>}
											{/*{order_status !== 'open' && <Button icon={SaveMinor} onClick={() => handleSaveBarCode(item_sku)} plain />}*/}
											{/*{order_status !== 'open' && <Button icon={PrintMinor} onClick={() => handlePrint(item_sku)} plain />}*/}
											{['open', 'printing'].includes(order_status)  && <Button icon={EditMinor} plain onClick={() => handleEdit(index)} />}
											{order_status === 'open' && <Button icon={DeleteMinor} plain destructive onClick={() => handleDelete(item._id)} />}
										</ButtonGroup>
									</td>
								</tr>
							)
						})}
						{order_status === 'open' && line_items && line_items.length > 0 && <tr>
							<td colSpan={4}>
								<Button onClick={handleToggle}>Thêm sản phẩm</Button>
							</td>
						</tr>}
						</tbody>
					</Table>
				</Card.Section>
			) : (
				<Card.Section>
					<p>Chưa có sản phẩm nào</p>
				</Card.Section>
			)}
			{_id && <Card.Section title={'Kích thước & cân nặng gói hàng'}>
				<Table>
					<tbody>
					<tr>
						<th width={120}>Kích thước:</th>
						<td>{length} x {width} x {height}mm</td>
					</tr>
					<tr>
						<th>Cân nặng:</th>
						<td>{weight}g</td>
					</tr>
					</tbody>
				</Table>
			</Card.Section>}
			{active && <AddItems user={user} open={true} currentIndex={currentIndex} handleClose={handleClose} initialState={initialState} handleToggle={handleToggle} />}
		</Card>
	)
}
