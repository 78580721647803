import React, {useState, useEffect} from 'react'
import {FormLayout, TextField, Button, Page, Form, DisplayText} from '@shopify/polaris'
import {showToast} from 'plugins/toast'
import {AuthConsumer} from "./auth"
import {useTranslation} from "react-i18next"
import styled from 'styled-components'
import {navigate} from "@reach/router"
import {initialUserAuth} from "./auth"

const Wrapper = styled.div`
	padding: 100px 15% 0;
`
export default function Login() {
	const {t} = useTranslation()
	useEffect(() => {
		document.title = t('Login')
		document.body.classList.add('login-page')

		const userAuth = localStorage.getItem('userAuth') && JSON.parse(localStorage.getItem('userAuth'))

		if (!userAuth) {
			localStorage.setItem('userAuth', JSON.stringify(initialUserAuth))
		}
		if (userAuth && userAuth.authenticated) {
			document.body.classList.remove('login-page')
			return navigate('/')
		}
	}, [])

	const initialState = {
		email: '',
		password: '',
	}

	const [state, setState] = useState(initialState)
	const [loading, setLoading] = useState(false)

	const handleChange = (id, val) => {
		setState({
			...state,
			[id]: val
		})
	}
	const handleSubmit = (handleLogin) => {
		const {email, password} = state
		setLoading(true)
		handleLogin(email, password).then(res => {
			console.log(res, 'login res')
			if (res.success) {
				showToast({
					message: 'Đăng nhập thành công'
				})
				document.body.classList.remove('login-page')
				// return window.location.href = '/'
				return navigate('/')
			} else {
				return showToast({
					message: res.message,
					error: true
				})
			}
		})
			.catch(console.log)
			.finally(() => setLoading(false))
	}

	const {email, password} = state
	return (
		<AuthConsumer>
			{({handleLogin}) => (
				<Page fullWidth={false}>
					<Wrapper>
						<Form onSubmit={() => handleSubmit(handleLogin)}>
							<FormLayout>
								<DisplayText size="extraLarge">{t('Login')}</DisplayText>
								<TextField
									value={email}
									label={t('Email')}
									type={'email'}
									onChange={v => handleChange('email', v)}
								/>
								<TextField
									value={password}
									label={t('Password')}
									type={'password'}
									onChange={v => handleChange('password', v)}
								/>
								<Button
									loading={loading}
									primary
									disabled={!email || !password}
									onClick={() => handleSubmit(handleLogin)}
								>
									{t('Login')}
								</Button>
							</FormLayout>
						</Form>
					</Wrapper>
				</Page>
			)}
		</AuthConsumer>
	)
}
