import React, {useCallback, useContext, useState} from 'react'
import {Card, FormLayout, TextContainer, Button} from '@shopify/polaris'
import {OrderContext} from "../container"
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import {Link} from "@reach/router"
import EditAddress from "./editAddress"
import {countries} from "stores/countries"
import {US_States} from "stores/usStates"

export default function Customer({user}) {
	const [active, setActive] = useState(false)
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const {shipping_address, _id, order_status, label_created} = state
	const {t} = useTranslation()
	const {phone} = shipping_address
	const handleToggle = useCallback(() => setActive((active) => !active), [])

	const shipping_name = [shipping_address.first_name, shipping_address.last_name].filter(v => v !== '')
	const isNew = !phone
	const country_found = countries.find(c => c.code === shipping_address.country_code)
	return (
		<Card>
			<Card.Header title={t('Thông tin khách hàng')}>
				{['open', 'printing', 'finishing'].includes(order_status) && user.role !== 'seller' && <Button plain onClick={handleToggle}>{isNew ? t('Thêm') : t('Edit')}</Button>}
				{order_status === 'open' && user.role === 'seller' && <Button plain onClick={handleToggle}>{isNew ? t('Thêm') : t('Edit')}</Button>}
			</Card.Header>
			<Card.Section>
				{!shipping_address.first_name && <p>Chưa cập nhật thông tin khách hàng</p>}
				{shipping_address.first_name && <TextContainer>
					{shipping_name && <p><strong>Name:</strong> {shipping_name.join(' ')}</p>}
					{shipping_address.address && <p><strong>Address 1:</strong> {shipping_address.address}</p>}
					{shipping_address.address_2 && <p><strong>Address 2:</strong> {shipping_address.address_2}</p>}
					{shipping_address.city &&
					<p><strong>City:</strong> {shipping_address.city}</p>}
					{shipping_address.state &&
					<p><strong>State:</strong> {US_States[shipping_address.state] && US_States[shipping_address.state]}</p>}
					{country_found && <p><strong>Country:</strong> {country_found.name}</p>}
					{shipping_address.zip_code && <p><strong>ZIP code:</strong> {shipping_address.zip_code}</p>}
					{shipping_address.phone && <p><strong>Phone:</strong> {shipping_address.phone}</p>}
				</TextContainer>}
			</Card.Section>
			{active && <EditAddress open={true} initialState={shipping_address} handleToggle={handleToggle} />}
		</Card>
	)
}
