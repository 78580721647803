import React, {useState, useContext} from 'react'
import {Card, TextField, FormLayout, Stack, Button, Form} from '@shopify/polaris'
import TimeLine from './timeline'
import {OrderContext, updateOrder} from "../container"
import {useTranslation} from "react-i18next"
import {useSubscription} from "global-state-hook"
import {showToast} from "plugins/toast"
import {authContext} from "authentication/auth"

export default function Note() {
	const [message, setMessage] = useState('')
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const { _id, notes, order_status} = state
	const {t} = useTranslation()

	const userAuth = useContext(authContext)
	let name
	if (userAuth) {
		name = `${userAuth.user.first_name} ${userAuth.user.last_name}`
	}

	const handleSave = () => {
		const newData = {
			notes: [...notes, {
				message: message,
				created_by: name,
				created_at: new Date()
			}],
			_id: _id,
			order_status: order_status
		}
		updateOrder(newData).then(res => {
			console.log(res, 'note res')
			setState({notes: res.data.request.notes})
			showToast({
				message: 'Thêm ghi chú thành công'
			})
			setMessage('')
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		})
	}

	return (
		<Card title={t('Ghi chú nội bộ')}>
			<Card.Section>
				<Form onSubmit={handleSave}>
					<FormLayout>
						<TextField
							value={message}
							multiline={2}
							onChange={v => setMessage(v)}
							placeholder={t('Leave a comment')}
						/>
						{_id && <Stack>
							<Stack.Item fill />
							<Stack.Item>
								<Button onClick={handleSave} disabled={!message}>{t('Thêm ghi chú')}</Button>
							</Stack.Item>
						</Stack>}
					</FormLayout>
				</Form>
			</Card.Section>
			{_id && <TimeLine notes={notes.slice().reverse()} id={_id} />}
		</Card>
	)
}
