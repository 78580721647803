import React from 'react'
import {TextField, Modal, ChoiceList, FormLayout, DatePicker, Select} from '@shopify/polaris'
import {getTimeRage} from 'helpers/utils'
import moment from 'moment'
import XLSX from 'xlsx'
import {ExportSheet} from 'react-xlsx-sheet'
import {exportOrders} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {ORDER_STATUS} from "helpers/defines"

const now = moment().format('YYYY-MM-DD')
const d = new Date()
const current_month = d.getMonth()
const current_year = d.getFullYear()
const firstDay = moment().startOf('month').format('YYYY-MM-DD')

const orderHeaders = [
	{
		title: 'Ngày tạo đơn',
		dataIndex: 'created_at'
	},
	{
		title: 'Mã đơn hàng',
		dataIndex: 'title'
	},
	{
		title: 'Trạng thái',
		dataIndex: 'order_status'
	},
	{
		title: 'Platform order ID',
		dataIndex: 'seller_order_id'
	},
	{
		title: 'Tracking number',
		dataIndex: 'shipping_tracking_number'
	},
	{
		title: 'Seller',
		dataIndex: 'seller_name'
	},
	{
		title: 'Seller ID',
		dataIndex: 'seller_id'
	},
	{
		title: 'item',
		dataIndex: 'item_title'
	},
	{
		title: 'quantity',
		dataIndex: 'quantity'
	},
	{
		title: 'Design',
		dataIndex: 'design'
	},
	{
		title: 'Mockup',
		dataIndex: 'mockup'
	},
	{
		title: 'Laser',
		dataIndex: 'laser'
	},
	{
		title: 'Customer name',
		dataIndex: 'name'
	},
	{
		title: 'Phone',
		dataIndex: 'phone'
	},
	{
		title: 'Address 1',
		dataIndex: 'address1'
	},
	{
		title: 'Address 2',
		dataIndex: 'address2'
	},
	{
		title: 'City',
		dataIndex: 'city'
	},
	{
		title: 'State',
		dataIndex: 'state'
	},
	{
		title: 'Country',
		dataIndex: 'country_code'
	},
	{
		title: 'Postal code',
		dataIndex: 'zip_code'
	}
]

let shippingHeaders = [
	{
		title: 'order_number',
		dataIndex: 'title'
	},
	{
		title: 'Trạng thái',
		dataIndex: 'order_status'
	},
	{
		title: 'Partner tracking_number',
		dataIndex: 'shipping_tracking_number'
	},
	{
		title: 'tracking_number',
		dataIndex: 'shipping_tracking_partner'
	},
	{
		title: 'name',
		dataIndex: 'name'
	},
	{
		title: 'item',
		dataIndex: 'item_title'
	},
	{
		title: 'quantity',
		dataIndex: 'quantity'
	},
	{
		title: 'address1',
		dataIndex: 'address1'
	},
	{
		title: 'address2',
		dataIndex: 'address2'
	},
	{
		title: 'city',
		dataIndex: 'city'
	},
	{
		title: 'state',
		dataIndex: 'state'
	},
	{
		title: 'postalCode',
		dataIndex: 'zip_code'
	},
	{
		title: 'country',
		dataIndex: 'country_code'
	},
	{
		title: 'phone',
		dataIndex: 'phone'
	}
]
let trackingMoreHeaders = [
	{
		title: 'Courier Name *',
		dataIndex: 'courier_name'
	},
	{
		title: 'Tracking Number *',
		dataIndex: 'shipping_tracking_number'
	},
	// {
	// 	title: 'Logistic Channel',
	// 	dataIndex: 'empty'
	// },
	// {
	// 	title: 'Customer Email',
	// 	dataIndex: 'empty'
	// },
	{
		title: 'Order id',
		dataIndex: 'title'
	}
]

class ExportOrders extends React.Component {
	state = {
		option: 'today',
		month: current_month,
		year: current_year,
		data: [],
		start: now,
		end: now,
		order_status: '',
		shipping_carrier: '',
		file_template: 'trackingmore',
		selected_time: 'created_at',
		seller_id: '',
		loading: false,
	}

	handleMonthChange = (month, year) => {
		this.setState({
			month,
			year
		})
	}

	handleExport = async () => {
		this.setState({loading: true})
		const {handleChange} = this.props
		let {start, end, order_status, shipping_carrier, file_template, seller_id, selected_time} = this.state
		let params = {
			start_date: start,
			end_date: end,
			order_status: order_status || undefined,
			shipping_carrier: shipping_carrier || undefined,
			seller_id: seller_id || undefined,
			type: file_template,
			selected_time: selected_time
		}

		const result = await exportOrders(params)

		if (result.success && result.data) {
			let newItems = []

			result.data.map((item) => {
				const {shipping_address, seller} = item
				const {first_name, last_name, city, state, country_code, zip_code, phone, address, address_2} = shipping_address

				item.seller_name = `${seller.first_name} ${seller.last_name}`
				item.name = `${first_name} ${last_name}`
				item.city = city
				item.state = state
				item.country_code = country_code
				item.zip_code = zip_code
				item.phone = phone
				item.address1 = address
				item.address2 = address_2
				item.order_status = ORDER_STATUS[item.order_status]

				item.courier_name = 'dhlglobalmail'
				item.empty = ''

				if (file_template !== 'trackingmore') {
					item.line_items.map(line => {
						const {quantity, title, design, mockup, laser} = line
						item.quantity = quantity
						item.design = design
						item.mockup = mockup
						item.laser = laser
						item.item_title = title
						newItems.push({...item})
					})
				} else {
					newItems.push({...item})
				}
			})

			this.setState({data: newItems})
			document.querySelector('.download-file').click()
			showToast({
				message: 'Xuất đơn hàng thành công'
			})
			this.setState({loading: false})
			handleChange()
		} else {
			showToast({
				message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
				error: true
			})
		}

		console.log(result, 'result')
	}

	render() {
		const {option, month, year, start, end, shipping_carrier, selected_time, seller_id, order_status, loading, file_template, data} = this.state
		const {handleChange, open, label} = this.props
		if (shipping_carrier === 'other') shippingHeaders = shippingHeaders.filter(a => a.title !== 'tracking_number')

		let headers = orderHeaders
		if (file_template === 'trackingmore') headers = trackingMoreHeaders
		if (file_template === 'shipping') headers = shippingHeaders
		return (
			<div>
				<div style={{display: 'none'}}>
					<ExportSheet
						header={headers}
						fileName={'Orders'}
						dataSource={data}
						extName={'xlsx'}
						xlsx={XLSX}
					>
						<button className={'download-file'}>Download file</button>
					</ExportSheet>
				</div>
				{open && <Modal
					open={open}
					onClose={handleChange}
					title={label ? label : 'Xuất đơn hàng'}
					primaryAction={{
						content: 'Xuất file',
						onAction: this.handleExport,
						loading: loading,
					}}
					secondaryActions={[
						{
							content: 'Hủy',
							onAction: handleChange,
							disabled: loading
						}
					]}
				>
					<Modal.Section>
						<FormLayout>
							<ChoiceList
								title={'Chọn mẫu file'}
								choices={[
									{label: 'Mẫu Trackingmore', value: 'trackingmore'},
									{label: 'Mẫu vận chuyển', value: 'shipping'},
									{label: 'Mẫu tổng hợp', value: 'all'},
								]}
								selected={[file_template]}
								onChange={v => this.setState({file_template: v[0]})}
							/>
							<TextField
								label={'Seller ID'}
								value={seller_id}
								onChange={v => this.setState({seller_id: v.toUpperCase()})}
								placeholder={'Ví dụ: LEC'}
							/>
							{file_template === 'all' && <ChoiceList
								title={'Trạng thái đơn hàng'}
								choices={[
									{label: 'Tất cả', value: ''},
									{label: 'Chờ xác nhận', value: 'open'},
									{label: 'Đang in', value: 'printing'},
									{label: 'Đã hoàn thành', value: 'shipped'},
									{label: 'Không thành công', value: 'incompleted'},
									{label: 'Đã hủy', value: 'canceled'}
								]}
								selected={[order_status]}
								onChange={v => this.setState({order_status: v[0]})}
							/>}
							<ChoiceList
								title={'Đơn vị vận chuyển'}
								choices={[
									{
										label: 'Tất cả',
										value: ''
									},
									{
										label: 'Multrans',
										value: 'multrans'
									},
									{
										label: 'Khác',
										value: 'other'
									}
								]}
								selected={[shipping_carrier]}
								onChange={v => this.setState({shipping_carrier: v[0]})}
							/>
							{file_template !== 'trackingmore' && (
								<ChoiceList
									title={'Theo thời gian'}
									choices={[
										{
											label: 'Tạo đơn hàng',
											value: 'created_at'
										},
										{
											label: 'Xác nhận đơn hàng',
											value: 'confirmed_at'
										},
										{
											label: 'In xong',
											value: 'printed_at'
										},
										{
											label: 'Hoàn thiện xong',
											value: 'finished_at'
										},
										{
											label: 'Chuyển đi',
											value: 'shipping_at'
										},
										{
											label: 'Hoàn thành',
											value: 'shipped_at'
										}
									]}
									selected={[selected_time]}
									onChange={v => this.setState({selected_time: v[0]})}
								/>
							)}
							<ChoiceList
								title={'Lựa chọn thời gian'}
								choices={[
									{
										label: 'Hôm nay',
										value: 'today'
									},
									{
										label: 'Ngày hôm qua',
										value: 'yesterday'
									},
									{
										label: 'Tuần này',
										value: 'current_week'
									},
									{
										label: 'Tuần trước',
										value: 'last_week'
									},
									{
										label: 'Tháng này',
										value: 'current_month'
									},
									{
										label: 'Tháng trước',
										value: 'last_month'
									},
									{
										label: 'Thời gian tùy chỉnh',
										value: 'time_range'
									}
								]}
								selected={[option]}
								onChange={value => {
									this.setState({
										option: value[0],
										start: getTimeRage(value[0]).start ? getTimeRage(value[0]).start : firstDay,
										end: getTimeRage(value[0]).end ? getTimeRage(value[0]).end : now
									})
								}}
							/>
							{option === 'time_range' && <div style={{paddingTop: 10}}>
								<DatePicker
									month={month}
									year={year}
									multiMonth
									allowRange
									onChange={v => this.setState({
										start: moment(v.start).format('YYYY-MM-DD'),
										end: moment(v.end).format('YYYY-MM-DD')
									})}
									onMonthChange={this.handleMonthChange}
									disableDatesAfter={new Date()}
									selected={{
										start: new Date(start),
										end: new Date(end)
									}}
								/>
							</div>}
						</FormLayout>
					</Modal.Section>
				</Modal>}
			</div>
		)
	}
}

export default ExportOrders
