import React, {useState, useCallback} from 'react'
import {ThemeProvider} from 'styled-components'
import {AppProvider, Frame, TopBar} from '@shopify/polaris'
import translations from 'locales/polaris.json'
import {theme} from 'stores/theme'
import logo from 'core/images/logo.png'
import ToastFlash, {toastRef} from 'plugins/toast'
import Confirmation, {alertRef} from 'plugins/confirm'
import AppNavigation from "./modules/navigation"
import AppTopBar from 'modules/topBar'
import AppRoutes from "routes/index"
import Auth from "authentication/auth"
import {ADMIN_URL} from "stores/config"
import 'styles/css-override.scss'
import 'styles/ant.css'

function App() {
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false)

	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive,
			),
		[],
	)

	const appTheme = {
		colors: {
			topBar: {
				background: 'rgb(28, 34, 96)'
			},
			surface: '#111213',
			onSurface: '#111213',
			interactive: '#2e72d2',
			secondary: '#111213',
			primary: '#3b5998',
			critical: '#d82c0d',
			warning: '#ffc453',
			highlight: '#5bcdda',
			success: '#008060',
			decorative: '#ffc96b',
		},
		logo: {
			width: 125,
			topBarSource: logo,
			contextualSaveBarSource: logo,
			url: ADMIN_URL,
			accessibilityLabel: 'CoreBear CMS'
		}
	}

	return (
		<AppProvider
			i18n={translations}
			theme={appTheme}
			features={{newDesignLanguage: true}}
		>
			<Auth>
				<Frame
					topBar={
						<AppTopBar
							onNavigationToggle={toggleMobileNavigationActive}
						/>
					}
					navigation={<AppNavigation/>}
					showMobileNavigation={mobileNavigationActive}
					onNavigationDismiss={toggleMobileNavigationActive}
				>
					<ThemeProvider theme={theme}>
						<div className={'app-wrapper'}>
							<ToastFlash ref={toastRef}/>
							<Confirmation ref={alertRef}/>
							<AppRoutes/>
						</div>
					</ThemeProvider>
				</Frame>
			</Auth>
		</AppProvider>
	)
}

export default App
