import React from 'react'
import {Icon, Button} from "@shopify/polaris"
import {Location, navigate} from "@reach/router"

const NavSection = ({items, fill, title, separator}) => {
	let classes = []
	if (separator) classes.push('Polaris-Navigation__Section--withSeparator')
	if (fill) classes.push('Polaris-Navigation__Section--fill')
	return (
		<Location>
			{({location}) => {
				let {pathname, search} = location
				pathname += search
				return (
					<ul className={`Polaris-Navigation__Section ${classes.join(' ')}`}>
						{title &&
						<li className="Polaris-Navigation__SectionHeading"><span
							className="Polaris-Navigation__Text">{title}</span></li>}
						{items.map((item, i) => {
							let selected, active = false

							selected = pathname === item.url

							if (item.subNavigationItems && item.subNavigationItems.length > 0) {
								selected = false
							}
							if (item.subNavigationItems && item.subNavigationItems.length > 0 && selected) active = true

							item.subNavigationItems && item.subNavigationItems.map(childItem => {
								if (pathname.includes(childItem.url)) {
									active = true
								}
								// if (pathname.includes(childItem.url)) {
								// 	selected = true
								// }
							})
							return (
								<li key={i} className="Polaris-Navigation__ListItem">
									<button type="button"
											className={`Polaris-Navigation__Item ${selected ? 'Polaris-Navigation__Item--selected' : ''}`}
											onClick={() => navigate(item.url)}>
										{item.icon && <div className="Polaris-Navigation__Icon">
											<Icon source={item.icon}/>
										</div>}
										<span className="Polaris-Navigation__Text">{item.label}</span>
										{item.badge && <div className="Polaris-Navigation__Badge"><span
											className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall"><span
											className="Polaris-Badge__Content">{item.badge}</span></span>
										</div>}
									</button>
									{item.secondaryAction && (
										<div className="secondaryAction">
											<Button
												external
												plain
												url={item.secondaryAction.url && item.secondaryAction.url}
												icon={item.secondaryAction.icon && item.secondaryAction.icon}
											>
												{item.secondaryAction.label && item.secondaryAction.label}
											</Button>
										</div>
									)}
									{active && (
										<div className="Polaris-Navigation__SecondaryNavigation">
											<div id="PolarisSecondaryNavigation2" aria-hidden="false"
												 className="Polaris-Collapsible Polaris-Collapsible--open Polaris-Collapsible--fullyOpen"
												 style={{maxHeight: 'inherit'}}>
												<div>
													<ul className="Polaris-Navigation__List">
														{item.subNavigationItems.map((child, index) => {
															let childSelected
															if (item.url !== '/') {
																childSelected = pathname.includes(child.url)
															} else {
																childSelected = pathname === child.url
															}
															if (pathname.includes('inventory') && child.url === '/products') childSelected = false
															if (pathname.includes('order_status') && child.url === '/orders') childSelected = false
															if (pathname.includes('orders/') && child.url === '/orders') childSelected = false
															if (pathname.includes('status') && child.url === '/items') childSelected = false
															return (
																<li key={index}
																	className="Polaris-Navigation__ListItem">
																	<button type="button"
																			className={`Polaris-Navigation__Item ${childSelected ? 'Polaris-Navigation__Item--selected' : ''}`}
																			onClick={() => navigate(child.url)}>
																		<span
																			className="Polaris-Navigation__Text">{child.label}</span>
																	</button>
																</li>
															)
														})}
													</ul>
												</div>
											</div>
										</div>
									)}
								</li>
							)
						})}
					</ul>
				)
			}}
		</Location>
	)
}
export default NavSection
