import React, {useContext} from 'react'
import {Card} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {ProductContext} from "../container"
import {useTranslation} from "react-i18next"
import ResourceSelector from "components/resourceSelector"
import {useCollections} from "pages/collections/container"

export default function CardOrganization() {
	const pageSub = useContext(ProductContext)
	let {state, setState} = useSubscription(pageSub)
	const {collections} = state
	const {t} = useTranslation()
	return (
		<Card sectioned title={t('Product type')}>
			<ResourceSelector
				source={useCollections}
				placeholder={t('Select type')}
				value={collections[0]}
				onChange={v => setState({collections: [v]})}
			/>
		</Card>
	)
}
