import React from 'react'
import styled from 'styled-components'
import {Router} from '@reach/router'
import Dashboard from "pages/dashboard"
import SettingsPages from "./settings"
import ManagePage from "./manage"
import AuthPages from "./auth"
import CustomerPages from "routes/customers"
import OrderPages from "./orders"
import ItemPages from "./items"
import ProductPages from "routes/products"
import TypePages from "routes/./types"
import NotFound from "pages/404"

const Main = styled.div`
	.Polaris-Layout {
		& + .Polaris-PageActions {
			margin-top: 2rem;
		}
	}
	.Polaris-ResourceItem__Container {
    	align-items: center;
    }
    .text-right {
    	text-align: right;
    }
`

const AppRoutes = () => (
	<Main>
		<AuthPages/>
		<Router>
			<NotFound default />
			<Dashboard path="/"/>
			<OrderPages path="/orders/*"/>
			<ItemPages path="/items/*"/>
			<CustomerPages path="/sellers/*"/>
			<SettingsPages path="/settings/*"/>
			<ProductPages path="/products/*"/>
			<TypePages path="/types/*"/>
			<ManagePage path="/manage/*"/>
		</Router>
	</Main>
)
export default AppRoutes
