import React, {useContext, useEffect, useState} from 'react'
import {Card, Button, ButtonGroup, Link, Badge, Stack} from '@shopify/polaris'
import {ItemsContext} from "../container"
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import Table from "components/table"
import {saveSvgToPNG, printHTML, getImageUrl} from "helpers/utils"
import {PrintMinor, SaveMinor, DeleteMinor, EditMinor} from '@shopify/polaris-icons'
import Barcode from "react-barcode"
import OrderStatusBadge from "modules/orderStatus"

export default function Summary({user}) {

	const orderSub = useContext(ItemsContext)
	let {setState, state} = useSubscription(orderSub)
	const {item_sku, _id, order_title, order_id, item_id, title, design, color, laser, mockup, quantity, quantity_printed, quantity_finished, order_status, shipping_label} = state
	const {t} = useTranslation()
	// const handleToggle = useCallback(() => setActive((active) => !active), [])

	useEffect(() => {
		const svg = document.querySelector('#item-' + _id + ' svg')
		handleCreateBarCode(svg)
	}, [])

	const handleSaveBarCode = () => {
		const svg = document.querySelector('#item-' + _id + ' svg')
		saveSvgToPNG(svg, item_sku.substring(0, 16), 880, 280)
	}

	const handlePrint = () => {
		const content = document.querySelector('#item-' + _id)
		printHTML(content)
	}

	const barCode = React.createRef()
	const handleCreateBarCode = (svg) => {
		// svg.setAttribute('width', 800)
		// svg.setAttribute('height', height * 2)
		var svgData = new XMLSerializer().serializeToString( svg );

		var canvas = document.createElement( "canvas" );
		var ctx = canvas.getContext( "2d" );

		var img = barCode.current
		img.setAttribute( "src", "data:image/svg+xml;base64," + btoa( svgData ) );

		img.onload = function() {
			ctx.drawImage( img, 0, 0 );
		};
	}

	return (
		<Card>
			<Card.Header title={'Chi tiết Item'}>
				<Stack>
					<ButtonGroup>
						{shipping_label && <Button external icon={SaveMinor} url={getImageUrl(shipping_label)} plain>Tải Label</Button>}
						<Button icon={SaveMinor} onClick={handleSaveBarCode} plain>Tải Barcode</Button>
						<Button icon={PrintMinor} onClick={handlePrint} plain>In Barcode</Button>
					</ButtonGroup>
				</Stack>
			</Card.Header>
			<div style={{display: 'none'}}>
				<iframe id="datatoprint" />
				<div id={`item-${_id}`} key={item_sku}>
					<h3 style={{textAlign: 'center', margin: 0}}><strong>{title} x {quantity}</strong></h3>
					<Barcode
						key={item_sku}
						value={item_sku}
						width={4}
						height={250}
						fontSize={45}
					/>
				</div>
			</div>
			<Card.Section subdued>
				<Table bordered>
					<tbody>
					<tr>
						<th>Đơn hàng:</th>
						<td colSpan={3}>
							<div style={{display:'flex'}}>
								<Link url={`/orders/view/${order_id}`}>{order_title}</Link> &nbsp;<OrderStatusBadge order_status={order_status} />
							</div>
						</td>
					</tr>
					<tr>
						<th width={150}>Loại Item:</th>
						<td width={300}>
							<span>{title} - {item_id.toUpperCase()}</span>
							{color && <div style={{paddingTop: 10}}>Màu: <strong>{color}</strong></div>}
						</td>
						<th width={150}>Mã SKU:</th>
						<td>{item_sku}</td>
					</tr>
					<tr>
						<th width={150}>Đã in:</th>
						<td width={200}>{quantity_printed && quantity_printed.toString()}/{quantity} cái</td>
						<th width={150}>Đã hoàn thiện:</th>
						<td>{quantity_finished && quantity_finished.toString()}/{quantity} cái</td>
					</tr>
					<tr>
						<th width={150}>Files:</th>
						<td width={200} colSpan={4}>
							<ButtonGroup>
								<Button plain external url={design}>Thiết kế</Button>
								<Button plain external url={mockup}>Mockup</Button>
								{laser && <Button plain external url={laser}>Laser</Button>}
							</ButtonGroup>
						</td>
					</tr>
					<tr>
						<td colSpan={4} style={{textAlign: 'center'}}>
							<img ref={barCode} width={'100%'} />
						</td>
					</tr>
					</tbody>
				</Table>
			</Card.Section>
		</Card>
	)
}
