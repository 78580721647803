import React, {useContext} from 'react'
import {Card, FormLayout, TextField} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {TagContext} from "../container"
import {useTranslation} from "react-i18next"

export default function CardTitle({error}) {
	const {t} = useTranslation()
	const pageSub = useContext(TagContext)
	let {state, setState} = useSubscription(pageSub)
	const {title, description} = state
	return (
		<Card sectioned>
			<FormLayout>
				<TextField
					label={t('Tên thẻ')}
					value={title}
					onChange={v => setState({title: v})}
				/>
				<TextField
					label={t('Description')}
					value={description}
					multiline={3}
					onChange={v => setState({description: v})}
				/>
			</FormLayout>
		</Card>
	)
}
