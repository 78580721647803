import React, {useEffect, useState, useCallback, useContext} from 'react'
import {
	Page,
	Layout,
	Card,
	ChoiceList,
	ResourceItem,
	TextStyle,
	Badge,
	TextContainer,
	Button, DatePicker
} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {navigate, Redirect} from "@reach/router"
import {SkeletonList} from "modules/skeleton"
import {AuthConsumer, authContext} from "authentication/auth"
import DataList from "modules/dataList"
import Filters from "modules/filters"
import Pagination from "modules/pagination"
import {updateParam, getTimeRage} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import empty from 'images/order-empty.svg'
import Can from "authentication/can"
import {ExportMinor, ImportMinor} from '@shopify/polaris-icons'
import moment from "moment"
import ImportOrders from "components/importOrders"
import TagsList from "components/tagsList"
import OrderStatusBadge from "modules/orderStatus"
import ReviewOrder from "./components/reviewOrder"
import ExportOrders from "./components/ExportOrders"
import ImportTracking from "./components/importTracking"


const d = new Date()
const current_month = d.getMonth()
const current_year = d.getFullYear()
const firstDay = moment().startOf('month').format('YYYY-MM-DD')
const now = moment().format('YYYY-MM-DD')
const OrdersListView = ({title, data, handleSearch, appliedFilters, setAppliedFilters, selectedItems, setSelectedItems, handleUpdateItems, date = 'created_at', renderItem, dataHeader, promotedBulkActions, dataFilters, hasFilterShipping, highLight = false}) => {
	const [openImport, setOpenImport] = useState(false)
	const [openImportTracking, setOpenImportTracking] = useState(false)
	const [openExport, setOpenExport] = useState(false)
	const [openReview, setOpenReview] = useState(false)
	const [currentOrder, setCurrentOrder] = useState({})
	const [forceUpdate, setForceUpdate] = useState(false)

	const [customDate, setCustomDate] = useState({
		month: current_month,
		year: current_year,
		start: firstDay,
		end: now
	})
	const {t} = useTranslation()

	const {ready, last_page, current_page, isSearched, to, total} = data

	const userAuth = useContext(authContext)

	const handleFiltersChange = useCallback(
		(key, value) => {
			// Reset current order
			updateParam('order', '', null)
			// Update new filter
			appliedFilters[key] = value
			setAppliedFilters({...appliedFilters})
			if (key === 'processed_at') {
				updateParam('start_at', getTimeRage(value).start, null)
				updateParam('end_at', getTimeRage(value).end, null)
			} else {
				updateParam(key, value + '', null)
			}

			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFilterDateChange = useCallback(
		(start, end) => {
			updateParam('start_at', start, null)
			updateParam('end_at', end, null)
			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFiltersRemove = useCallback(
		(key) => {
			// Reset current order
			updateParam('order', '', null)
			delete appliedFilters[key]
			setAppliedFilters({...appliedFilters})
			updateParam(key, '', null)

			if (key === 'processed_at') {
				updateParam('start_at', '', null)
				updateParam('end_at', '', null)
			}
			// Run search with new filters
			handleSearch()
		},
		[]
	)
	const handleFiltersClearAll = () => {
		for (const obj of Object.keys(appliedFilters)) {
			delete appliedFilters[obj]
		}
		setAppliedFilters({...appliedFilters})
	}

	const {label_created, shipping_carrier, shipping_status, processed_at} = appliedFilters

	const filters = [
		{
			key: 'label_created',
			label: t('Trạng thái Label'),
			filter: (
				<ChoiceList
					title={t('Trạng thái Label')}
					titleHidden
					choices={[
						{label: 'Đã tạo Label', value: 'true'},
						{label: 'Chưa tạo Label', value: 'false'}
					]}
					selected={[label_created]}
					onChange={v => handleFiltersChange('label_created', v[0])}
					// allowMultiple
				/>
			),
			shortcut: true
		},
		{
			key: 'shipping_carrier',
			label: t('Đơn vị vận chuyển'),
			filter: (
				<ChoiceList
					title={t('Đơn vị vận chuyển')}
					titleHidden
					choices={[
						{label: 'Multrans', value: 'multrans'},
						{label: 'Khác', value: 'other'}
					]}
					selected={[shipping_carrier]}
					onChange={v => handleFiltersChange('shipping_carrier', v[0])}
					// allowMultiple
				/>
			),
			shortcut: true
		}
	]

	if (hasFilterShipping) {
		filters.push({
			key: 'shipping_status',
			label: t('Trạng thái vận chuyển'),
			filter: (
				<ChoiceList
					title={t('Trạng thái vận chuyển')}
					titleHidden
					choices={[
						{label: 'Pending', value: 'pending'},
						{label: 'Not Found', value: 'notfound'},
						{label: 'Transit', value: 'transit'},
						{label: 'Pickup', value: 'pickup'},
						{label: 'Expired', value: 'expired'},
						{label: 'Delivered', value: 'delivered'},
						{label: 'Undelivered', value: 'undelivered'},
						{label: 'Exception', value: 'exception'},
					]}
					selected={[shipping_status]}
					onChange={v => handleFiltersChange('shipping_status', v[0])}
					// allowMultiple
				/>
			),
			shortcut: true
		})
	}

	const handleMonthChange = (month, year) => {
		setCustomDate({...customDate, month: month, year: year})
	}
	const dateFilter = {
		key: 'date',
		label: t('Thời gian'),
		filter: (
			<>
				<ChoiceList
					title={t('Thời gian')}
					titleHidden
					choices={[
						{
							label: 'Hôm nay',
							value: 'today'
						},
						{
							label: 'Ngày hôm qua',
							value: 'yesterday'
						},
						{
							label: 'Tuần này',
							value: 'current_week'
						},
						{
							label: 'Tuần trước',
							value: 'last_week'
						},
						{
							label: 'Tháng này',
							value: 'current_month'
						},
						{
							label: 'Tháng trước',
							value: 'last_month'
						},
						{
							label: 'Thời gian tùy chỉnh',
							value: 'custom'
						}
					]}
					selected={[processed_at]}
					onChange={v => {
						setCustomDate({
							...customDate,
							start: getTimeRage(v[0]).start ? getTimeRage(v[0]).start : firstDay,
							end: getTimeRage(v[0]).end ? getTimeRage(v[0]).end : now
						})
						handleFiltersChange('processed_at', v[0])
					}}
					// allowMultiple
				/>
				{processed_at === 'custom' && <div style={{paddingTop: 10}}>
					<DatePicker
						month={customDate.month}
						year={customDate.year}
						multiMonth
						allowRange
						onChange={v => {
							const start =  moment(v.start).format('YYYY-MM-DD')
							const end =  moment(v.end).format('YYYY-MM-DD')
							setCustomDate({
								...customDate,
								start: start,
								end: end
							})
							handleFilterDateChange(start, end)
						}}
						onMonthChange={handleMonthChange}
						disableDatesAfter={new Date()}
						selected={{
							start: new Date(customDate.start),
							end: new Date(customDate.end)
						}}
					/>
				</div>}
			</>
		),
		shortcut: true
	}

	if (dataFilters) {
		dataFilters[0] = dateFilter
	} else {
		filters.unshift(dateFilter)
	}
	const dateTitle = {
		created_at: 'Tạo lúc',
		confirmed_at: 'Xác nhận lúc',
		finished_at: 'Lưu kho lúc',
		shipped_at: 'Giao hàng lúc',
		printed_at: 'In xong lúc',
		shipping_at: 'Chuyển đi lúc',
		canceled_at: 'Hủy lúc',
	}

	const headers = [
		{
			id: 'order',
			title: t('Đơn hàng'),
			width: 25
		},
		{
			id: 'created_at',
			title: dateTitle[date],
			width: 20
		},
		{
			id: 'seller',
			title: t('Seller'),
			width: 25
		},
		{
			id: 'order_status',
			title: t('Trạng thái'),
			width: 15
		},
		{
			id: 'quick_action',
			title: '',
			width: 15
		}
	]

	const renderItemData = (item) => {
		const {_id, title, created_at, seller, seller_id, order_status, tags, notes, shipped_at, confirmed_at} = item
		let variation
		switch (order_status) {
			case 'closed':
				variation = 'subdued'
				break
			case 'canceled':
				variation = 'negative'
				break
			default:
				break
		}
		const fullname = seller ? [seller.first_name, seller.last_name].filter(v => v !== '') : []

		const lastNote = notes && notes.length > 0 && notes.slice(-1).pop()

		const now = moment()

		let className = ['item-wrapper']

		const diff = now.diff(new Date(item[date]), 'hours')
		if (highLight && diff >= 24) className.push('overdue')

		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/orders/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className={className.join(' ')}>
					<div className="column title">
						<TextContainer>
							<TextStyle variation={variation}><strong>{title}</strong></TextStyle>
							{lastNote && lastNote.type !== 'auto' && <div><TextStyle variation={lastNote.type && lastNote.type === 'error' && 'negative'}>{lastNote.message}</TextStyle></div>}
							<TagsList tags={tags}/>
						</TextContainer>
					</div>
					<div className="column" style={{width: '20%'}}>
						<TextStyle>{moment(item[date]).format('DD-MM-YY HH:mm')}</TextStyle>
					</div>
					<div className="column" style={{width: '25%'}}>
						{fullname.join(' ')}&nbsp;<Badge><strong>{seller_id}</strong></Badge>
					</div>
					<div className="column" style={{width: '15%'}}>
						<OrderStatusBadge order_status={order_status}/>
					</div>
					<div className="column" style={{width: '15%'}}>
						<Button
							size={'slim'}
							onClick={e => {
								e.preventDefault()
								e.stopPropagation()
								setCurrentOrder(item)
								handleToggleReview()
							}}
						>
							Xem nhanh
						</Button>
					</div>
				</div>
			</ResourceItem>
		)
	}

	const handleToggleImport = useCallback(() => setOpenImport(!openImport), [openImport])
	const handleToggleImportTracking = useCallback(() => setOpenImportTracking(!openImportTracking), [openImportTracking])
	const handleToggleExport = useCallback(() => setOpenExport(!openExport), [openExport])
	const handleToggleReview = useCallback(() => setOpenReview(!openReview), [openReview])
	const handleToggleUpdate = useCallback(() => setForceUpdate(!forceUpdate), [forceUpdate])

	let items = data.data || []
	items = items && items.length && items.map(item => {
		item.id = item._id
		return item
	})

	let secondaryActions = []

	let primaryAction = {
		content: t('Create order'),
		onAction: () => navigate('/orders/new')
	}

	if (userAuth) {
		const {role} = userAuth.user
		if (['finisher', 'warehouse_manager', 'printer'].includes(role)) {
			promotedBulkActions = undefined
			secondaryActions = []
			primaryAction = undefined
		}

		if (['admin', 'orders_manager'].includes(role)) {
			secondaryActions.push(
				{
					content: t('Nhập mã Tracking'),
					accessibilityLabel: 'Import tracking',
					plain: true,
					icon: ImportMinor,
					onClick: handleToggleImportTracking
				},
				{
					content: t('Import orders'),
					accessibilityLabel: 'Import orders',
					plain: true,
					onClick: handleToggleImport,
					icon: ImportMinor
				}
			)
		}
		if (['admin', 'orders_manager', 'accountant'].includes(role)) {
			secondaryActions.push({
					content: t('Export orders'),
					accessibilityLabel: 'Export orders',
					plain: true,
					onClick: handleToggleExport,
					icon: ExportMinor
			})
		}
	}


	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				if (authenticated) {
					return (
						<Can
							role={user.role}
							perform="orders:list"
							yes={() => {
								return ready ? (
									<Page
										title={title ? title : 'Đơn hàng'}
										fullWidth
										primaryAction={primaryAction}
										secondaryActions={secondaryActions}
									>
										{openImport && <ImportOrders user={user} handleChange={handleToggleImport} open={openImport}/>}
										{openImportTracking &&
										<ImportTracking user={user} handleChange={handleToggleImportTracking} open={openImportTracking}/>}
										{openExport && <ExportOrders user={user} handleChange={handleToggleExport} open={openExport}/>}
										{openReview && <ReviewOrder order={currentOrder} handleToggle={handleToggleReview} open={openReview}
																								forceUpdate={handleToggleUpdate} handleUpdateItems={handleUpdateItems}
																								items={items}/>}
										<Layout>
											<Layout.Section>
												{items && items.length > 0 || isSearched ? (
													<Card sectioned>
														<Filters
															filters={dataFilters ? dataFilters : filters}
															handleSearch={handleSearch}
															appliedFilters={appliedFilters}
															handleFiltersChange={handleFiltersChange}
															handleFiltersRemove={handleFiltersRemove}
															handleFiltersClearAll={handleFiltersClearAll}
														/>
														<div className="results-count">
															{t('showing', {to: to, total: total, resourceName: 'đơn hàng'})}
														</div>
														<DataList
															resourceName={{singular: t('Đơn hàng'), plural: t('Đơn hàng')}}
															items={items}
															selectedItems={selectedItems}
															onSelectionChange={setSelectedItems}
															promotedBulkActions={promotedBulkActions || undefined}
															headers={dataHeader ? dataHeader : headers}
															orderType={'orders'}
															renderItem={renderItem ? renderItem : renderItemData}
														/>
														<Pagination
															pageNumber={current_page}
															lastPage={last_page}
															path={'orders'}
														/>
													</Card>
												) : <EmptyPage
													image={empty}
													description={'Chưa có đơn hàng nào'}
												/>}
											</Layout.Section>
										</Layout>
									</Page>
								) : <SkeletonList fullWidth/>
							}}
						/>
					)
				} else {
					return <Redirect to={'/login'} noThrow/>
				}
			}}
		</AuthConsumer>
	)
}
export default OrdersListView
