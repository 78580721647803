import React, {useEffect} from 'react'
import {Page, Layout, EmptyState} from "@shopify/polaris"
import {navigate} from "@reach/router"
import {useTranslation} from "react-i18next"
import notFound from 'images/404.svg'
import EmptyPage from "modules/emptyPage"
const NotFound = () => {
	const {t} = useTranslation()
	return (
		<div className="page-404">
			<Page>
				<Layout>
					<EmptyPage
						title={t("Opps, this page couldn't be found")}
						primaryAction={{content: t('Về trang chủ'), onAction: () => navigate('/')}}
						image={notFound}
						description={t('Check the web address and try again')}
					/>
				</Layout>
			</Page>
		</div>
	)
}
export default NotFound
