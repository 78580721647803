import React from 'react'
import {DisplayText, TextContainer, TextStyle} from "@shopify/polaris"
import noResults from 'images/no-results.svg'
import {useTranslation} from "react-i18next"
import styled from "styled-components"

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: column;
	align-items: center;
	padding: 2rem;
	text-align: center;
	img {
		margin-bottom: 20px;
	}
	.Polaris-DisplayText {
		margin-bottom: 2rem;
	}
`

export default function NoResults({resourceName}) {
  const {t} = useTranslation()
  return (
	<Wrapper>
	  <img src={noResults} alt="" width={180}/>
	  <TextContainer>
		<DisplayText size="medium">{t('no_results', {resourceName: resourceName.toLowerCase()})}</DisplayText>
		<TextStyle>{t('Try changing the filters or search term')}</TextStyle>
	  </TextContainer>
	</Wrapper>
  )
}