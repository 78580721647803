import React, {useCallback, useContext, useState} from 'react'
import {Card, Badge, FormLayout, Select, TextField, Stack, Button} from '@shopify/polaris'
import {OrderContext, createLabel, updateOrder} from "../container"
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import {showToast} from "plugins/toast"
import {EditMinor, PrintMinor} from "@shopify/polaris-icons"
import {getImageUrl} from "helpers/utils"
import axios from "axios"

export default function Shipping() {

	const [disabled, setDisabled] = useState(true)
	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false)

	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const {shipping_status, shipping_carrier, shipping_tracking_number, shipping_label, label_created, order_status, title} = state
	const {t} = useTranslation()
	let shippingProgress, shippingStatus
	switch (shipping_status) {
		case 'incomplete':
			shippingProgress = 'incomplete'
			shippingStatus = 'attention'
			break
		case 'available':
		case 'shipping':
			shippingProgress = 'partiallyComplete'
			shippingStatus = 'info'
			break
		case 'shipped':
			shippingProgress = 'complete'
			shippingStatus = ''
			break
		case 'returned':
			shippingProgress = 'complete'
			shippingStatus = 'critical'
			break
		default:
			break
	}

	const handleSave = () => {
		setSaving(true)
		const {shipping_carrier, _id, shipping_tracking_number} = state
		const data = {
			_id: _id,
			shipping_tracking_number: shipping_tracking_number,
			shipping_carrier: shipping_carrier,
		}
		updateOrder(data).then((res) => {
			showToast({
				message: 'Cập nhật thành công'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleCreateLabel = () => {
		setLoading(true)
		createLabel({title: title})
			.then(response => {
				const {data} = response
				if (data.success) {
					const {shipping_label, shipping_tracking_number} = data.data
					setState({label_created: true, shipping_label: shipping_label, shipping_tracking_number: shipping_tracking_number})
					showToast({
						message: 'Tạo Label thành công'
					})
				} else {
					showToast({
						message: t('Không tạo được Label, vui lòng kiểm tra lại thông tin, địa chỉ nhận hàng.'),
						error: true
					})
				}
				console.log(response, 'response')
			})
			.catch(error => console.log(error))
			.finally(() => {
				setLoading(false)
			})
	}

	const handleToggleEdit = useCallback(() => {
		setDisabled(disabled => !disabled)
		setTimeout(() => {
			document.querySelector('[name="shipping_tracking_number"]').select()
		}, 100)
	}, [])

	const trackingUrl = shipping_carrier === 'other' ? `https://t.17track.net/en#nums=${shipping_tracking_number}` : `https://webtrack.dhlglobalmail.com/?trackingnumber=${shipping_tracking_number}`
	return (
		<Card>
			<Card.Header title={t('Vận chuyển')}>
				<Stack aligment={'center'}>
					{['printing', 'finishing', 'storing'].includes(order_status) &&
					<Button loading={loading} size={'slim'} onClick={handleCreateLabel}>{!label_created ? 'Tạo Label' : 'Cập nhật Label'}</Button>}
					{!label_created && <Badge status={'attention'}>Chưa tạo Label</Badge>}
					{shipping_label &&
					<Button outline size={'slim'} icon={PrintMinor} external url={getImageUrl(shipping_label)}>In Label</Button>}
				</Stack>
			</Card.Header>
			<Card.Section subdued>
				<FormLayout>
					<FormLayout.Group>
						<Select
							label={t('Shipping carrier')}
							value={shipping_carrier}
							options={[
								{label: "Chọn đơn vị vận chuyển", value: ''},
								{label: "Multrans", value: 'multrans'},
								{label: "Other", value: 'other'}
							]}
							onChange={v => setState({shipping_carrier: v})}
						/>
						<TextField
							label={t('Tracking code')}
							disabled={disabled}
							value={shipping_tracking_number}
							onChange={v => setState({shipping_tracking_number: v})}
							name={'shipping_tracking_number'}
							connectedRight={
								<Button icon={EditMinor} onClick={handleToggleEdit}/>
							}
							labelAction={shipping_tracking_number ? {
								content: 'Xem tracking',
								onAction: () => window.open(trackingUrl, '_blank')
							} : undefined}
						/>
					</FormLayout.Group>
				</FormLayout>
			</Card.Section>
			{shipping_status !== 'completed' && <Card.Section>
				<Stack>
					<Stack.Item fill/>
					<Stack.Item>
						<Button outline onClick={handleSave} loading={saving} disabled={saving}>Cập nhật</Button>
						{/*{shipping_status === 'incomplete' && <Button primary disabled={!shipping_carrier} onClick={() => handleSave('available')}>{t('Ready to ship')}</Button>}*/}
						{/*{shipping_status === 'incomplete' && <Button primary disabled={!shipping_carrier} onClick={() => handleSave('shipping')}>{t('Start shipping')}</Button>}*/}
						{/*{shipping_status === 'shipping' && <Button primary disabled={!shipping_carrier} onClick={() => handleSave('completed')}>{t('Mark as shipped')}</Button>}*/}
					</Stack.Item>
				</Stack>
			</Card.Section>}
		</Card>
	)
}
