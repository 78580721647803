import {createSubscription, useSubscription} from "global-state-hook"
import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"
import {navigate} from "@reach/router"

export const itemInitalState = {
	id: '',
	item_id: '',
	design: '',
	mockup: '',
	laser: '',
	color: '',
	quantity: 1,
	quantity_printed: 0,
	status: 'pending',
	quantity_finished: 0,
	finished: false,
	item_sku: ''
}

export const initialState = {
	id: '',
	title : '',
	order_status : "open",
	shipping_status : "pending",
	seller : {
		id : '',
		email : '',
		first_name : '',
		last_name : '',
		phone : ''
	},
	seller_id: '',
	shipping_address : {
		first_name : '',
		last_name : '',
		phone : '',
		city : '',
		district : '',
		address : '',
		address_2 : '',
		// wards : '',
		// province: '',
		state: '',
		country_code : 'US',
		zip_code: ''
	},
	shipping_carrier : 'multrans',
	shipping_tracking_number : '',
	shipping_tracking_partner : '',
	shipping_label: '',
	label_created: false,
	cancel_reason : '',
	canceled_at : '',
	shipped_at : '',
	confirmed_at : '',
	printed_at : '',
	shipping_at : '',
	line_items : [],
	cnote : '',
	notes : [],
	seller_order_id: '',
	tags: [],
	width: '',
	height: '',
	length: '',
	weight: ''
}

export const OrderContext = createContext({...initialState})

export const createOrder = (state) => {
	return new Promise((resolve, reject) => {
		axios.post('/orders', {
			...state
		})
			.then(res => resolve(res.data))
			.catch(reject)
	})
}
export const fetchOrder = id => {
	return new Promise(resolve => {
		axios.get(`/orders?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}

export const exportOrders = (params) => {
	return new Promise(resolve => {
		axios.get('/orders/export', {
			params: params,
			// cancelToken: cancelToken,
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const fetchOrderByTitle = title => {
	return new Promise(resolve => {
		axios.get(`/orders/detail?title=${title}`)
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}

export const fetchOrderByTracking = tracking_number => {
	return new Promise(resolve => {
		axios.get(`/orders/order_by_tracking?tracking_number=${tracking_number}`)
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}
export const updateOrder = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/orders/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const deleteOrders = (ids, redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/orders/delete?ids=${ids}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'Xóa đơn hàng thành công'
					})
					if (redirect) {
						navigate('/orders')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const updateMultiple = (ids, status) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/orders/updateMultiple?ids=${ids}&order_status=${status}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'Cập nhật thành công'
					})
					// if (shipping_carrier === 'multrans') {
					// 	createLabel({title: title}).then(res => {
					// 		console.log(res, 'create label res')
					// 	})
					// }
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const handleShip = (tracking_number) => {
	return new Promise((resolve, reject) => {
		axios.get(`/orders/ship?tracking_number=${tracking_number}`)
			.then(response => {
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const archiveOrders = (ids, status = 'closed', redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/orders/archive?ids=${ids}&status=${status}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: status === 'closed' ? 'Orders archived' : 'Orders unarchived'
					})
					if (redirect) {
						navigate('/orders')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}
export const fetchOrdersList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 50,
		page: 1
	}, params)
	return new Promise(resolve => {
		axios.get('/orders', {
			params: newParams,
			cancelToken: cancelToken,
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const searchOrders = (params, cancelToken) => {
	console.log('run search')
	return new Promise(resolve => {
		axios.get('/orders', {
			params: {
				page: 1,
				limit: 50,
				...params
			},
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const ordersListSubscription = createSubscription({ready: false})
export const useOrders = () => {
	let {state, setState} = useSubscription(ordersListSubscription)
	const fetchList = (params) => {
		const newParams = Object.assign({
			limit: 20,
			page: 1
		}, params)
		return new Promise(resolve => {
			axios.get('/orders', {
				params: newParams
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_order, total, last_order} = response.data
					setState({items: data, current_order: current_order, total: total, last_order: last_order, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	const search = (params) => {
		console.log('run search')
		return new Promise(resolve => {
			axios.get('/orders', {
				params: {
					page: 1,
					limit: 20,
					...params
				}
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_order, total, last_order} = response.data
					setState({items: data, current_order: current_order, total: total, last_order: last_order, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	return {state, setState, fetchList, search}
}


export const fetchOrderItem = item_sku => {
	return new Promise(resolve => {
		axios.get(`/items/detail?item_sku=${item_sku}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response
				resolve(data)
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}
export const updateOrderItem = (data) => {
	const {item_sku, quantity} = data
	return new Promise((resolve, reject) => {
		axios.get(`/orders/updateItem?id=${item_sku}&quantity=${quantity}`)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const createLabel = (data) => {
	const {title} = data
	return new Promise((resolve, reject) => {
		axios.get(`/orders/label/${title}`)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}
