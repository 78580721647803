import React, {useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardTitle from "./components/cardTitle"
import CardStatus from "./components/cardStatus"
import CardSizes from "./components/cardSizes"
import CardOrganization from "./components/cardOrganization"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {createProduct, initialState, ProductContext} from "./container"
import {showToast} from "plugins/toast"
import {createSubscription, useSubscription} from "global-state-hook"
import Can from "authentication/can"
import { HotKeys } from "react-hotkeys"
import {keyMaps} from "stores/keymap"

const CreateProduct = () => {

	const pageSub = createSubscription(initialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('Add product')
	})

	return <ProductContext.Provider value={pageSub}>
		<CreateProductView/>
	</ProductContext.Provider>
}


const CreateProductView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(ProductContext)
	let {state, setState} = useSubscription(pageSub)

	const handleSave = () => {
		let error = []
		const required = ['title', 'item_id', 'width', 'height', 'length', 'weight']
		required.map(f => {
			if (!state[f]) error.push(f)
		})
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}

		setSaving(true)
		createProduct(state).then((res) => {
			if (res.data.success) {
				showToast({
					message: t('Thêm sản phẩm thành công')
				})
				setTimeout(() => {
					return navigate('/products/edit/' + res.data._id)
				}, 1000)
			}  else {
				return showToast({
					message: res.data.message,
					error: true
				})
			}
		}).catch(error => {
			console.log(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
		})
	}

	const handlers = {
		SAVE: e => {
			e.preventDefault()
			handleSave()
		}
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="products:create"
						yes={() => (
							<HotKeys keyMap={keyMaps} handlers={handlers}>
								<StickyActions
									actions={(
										<ButtonGroup>
											<Button onClick={() => navigate('/products')}>{t('Discard')}</Button>
											<Button
												primary
												onClick={handleSave}
												loading={saving}
											>
												{t('Save')}
											</Button>
										</ButtonGroup>
									)}
								/>
								<Page
									title={t('Add product')}
									breadcrumbs={[{content: t('Products'), onAction: () =>  navigate('/products')}]}
								>
									<Layout>
										<Layout.Section>
											<CardTitle error={mandatoryFields} />
											<CardSizes error={mandatoryFields} />
										</Layout.Section>
										<Layout.Section secondary>
											<CardStatus/>
											<CardOrganization/>
										</Layout.Section>
									</Layout>
									<PageActions
										primaryAction={{
											content: t('Save'),
											loading: saving,
											onAction() {
												handleSave()
											}
										}}
									/>
								</Page>
							</HotKeys>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default CreateProduct
