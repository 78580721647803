import React from 'react'
import {Router} from "@reach/router"
import ItemsList from "pages/items/list"
import ItemsListPending from "pages/items/listPending"
import ItemslistPrinted from "pages/items/listPrinted"
import ItemslistDone from "pages/items/listDone"
import EditItem from "pages/items/edit"

export default function ItemPages() {
  return (
	<Router>
	  <ItemsList path="/" />
	  <ItemsListPending path="/pending" />
	  <ItemslistPrinted path="/printed" />
	  <ItemslistDone path="/done" />
	  <EditItem path="/edit/:itemID" />
	</Router>
  )
}
