import {Card, FormLayout, TextField} from '@shopify/polaris'
import React, {useContext} from 'react'
import chroma from 'chroma-js'
import Select from 'react-select'
import {colorOptions} from "../container"
import {useSubscription} from "global-state-hook";
import {TagContext} from "../container";

const dot = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 10,
		width: 10,
	},
});

const colorStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color);
		return {
			...styles,
			backgroundColor: isDisabled
				? null
				: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: null,
			color: isDisabled
				? '#ccc'
				: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
			},
		};
	},
	input: styles => ({ ...styles, ...dot() }),
	placeholder: styles => ({ ...styles, ...dot() }),
	singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}


export default function CardColor() {
	const pageSub = useContext(TagContext)
	let {setState, state} = useSubscription(pageSub)
	const {color} = state
	const currentValue = colorOptions.find(c => c.value === color)
	return (
		<Card sectioned title={'Màu thẻ'}>
			<FormLayout>
				<Select
					value={currentValue ? {
						color: currentValue.color,
						value: color,
						label: currentValue.label
					} : ''}
					options={colorOptions}
					styles={colorStyles}
					placeholder={'Chọn màu thẻ'}
					isClearable={true}
					onChange={v => setState({color: v ? v.value : ''})}
				/>
			</FormLayout>
		</Card>
	)
}
