import React, {Fragment, useCallback, useEffect, useContext, useState} from 'react'
import BarcodeReader from 'react-barcode-reader'
import QuickProcess from "components/quickProcess"
import UpdateOrderStatus from "components/updateOrderStatus"
import FinishItem from "components/finishItem"
import {AuthConsumer} from "authentication/auth"
import {handleShip} from "pages/orders/container"
import {authContext} from "authentication/auth"
import {showToast} from "plugins/toast"
import {showConfirm} from "plugins/confirm"

export default function BarcodeListener() {
	const [active, setActive] = useState(false)
	const [code, setCode] = useState('')

	const handleToggle = useCallback(() => setActive((active) => !active), [])

	const userAuth = useContext(authContext)

	// console.log(userAuth.user)

	const handleScan = (code) => {
		setCode(code)

		if (code && userAuth && userAuth.user.role === 'warehouse_manager') {
			handleShip(code).then(response => {
				const {data} = response
				if (response && data.success) {

					if (data.message === 'order_is_shipping') {
						return showConfirm({
							title: 'Đơn hàng đang vận chuyển',
							message: 'Đơn hàng này đã được chuyển đi, vui lòng kiểm tra lại.',
							confirm: 'Xác nhận',
							cancel: 'Đóng',
						})
					} else {
						showToast({
							message: data.message
						})
					}
				} else {
					showToast({
						message: 'Không tìm thấy đơn hàng, vui lòng kiểm tra lại.',
						error: true
					})
				}
			})
			return false
		} else {
			handleToggle()
		}
	}

	const handleError = (err) => {
		console.log(err, 'err')
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				console.log(user, 'user')
				const {role} = user
				return authenticated ? (
					<Fragment>
						<BarcodeReader
							onError={handleError}
							onScan={handleScan}
							preventDefault={true}
						/>
						{active && role === 'printer' && <QuickProcess handleToggle={handleToggle} code={code} active={active} />}
						{/*{active && ['warehouse_manager'].includes(role) && <UpdateOrderStatus userRole={role} handleToggle={handleToggle} code={code} active={active} />}*/}
						{active && ['finisher', 'packer'].includes(role) && <FinishItem userRole={role} handleToggle={handleToggle} code={code} active={active} />}
					</Fragment>
				) : ''
			}}
		</AuthConsumer>
	)
}
