import React, {useContext} from 'react'
import {Card, FormLayout, TextField, Layout} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {GeneralContext} from "pages/settings/container"
import {useTranslation} from "react-i18next"

export default function CardInformation() {
	const pageSub = useContext(GeneralContext)
	let {state, setState} = useSubscription(pageSub)
	const {email, recruitment_email,  phone, phone2} = state
	const {t} = useTranslation()
	return (
		<Layout.AnnotatedSection
			title={t('Contact information')}
		>
			<Card sectioned>
				<FormLayout>
					<TextField
						label={t('Contact email')}
						value={email}
						type={'email'}
						onChange={v => setState({email: v})}
					/>
					<TextField
						label={t('Hotline')}
						value={phone}
						onChange={v => setState({phone: v})}
					/>
				</FormLayout>
			</Card>
		</Layout.AnnotatedSection>
	)
}
