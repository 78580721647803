import {createSubscription, useSubscription} from "global-state-hook"
import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"

export const initialState = {
	id: '',
	item_id: '',
	design: '',
	mockup: '',
	quantity: 1,
	quantity_printed: 0,
	status: 'pending',
	quantity_finished: 0,
	finished: false,
	item_sku: ''
}

export const ItemsContext = createContext({...initialState})

export const fetchItem = id => {
	return new Promise(resolve => {
		axios.get(`/items?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}
export const updateItem = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/items/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const fetchItemsList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 50,
		page: 1,
	}, params)
	return new Promise(resolve => {
		axios.get('/items', {
			params: newParams,
			cancelToken: cancelToken,
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const searchItems = (params, cancelToken) => {
	console.log('run search')
	return new Promise(resolve => {
		axios.get('/items', {
			params: {
				page: 1,
				limit: 50,
				...params
			},
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const itemsListSubscription = createSubscription({ready: false})
export const useItems = () => {
	let {state, setState} = useSubscription(itemsListSubscription)
	const fetchList = (params) => {
		const newParams = Object.assign({
			limit: 20,
			page: 1
		}, params)
		return new Promise(resolve => {
			axios.get('/items', {
				params: newParams
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_order, total, last_order} = response.data
					setState({items: data, current_order: current_order, total: total, last_order: last_order, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	const search = (params) => {
		console.log('run search')
		return new Promise(resolve => {
			axios.get('/items', {
				params: {
					page: 1,
					limit: 20,
					...params
				}
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_order, total, last_order} = response.data
					setState({items: data, current_order: current_order, total: total, last_order: last_order, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	return {state, setState, fetchList, search}
}

export const updateItemStatus = (data) => {
	const {item_sku, quantity, status} = data
	return new Promise((resolve, reject) => {
		axios.get(`/orders/updateItem?id=${item_sku}&quantity=${quantity}&status=${status}`)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}
