import React, {useState, useContext, useCallback} from 'react'
import {Card, TextField, FormLayout, TextStyle, Button, ButtonGroup} from '@shopify/polaris'
import {OrderContext, updateOrder} from "../container"
import {useTranslation} from "react-i18next"
import {useSubscription} from "global-state-hook"
import {showToast} from "plugins/toast"

export default function CNote() {
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const {cnote, _id, seller_order_id, order_status} = state
	const {t} = useTranslation()
	const [active, setActive] = useState(false)

	const handleToggle = useCallback(() => setActive((active) => !active), [])

	const handleSave = () => {
		updateOrder({_id: _id, order_status: order_status, seller_order_id: seller_order_id, cnote: cnote}).then((res) => {
			showToast({
				message: 'Cập nhật thành công'
			})
		})
			.catch((error) => console.log(error))
			.finally(() => {
				handleToggle()
			})
	}

	return (
		<Card title={t('Lưu ý')} actions={[
			{content: _id ? 'Sửa' : undefined, onAction: handleToggle}
		]}>
			{!_id ? (
					<Card.Section>
						<FormLayout>
							<TextField
								label={'Ghi chú cho đơn hàng'}
								multiline={3}
								value={cnote}
								onChange={v => setState({cnote: v})}
								placeholder={'Thêm ghi chú'}
							/>
							<TextField
								label={'Platform Order ID'}
								value={seller_order_id}
								onChange={v => setState({seller_order_id: v})}
							/>
						</FormLayout>
					</Card.Section>
				)
				: (
					<Card.Section>
						{!cnote && !active && <p>{t('Không có ghi chú')}</p>}
						{active && <FormLayout>
							<FormLayout>
								<TextField
									label={'Ghi chú cho đơn hàng'}
									multiline={3}
									value={cnote}
									onChange={v => setState({cnote: v})}
								/>
								<TextField
									label={'Platform Order ID'}
									value={seller_order_id}
									onChange={v => setState({seller_order_id: v})}
								/>
								<ButtonGroup>
									<Button plain onClick={handleToggle}>Đóng</Button>
									<Button onClick={handleSave}>Lưu</Button>
								</ButtonGroup>
							</FormLayout>
						</FormLayout>}
						{cnote && !active && <p>{cnote}</p>}
					</Card.Section>
				)}
			{!active && _id && <Card.Section title={'Platform Order ID'}>
				<TextStyle>{seller_order_id ? seller_order_id : 'Chưa cập nhật'}</TextStyle>
			</Card.Section>}
		</Card>
	)
}
