import React from 'react'
import {Card, Button, ButtonGroup, Badge, Link} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import Table from "components/table"
import ItemStatusBadge from "modules/itemStatus"
import {PrintMinor} from "@shopify/polaris-icons"
import {getImageUrl} from "helpers/utils"

export default function ViewSummary({order, quickView = false}) {
	const {t} = useTranslation()
	const {line_items, _id, title, shipping_label} = order

	return (
		<Card>
			<Card.Header title={t('Chi tiết đơn hàng')}>
				{quickView && <Link external url={`/orders/edit/${_id}`}>{title}</Link>}
				{!quickView && shipping_label && <Button outline size={'slim'} icon={PrintMinor} external url={getImageUrl(shipping_label)}>In Label</Button>}
				{!quickView && !shipping_label && <Badge>Chưa có Label</Badge>}
			</Card.Header>
			{line_items && line_items.length ? (
				<Card.Section subdued>
					<Table bordered>
						<thead>
						<tr>
							<th>Loại sản phẩm</th>
							{!quickView && <th>Đã in</th>}
							{!quickView && <th>Hoàn thiện</th>}
							{!quickView && <th>Trạng thái</th>}
							<th />
						</tr>
						</thead>
						<tbody>
						{line_items.map((item, index) => {
							const {mockup, design, quantity, quantity_printed, item_id, title, status, color, laser, quantity_finished} = item
							return (
								<tr key={index}>
									<td>
										<span style={{fontWeight: 500}}>{title}</span> ({item_id}) x{quantity}
										{color && <div style={{marginTop: 10}}>Màu: <strong>{color}</strong></div>}
									</td>
									{!quickView && <td width={100}>{quantity_printed ? quantity_printed.toString() : 0}/{quantity} cái</td>}
									{!quickView && <td>{quantity_finished ? quantity_finished.toString() : 0}/{quantity} cái</td>}
									{!quickView && <td><ItemStatusBadge status={status} />	</td>}
									<td width={200}>
										<ButtonGroup>
											<Button plain external url={design}>Thiết kế</Button>
											<Button plain external url={mockup}>Mockup</Button>
											{laser && <Button plain external url={laser}>Laser</Button>}
										</ButtonGroup>
									</td>
								</tr>
							)
						})}
						</tbody>
					</Table>
				</Card.Section>
			) : (
				<Card.Section>
					<p>Chưa có sản phẩm nào</p>
				</Card.Section>
			)}
		</Card>
	)
}
