import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import logo from 'core/images/logo.png'

const Wrapper = styled.div`
	.Polaris-Frame__Main--newDesignLanguage & {
		.Polaris-Frame-ContextualSaveBar {
			height: 6.8rem;
		}
		.Polaris-Frame-ContextualSaveBar__LogoContainer {
			flex: 0 0 25.8rem;
		}
	}
	
`

const StickyActions = ({ message, actions, fullWidth }) => {
	return <Wrapper>
		<div
			className="Polaris-Frame__ContextualSaveBar Polaris-Frame-CSSAnimation--startFade Polaris-Frame-CSSAnimation--endFade">
			<div>
				<div className="Polaris-Frame-ContextualSaveBar">
					<div className="Polaris-Frame-ContextualSaveBar__LogoContainer">
						<img src={logo} width={125} />
					</div>
					<div className="Polaris-Frame-ContextualSaveBar__Contents">
						<h2 className="Polaris-Frame-ContextualSaveBar__Message">{message}</h2>
						{actions && <div className="Polaris-Frame-ContextualSaveBar__ActionContainer">
							{actions}
						</div>}
					</div>
				</div>
			</div>
		</div>
	</Wrapper>
}

StickyActions.propTypes = {
	fullWidth: PropTypes.bool,
	title: PropTypes.string,
	actions: PropTypes.element
}

export default StickyActions
