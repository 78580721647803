import React from 'react'
import {Pagination, TextStyle} from '@shopify/polaris'
import styled from 'styled-components'
import {updateParam} from '../helpers/utils'
import {useTranslation} from "react-i18next"

const Wrapper = styled.div`
	display: flex;
	padding: 2rem 0;
	align-items: center;
	flex-flow: column;
	justify-content: center;
	.Polaris-Pagination {
		margin-bottom: 10px;
		> span:last-child {
			margin-left: -1px;
		}
	}
`
const CustomPagination = ({pageNumber, lastPage}) => {
	pageNumber = pageNumber ? parseInt(pageNumber) : 1
  	const {t} = useTranslation()
	return (
		lastPage > 1 ? <Wrapper>
			<Pagination
				hasPrevious={pageNumber > 1}
				previousKeys={[37]}
				previousTooltip={t('Previous')}
				onPrevious={() => updateParam('page', pageNumber - 1)}
				hasNext={pageNumber < lastPage}
				nextKeys={[39]}
				nextTooltip={t('Next')}
				onNext={() => updateParam('page', pageNumber + 1)}
			/>
			<TextStyle variation="subdued">{t('Page')} {pageNumber}/{lastPage}</TextStyle>
		</Wrapper> : null
	)
}
export default CustomPagination
