import React from 'react'
import {Badge} from "@shopify/polaris"
import styled from 'styled-components'
import {SHIPPING_STATUS} from "helpers/defines"

const Wrapper = styled.div`
	.Polaris-Badge {
		&--statusCritical {
			background: var(--p-text-critical,#bf0711);
			span {
				color: #fff;
			}
		}
	}
`

export default function ShippingStatusBadge({shipping_status}) {
	let orderStatus
	switch (shipping_status) {
		case 'incomplete':
		case 'pending':
			orderStatus = 'attention'
			break
		case 'notfound':
		case 'transit':
		case 'pickup':
			orderStatus = 'info'
			break
		case 'expired':
			orderStatus = ''
			break
		case 'delivered':
			orderStatus = 'success'
			break
		case 'undelivered':
			orderStatus = 'critical'
			break
		case 'exception':
			orderStatus = 'warning'
			break
		default:
			break
	}
	return (
		<Wrapper>
			<Badge status={orderStatus}>{SHIPPING_STATUS[shipping_status]}</Badge>
		</Wrapper>
	)
}
