import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import locale_en from 'locales/en.json'
import locale_vi from 'locales/vi.json'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en: {
				translations: locale_en
			},
			vi: {
				translations: locale_vi
			}
		},
		lng: "vi",
		fallbackLng: "en",
		debug: false,

		// have a common namespace used around the full app
		ns: ["translations"],
		defaultNS: "translations",

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false
		}
	})

export default i18n
