import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {fetchItemsList, searchItems} from "./container"
import {getParam, urlParams, getParams, updateParam} from "helpers/utils"
import axios from "axios"
import ItemsListView from "pages/items/listView"

const ItemsListPending = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const [currentStatus, setCurrentStatus] = useState('')
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = t('Sản xuất')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		if (urlParams.has('status')) {
			setCurrentStatus(getParam('status'))
		}
		let result, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.status = 'pending'
					result = await searchItems(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchItemsList({page: currentPage, status: 'pending'}, source.token)
				}

				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}

			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search, currentStatus])

	console.log(currentStatus, 'currentStatus')

	const handleSearch = () => {
		const params = getParams()
			params.status = 'pending'
		;(async () => {
			const result = await searchItems(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}

	return (
		<ItemsListView
			title={'Danh sách chờ in'}
			data={data}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			setAppliedFilters={setAppliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			highlight
		/>
	)
}
export default ItemsListPending
