import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Layout, Page} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchCustomer, initialState, CustomerContext, updateCustomer, deleteCustomers} from "./container"
import {showConfirm} from "plugins/confirm"
import CardSellerInformation from "./components/cardSellerInformation"
import Can from "authentication/can"

const ViewCustomer = (props) => {
	const pageSub = createSubscription({id: props.customerID, ...initialState})

	return <CustomerContext.Provider value={pageSub}>
		<ViewCustomerView {...props}/>
	</CustomerContext.Provider>

}

const ViewCustomerView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(CustomerContext)
	let {setState, state} = useSubscription(pageSub)
	useEffect(() => {
		document.title = t('Thông tin Seller')
		const fetchData = async () => {
			const result = await fetchCustomer(props.customerID)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.customerID])
	const {first_name, last_name} = state
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="customers:view"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<Page
											title={first_name + ' ' + last_name}
											breadcrumbs={[{content: t('Customers'), onAction: () => navigate('/sellers')}]}
										>
											<Layout>
												<Layout.Section>
													<CardSellerInformation />
												</Layout.Section>
											</Layout>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default ViewCustomer
