import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {
	Button,
	ButtonGroup,
	Layout,
	Page,
	TextStyle,
	Link, Banner
} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchItem, initialState, ItemsContext, updateItem} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import Summary from "./components/summary"
import moment from 'moment'
import {updateItemStatus} from "./container"
import ItemStatusBadge from "modules/itemStatus"

const EditItem = (props) => {
	const orderSub = createSubscription({id: props.itemID, ...initialState})

	return <ItemsContext.Provider value={orderSub}>
		<EditItemView {...props}/>
	</ItemsContext.Provider>
}

const EditItemView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const orderSub = useContext(ItemsContext)
	let {setState, state} = useSubscription(orderSub)

	useEffect(() => {
		document.title = t('Update item')
		const fetchData = async () => {
			const result = await fetchItem(props.itemID)
			console.log('Data details', result)
			if (result) {
				setState(result)
				setReady(true)
			} else {
				showToast({
					message: 'Item không tồn tại do đơn hàng đã bị xóa',
					error: true
				})
				navigate('/items')
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.itemID])

	const handleSave = (status) => {
		const {item_sku} = state
		showConfirm({
			title: t('Chuyển trạng thái sản xuất'),
			message: t('Bạn có chắc chắn với hành động này?'),
			confirm: t('Xác nhận'),
			cancel: t('Hủy')
		}).then(res => {
			if (res) {
				setSaving(true)
				updateItemStatus({item_sku: item_sku, quantity: 1, status: status}).then((res) => {
					console.log(res, 'order res')
					showToast({
						message: 'Item đã được cập nhật'
					})
					setState({status: status})
				}).catch((error) => {
					console.error(error)
					showToast({
						message: t('Something went wrong, please try again'),
						error: true
					})

				}).finally(() => {
					setSaving(false)
				})
			}
		})
	}

	const {title, created_at, status, order_status, order_id} = state
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="items:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										{!['canceled', 'shipped', 'incompleted'].includes(order_status) && <StickyActions
											actions={(
												<ButtonGroup>
													{status !== 'pending' && <Button outline onClick={() => handleSave('pending')} loading={saving}>{t('Chờ in')}</Button>}
													{status !== 'printed' && <Button onClick={() => handleSave('printed')} loading={saving}>{t('In xong')}</Button>}
													{status !== 'done' && <Button primary onClick={() => handleSave('done')} loading={saving}>{t('Hoàn thiện xong')}</Button>}
												</ButtonGroup>
											)}
										/>}
										<Page
											title={title}
											subtitle={<TextStyle>{moment(created_at).format('YYYY-MM-DD HH:mm')}</TextStyle>}
											breadcrumbs={[{content: t('Items'), onAction: () => window.history.back()}]}
											titleMetadata={<Fragment>
												<ItemStatusBadge status={status} />
											</Fragment>}
										>
											{['canceled'].includes(order_status) && (
												<div style={{marginBottom: '2rem'}}>
													<Banner title={'Đơn hàng này đã bị hủy'} status={'critical'}>
														<p>Đơn hàng của Item này đã bị hủy, vui lòng kiểm tra lại. <Link url={`/orders/view/${order_id}`}>Xem đơn hàng.</Link></p>
													</Banner>
												</div>
											)}
											<Layout>
												<Layout.Section>
													<Summary user={user} />
												</Layout.Section>
											</Layout>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditItem
