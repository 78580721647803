import React, {useCallback, useContext, useState} from 'react'
import {Card, FormLayout, TextContainer, TextStyle} from '@shopify/polaris'
import {OrderContext} from "../container"
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import {Link} from "@reach/router"
import PickSeller from "../components/pickSeller"

export default function Seller() {
	const [active, setActive] = useState(false)
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const {seller, seller_id, _id} = state
	const {t} = useTranslation()
	const fullname = [seller.first_name, seller.last_name].filter(v => v !== '')

	const handleToggle = useCallback(() => setActive((active) => !active), [])
	let actions = []

	if (!_id) {
		actions.push({content: t('Chọn seller'), onAction: handleToggle})
	}
	return (
		<Card title={t('Seller')} actions={actions}>
			<Card.Section>
				{seller.id ? (
					<TextContainer>
						<Link to={'/sellers/edit/' + seller.id}>{fullname.join(' ')} ({seller_id})</Link>
						<p>{t('Email')}: {seller.email ? seller.email : t('No email address')}</p>
						<p>{t('Phone')}: {seller.phone ? seller.phone : t('No phone number')}</p>
					</TextContainer>
				) : <p>Chưa chọn seller</p> }
			</Card.Section>
			{active && <PickSeller open={active} handleToggle={handleToggle} />}
		</Card>
	)
}
