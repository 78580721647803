import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {fetchTagsList} from 'pages/tags/container'
import chroma from 'chroma-js'
import {colorOptions} from 'pages/tags/container'
import {useTranslation} from "react-i18next"
const dot = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		marginLeft: 8,
		height: 10,
		width: 10,
	},
})
const colourStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color);
		return {
			...styles,
			backgroundColor: isDisabled
				? null
				: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: null,
			color: isDisabled
				? '#ccc'
				: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
			},
		};
	},
	multiValue: (styles, { data }) => {
		const color = chroma(data.color);
		return {
			...styles,
			backgroundColor: color.alpha(0.1).css(),
			...dot(data.color)
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.color,
			color: 'white',
		},
	}),
};
export default function TagsSelect({value, onChange}) {
	const {t} = useTranslation()
	const [data, setData] = useState({ready: false, items: []})

	useEffect(() => {
		const getInitialData = async () => {
			const result = await fetchTagsList({limit: 200})
			if (result && result.data) {
				setData({items: result.data, ready: true})
			}
		}
		getInitialData()
	}, [])

	const {items, ready} = data
	if (!value) value = []
	const currentValue = value.map(id => {
		const tag_found = items.find(v => v._id === id)
		return tag_found && {
			label: tag_found.title,
			value: id,
			color: colorOptions.find(v => v.value === tag_found.color).color
		}
	})

	return (
		<Select
			value={currentValue}
			onChange={v => onChange(v)}
			options={items.map(item => {
				const color = colorOptions.find(v => v.value === item.color)
				return {
					label: item.title,
					value: item.id,
					color: color.color
				}
			})}
			placeholder="Chọn thẻ"
			isSearchable
			isClearable
			isMulti
			styles={colourStyles}
		/>
	)
}
