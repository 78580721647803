import React, {useEffect, useState, useCallback, useContext} from 'react'
import {
	Page,
	Layout,
	Card,
	ChoiceList,
	ResourceItem,
	TextStyle,
	Badge,
	ButtonGroup,
	TextContainer,
	Button,
	Caption, DatePicker
} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {navigate, Redirect} from "@reach/router"
import {SkeletonList} from "modules/skeleton"
import {AuthConsumer, authContext} from "authentication/auth"
import DataList from "modules/dataList"
import Filters from "modules/filters"
import Pagination from "modules/pagination"
import {getTimeRage, updateParam} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import empty from 'images/order-empty.svg'
import axios from "axios"
import Can from "authentication/can"
import {fetchProductsList} from "pages/products/container"
import moment from "moment"
import ItemStatusBadge from "modules/itemStatus"

const d = new Date()
const current_month = d.getMonth()
const current_year = d.getFullYear()
const firstDay = moment().startOf('month').format('YYYY-MM-DD')
const now = moment().format('YYYY-MM-DD')

const ItemsListView = ({title, data, handleSearch, appliedFilters, setAppliedFilters, selectedItems, date = 'created_at', highlight = false}) => {
	const {t} = useTranslation()
	const [productsList, setProductsList] = useState([])
	const [customDate, setCustomDate] = useState({
		month: current_month,
		year: current_year,
		start: firstDay,
		end: now
	})

	const source = axios.CancelToken.source()
	useEffect(() => {
		let products
		const fetchData = async () => {
			try {
				products = await fetchProductsList({limit: 100}, source.token)

				if (products && products.data) {
					setProductsList(products.data)
				}

			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])
	const {ready, last_page, current_page, isSearched, to, total} = data

	const handleFiltersChange = useCallback(
		(key, value) => {
			// Reset current order
			updateParam('order', '', null)
			// Update new filter
			appliedFilters[key] = value
			setAppliedFilters({...appliedFilters})
			if (key === 'processed_at') {
				updateParam('start_at', getTimeRage(value).start, null)
				updateParam('end_at', getTimeRage(value).end, null)
			} else {
				updateParam(key, value + '', null)
			}

			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFilterDateChange = useCallback(
		(start, end) => {
			updateParam('start_at', start, null)
			updateParam('end_at', end, null)
			// Run search with new filters
			handleSearch()
		},
		[]
	)

	const handleFiltersRemove = useCallback(
		(key) => {
			// Reset current order
			updateParam('order', '', null)
			delete appliedFilters[key]
			setAppliedFilters({...appliedFilters})
			updateParam(key, '', null)

			if (key === 'processed_at') {
				updateParam('start_at', '', null)
				updateParam('end_at', '', null)
			}
			// Run search with new filters
			handleSearch()
		},
		[]
	)
	const handleMonthChange = (month, year) => {
		setCustomDate({...customDate, month: month, year: year})
	}

	const handleFiltersClearAll = () => {
		for (const obj of Object.keys(appliedFilters)) {
			delete appliedFilters[obj]
		}
		setAppliedFilters({...appliedFilters})
	}

	const {item_id, processed_at} = appliedFilters

	const filters = [
		{
			key: 'date',
			label: t('Thời gian'),
			filter: (
				<>
					<ChoiceList
						title={t('Thời gian')}
						titleHidden
						choices={[
							{
								label: 'Hôm nay',
								value: 'today'
							},
							{
								label: 'Ngày hôm qua',
								value: 'yesterday'
							},
							{
								label: 'Tuần này',
								value: 'current_week'
							},
							{
								label: 'Tuần trước',
								value: 'last_week'
							},
							{
								label: 'Tháng này',
								value: 'current_month'
							},
							{
								label: 'Tháng trước',
								value: 'last_month'
							},
							{
								label: 'Thời gian tùy chỉnh',
								value: 'custom'
							}
						]}
						selected={[processed_at]}
						onChange={v => {
							setCustomDate({
								...customDate,
								start: getTimeRage(v[0]).start ? getTimeRage(v[0]).start : firstDay,
								end: getTimeRage(v[0]).end ? getTimeRage(v[0]).end : now
							})
							handleFiltersChange('processed_at', v[0])
						}}
						// allowMultiple
					/>
					{processed_at === 'custom' && <div style={{paddingTop: 10}}>
						<DatePicker
							month={customDate.month}
							year={customDate.year}
							multiMonth
							allowRange
							onChange={v => {
								const start =  moment(v.start).format('YYYY-MM-DD')
								const end =  moment(v.end).format('YYYY-MM-DD')
								setCustomDate({
									...customDate,
									start: start,
									end: end
								})
								handleFilterDateChange(start, end)
							}}
							onMonthChange={handleMonthChange}
							disableDatesAfter={new Date()}
							selected={{
								start: new Date(customDate.start),
								end: new Date(customDate.end)
							}}
						/>
					</div>}
				</>
			),
			shortcut: true
		},
		{
			key: 'item_id',
			label: t('Phân loại'),
			filter: (
				<ChoiceList
					title={t('Phân loại')}
					titleHidden
					choices={productsList.map(p => {
						return {
							label: p.title,
							value: p.item_id
						}
					})}
					selected={[item_id]}
					onChange={v => handleFiltersChange('item_id', v[0])}
					// allowMultiple
				/>
			),
			shortcut: true
		}
	]

	const headers = [
		{
			id: 'item',
			title: t('Loại'),
			width: 30
		},
		{
			id: 'item_sku',
			title: t('Item SKU'),
			width: 20
		},
		{
			id: 'printed',
			title: t('Đã in'),
			width: 10
		},
		{
			id: 'status',
			title: t('Hoàn thiện'),
			width: 10
		},
		{
			id: 'actions',
			title: '',
			width: 30
		}
	]

	const renderItem = (item) => {
		const {_id, created_at, title, quantity, quantity_printed, quantity_finished, status, item_sku, mockup, design, item_id, order_id, laser} = item
		let className = ['item-wrapper']
		const now = moment()
		const diff = now.diff(new Date(item[date]), 'hours')
		if (highlight && diff >= 24) className.push('overdue')
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/items/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className={className.join(' ')}>
					<div className="column title">
						<TextContainer>
							<TextStyle variation={status === 'done' ? 'subdued' : undefined}><strong>{title}</strong> - ({item_id.toUpperCase()}) </TextStyle>
							<ItemStatusBadge status={status} />
							<Caption>
								{moment(item[date]).format('DD/MM/YYYY HH:mm')}
							</Caption>
						</TextContainer>
					</div>
					<div className="column" style={{width: '20%'}}>
						<TextStyle>{item_sku}</TextStyle>
					</div>
					<div className="column" style={{width: '10%'}}>
						<TextStyle>{quantity_printed && quantity_printed.toString()}/{quantity} cái</TextStyle>
					</div>
					<div className="column" style={{width: '10%'}}>
						<TextStyle>{quantity_finished && quantity_finished.toString()}/{quantity} cái</TextStyle>
					</div>
					<div className="column" style={{width: '30%'}} onClick={(e) => e.stopPropagation()}>
						<ButtonGroup>
							<Button plain url={design} external>Design</Button>
							<Button plain url={mockup} external>Mockup</Button>
							<Button plain url={laser} external>Laser</Button>
							<Button plain onClick={() => navigate(`/orders/view/${order_id}`)}>Đơn hàng</Button>
						</ButtonGroup>
					</div>
				</div>
			</ResourceItem>
		)
	}

	let items = data.data || []
	items = items && items.length && items.map(item => {
		item.id = item._id
		return item
	})

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				if (authenticated) {
					return (
						<Can
							role={user.role}
							perform="items:list"
							yes={() => {
								return ready ? (
									<Page
										title={title ? title : `Danh sách sản xuất`}
										fullWidth
									>
										<Layout>
											<Layout.Section>
												{items && items.length > 0 || isSearched ? (
													<Card sectioned>
														<Filters
															filters={filters}
															handleSearch={handleSearch}
															appliedFilters={appliedFilters}
															handleFiltersChange={handleFiltersChange}
															handleFiltersRemove={handleFiltersRemove}
															handleFiltersClearAll={handleFiltersClearAll}
														/>
														<div className="results-count">
															{t('showing', {to: to, total: total, resourceName: 'items'})}
														</div>
														<DataList
															resourceName={{singular: t('item'), plural: t('item')}}
															items={items}
															selectedItems={selectedItems}
															// onSelectionChange={setSelectedItems}
															// promotedBulkActions={promotedBulkActions}
															headers={headers}
															orderType={'items'}
															renderItem={renderItem}
														/>
														<Pagination
															pageNumber={current_page}
															lastPage={last_page}
															path={'items'}
														/>
													</Card>
												) : <EmptyPage
													image={empty}
													description={'Không có item nào cần in'}
												/>}
											</Layout.Section>
										</Layout>
									</Page>
								) : <SkeletonList fullWidth/>
							}}
						/>
					)
				} else {
					return <Redirect to={'/login'} noThrow/>
				}
			}}
		</AuthConsumer>
	)
}
export default ItemsListView
