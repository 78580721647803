import React from 'react'
import styled from 'styled-components'
import {colorOptions} from 'pages/tags/container'

const List = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin-top: 5px;
	.Polaris-Tag__TagText {
		color: #fff;
	    font-size: 10px;
	    text-transform: uppercase;
	    font-weight: 500;
	}
	.Polaris-Tag {
	    padding-right: .8rem;
	    min-height: 10px;
	}
	.Polaris-Tag + .Polaris-Tag {
		margin-left: 5px;
	}
`

const TagsList = ({tags}) => {
	return tags && Array.isArray(tags) && tags.length > 0 ? (
		<List>
			{tags.map((tag, index) => {
				const color = colorOptions.find(c => c.value === tag.color)
				return <span key={index} className={'Polaris-Tag tag'} style={{backgroundColor: color.color}}><span
					className="Polaris-Tag__TagText">{tag.title}</span></span>
			})}
		</List>
	) : ''
}
export default TagsList
