import React from 'react'
import {Router} from "@reach/router"
import OrdersList from "pages/orders/list"
import OrdersListShipping from "pages/orders/listShipping"
import OrdersListStoring from "pages/orders/listStoring"
import OrdersListFinishing from "pages/orders/listFinishing"
import OrdersListPrinting from "pages/orders/listPrinting"
import OrderslistShipped from "pages/orders/listShipped"
import OrderslistSOpen from "pages/orders/listOpen"
import OrdersListUndelivered from "pages/orders/listUndelivered"
import OrdersListCanceled from "pages/orders/listCanceled"
import CreateOrder from "pages/orders/create"
import EditOrder from "pages/orders/edit"
import ViewOrder from "pages/orders/view"

export default function OrderOrders() {
  return (
	<Router>
	  <OrdersList path="/" />
	  <OrderslistSOpen path="/pending" />
	  <OrdersListShipping path="/shipping" />
	  <OrdersListPrinting path="/printing" />
	  <OrdersListFinishing path="/packing" />
	  <OrdersListStoring path="/storing" />
	  <OrderslistShipped path="/shipped" />
	  <OrdersListCanceled path="/canceled" />
	  <OrdersListUndelivered path="/undelivered" />
	  <CreateOrder path="/new" />
	  <EditOrder path="/edit/:orderID" />
	  <ViewOrder path="/view/:orderID" />
	</Router>
  )
}
