import React from 'react'
import {Router} from "@reach/router"
import GeneralSettings from "../pages/general"

export default function SettingsPage() {
  return (
	<Router>
	  <GeneralSettings path="/" />
	</Router>
  )
}
