import React, {useContext} from 'react'
import {Card, FormLayout, Layout, ChoiceList} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {UserContext} from "../container"
import {useTranslation} from "react-i18next"

export default function CardRole({error}) {
	const pageSub = useContext(UserContext)
	let {state, setState} = useSubscription(pageSub)
	const {role} = state
	const {t} = useTranslation()
	return (
		<Layout.AnnotatedSection
			title={t('User permissions')}
		>
			<Card sectioned>
				<FormLayout>
					<ChoiceList
						title={t('User role')}
						choices={[
							{label: t('Quản trị viên'), value: 'admin'},
							// {label: t('Quản lý'), value: 'general_manager'},
							{label: t('Quản lý đơn hàng'), value: 'orders_manager'},
							{label: t('Sản xuất'), value: 'printer'},
							{label: t('Hoàn thiện & đóng gói'), value: 'finisher'},
							{label: t('Quản lý kho'), value: 'warehouse_manager'},
							{label: t('Kế toán'), value: 'accountant'},
						]}
						selected={[role]}
						onChange={v => setState({role: v[0]})}
						error={error.includes('role') ? t('Please select a role') : undefined}
					/>
				</FormLayout>
			</Card>
		</Layout.AnnotatedSection>
	)
}
