import {ActionList, Card, TopBar} from "@shopify/polaris"
import React, {useCallback, useState} from "react"
import {useTranslation} from "react-i18next"
import styled from 'styled-components'
import {LogOutMinor, ProfileMajorMonotone} from "@shopify/polaris-icons"
import {AuthConsumer} from "authentication/auth"
import {ROLES_MAP} from "helpers/defines"

const TopBarWrapper = styled.div`
	.Polaris-TopBar-UserMenu__Details {
		p {
			margin: 0;
		}
	}
`

const UserMenu = ({handleLogout, user}) => {
	const {t} = useTranslation()
	const [userMenuActive, setUserMenuActive] = useState(false)

	const toggleUserMenuActive = useCallback(
		() => setUserMenuActive((userMenuActive) => !userMenuActive),
		[],
	)

	const userMenuActions = [
		{
			items: [
				{
					content: t('Thoát'),
					icon: LogOutMinor,
					destructive: true,
					onAction: handleLogout
				},
			]
		}
	]
	const fullname = user.first_name && [user.first_name, user.last_name]
	let subTitle = user.first_name ? ROLES_MAP[user.role] : ''
	if (user.seller_id) subTitle = 'Mã Seller: ' + user.seller_id
	return (
		<TopBar.UserMenu
			actions={userMenuActions}
			name={user.first_name && fullname.join(' ')}
			detail={subTitle}
			initials={user.first_name && user.first_name.charAt(0)}
			open={userMenuActive}
			onToggle={toggleUserMenuActive}
		/>
	)
}

export default function AppTopBar({onNavigationToggle, userMenu}) {
	const {t} = useTranslation()
	const [searchString, setSearchString] = useState('')
	const [searchActive, setSearchActive] = useState(false)

	const handleSearchFieldChange = useCallback((value) => {
		setSearchString(value)
		setSearchActive(value.length > 0)
	}, [])

	const handleSearchResultsDismiss = useCallback(() => {
		setSearchActive(false)
		setSearchString('')
	}, [])

	const searchResultsMarkup = (
		<Card>
			<ActionList
				items={[
					{content: 'Help center'},
					{content: 'Community forums'},
				]}
			/>
		</Card>
	)
	const searchField = (
		<TopBar.SearchField
			onChange={handleSearchFieldChange}
			value={searchString}
			placeholder={t('Search')}
		/>
	)

	return (
		<AuthConsumer>
			{({logout, authenticated, user}) => authenticated ? (
				<TopBarWrapper>
					<TopBar
						showNavigationToggle={true}
						searchResultsVisible={searchActive}
						// searchField={searchField}
						searchResults={searchResultsMarkup}
						onSearchResultsDismiss={handleSearchResultsDismiss}
						onNavigationToggle={onNavigationToggle}
						userMenu={<UserMenu handleLogout={logout} user={user} />}
					/>
				</TopBarWrapper>
			) : null}
		</AuthConsumer>
	)
}
