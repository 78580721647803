export const ROLES_MAP = {
	admin: 'Quản trị viên',
	general_manager: 'Quản lý',
	orders_manager: 'Quản lý đơn hàng',
	printer: 'Sản xuất',
	finisher: 'Hoàn thiện & đóng gói',
	packer: 'Quản lý đóng gói',
	warehouse_manager: 'Quản lý kho',
	seller: 'Nhà bán hàng',
	accountant: 'Kế toán',
}

export const PAYMENT_STATUS = {
	incomplete: 'Pending',
	completed: 'Paid',
	refunded: 'Refunded'
}

export const SHIPPING_STATUS = {
	incomplete: 'Pending',
	pending: 'Pending',
	notfound: 'Not Found',
	transit: 'Transit',
	pickup: 'Pickup',
	expired: 'Expired',
	delivered: 'Delivered',
	undelivered: 'Undelivered',
	exception: 'Exception'
}

export const ORDER_STATUS = {
	open: 'Đang chờ',
	confirmed: 'Đã xác nhận',
	shipped: 'Đã giao hàng',
	canceled: 'Đã hủy',
	closed: 'Đã lưu trữ',
	shipping: 'Đang vận chuyển',
	packing: 'Đang đóng gói',
	finishing: 'Đang hoàn thiện',
	printing: 'Đang in',
	processing: 'Đang sản xuất',
	storing: 'Đang lưu kho',
	incompleted: 'Không giao được hàng'
}

export const PAYMENT_METHOD = {
	COD: 'Cash on delivery (COD)',
	ATM: 'ATM card',
	credit_card: 'Credit card',
	BANK_TRANSFER: 'Bank transfer',
	CASH: 'Cash',
	paypal: 'PayPal',
}

export const ITEM_STATUS = {
	pending: 'Chờ in',
	printed: "In xong",
	done: "Đã hoàn thiện",
}
