import React from 'react'
import styled from 'styled-components'
import {Router} from '@reach/router'
import Dashboard from "pages/dashboard"
import OrderPages from "./sellerOrders"
import NotFound from "pages/404"
import LoginPage from "authentication/sellerLogin"
import EditProfile from "pages/customers/profile"
const Main = styled.div`
	.Polaris-Layout {
		& + .Polaris-PageActions {
			margin-top: 2rem;
		}
	}
	.Polaris-ResourceItem__Container {
    	align-items: center;
    }
    .text-right {
    	text-align: right;
    }
`

const AppRoutes = () => (
	<Main>
		<Router>
			<LoginPage path="/login" />
			<NotFound default />
			<Dashboard path="/"/>
			<OrderPages path="/orders/*"/>
			<EditProfile path="/manage/profile/*"/>
		</Router>
	</Main>
)
export default AppRoutes
