import React from 'react'
import {FormLayout} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import AdvanceSelect from "./advanceSelect"
import {countries} from "../stores/countries"

export default function CountrySelect({value, onChange}) {
	const {t} = useTranslation()

	return (
		<FormLayout>
			<AdvanceSelect
				allowClear
				label={t('Country')}
				value={value || undefined}
				onChange={onChange}
				showSearch
				filterOption={(input, option) =>
					option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				options={countries.map(item => {
					return {
						label: item.name,
						value: item.code
					}
				})}
			/>
		</FormLayout>
	)
}
