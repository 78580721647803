import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardTitle from "./components/cardTitle"
import CardVisibility from "./components/cardVisibility"
import CardColor from "./components/cardColor"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {createTag, initialState, TagContext} from "./container"
import {showToast} from "plugins/toast"
import {createSubscription, useSubscription} from "global-state-hook"
import Can from "authentication/can"

const CreateTag = () => {

	const pageSub = createSubscription(initialState)
	const {t} = useTranslation()

	useEffect(() => {
		document.title = t('Thêm thẻ ghi chú')
	})

	return <TagContext.Provider value={pageSub}>
		<CreateTagView/>
	</TagContext.Provider>
}


const CreateTagView = () => {
	const {t} = useTranslation()
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(TagContext)
	let {state} = useSubscription(pageSub)

	const handleSave = () => {

		const {title} = state
		let error = []
		if (!title) {
			error.push('title')
		}
		setMandatoryFields(error)
		if (error.length > 0) {
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}

		setSaving(true)
		createTag(state).then((res) => {
			showToast({
				message: t('Tạo thẻ tag thành công')
			})
			setTimeout(() => {
				return navigate('/manage/tags/edit/' + res.data._id)
			}, 1000)
		}).catch(error => {
			console.log(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})
		}).finally(() => {
			setSaving(false)
		})
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="tags:create"
						yes={() => (
							<Fragment>
								<StickyActions
									actions={(
										<ButtonGroup>
											<Button onClick={() => navigate('/manage/tags')}>{t('Discard')}</Button>
											<Button
												primary
												onClick={handleSave}
												loading={saving}
											>
												{t('Save')}
											</Button>
										</ButtonGroup>
									)}
								/>
								<Page
									title={t('Add type')}
									breadcrumbs={[{content: t('Types'), onAction: () => navigate('/tags')}]}
								>
									<Layout>
										<Layout.Section>
											<CardTitle error={mandatoryFields} />
										</Layout.Section>
										<Layout.Section secondary>
											<CardColor />
										</Layout.Section>
									</Layout>
									<PageActions
										primaryAction={{
											content: t('Save'),
											loading: saving,
											onAction() {
												handleSave()
											}
										}}
										secondaryActions={[
											{
												content: t('Discard'),
												onAction: () => navigate('/tags')

											}
										]}
									/>
								</Page>
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default CreateTag
