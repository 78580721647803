import {Card, TextContainer, Caption, TextStyle} from '@shopify/polaris'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const TimelineList = styled.ul`
	margin: 0;
	padding: 0;
	li {
		position: relative;
		padding: 15px 20px 15px 40px;
		list-style: none;
		&:before {
			content: '';
			width: 15px;
			height: 15px;
			top: 20px;
			left: 0;
			position: absolute;
			border-radius: 50%;
			border: 3px solid #ebeef0;
            background-color: #c3cfd8;
			z-index: 1;
		}
		&:after {
			content: '';
			position: absolute;
			width: 3px;
			height: 100%;
			top: 0;
			left: 6px;
			background-color: #e3e6e9;
		}
		&:first-child {
			&:after {
				top: 20px;
			}
		}
		&:last-child {
			
		}
	}
	.Polaris-Button--sizeSlim {
		margin-top: 0;
	}

`

export default function TimeLine({notes}) {

	return (
		<Card.Section subdued>
			<TimelineList>
				{notes && notes.length > 0 && notes.map((item, index) => {
					return (
						<li key={index}>
							<TextContainer>
								<strong>{item.created_by}: </strong>
								<TextStyle variation={item.type && item.type === 'error' && 'negative'}>
									{item.message}
								</TextStyle>
								<Caption>lúc {moment(item.created_at).format('HH:mm DD-MM-YYYY')}</Caption>
							</TextContainer>
						</li>
					)
				})}
			</TimelineList>
		</Card.Section>
	)
}
