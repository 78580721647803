import React, {useEffect, useState, useCallback} from 'react'
import {Page, Layout, Card, ChoiceList, ResourceItem, TextStyle, Badge, Caption} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {navigate, Redirect} from "@reach/router"
import {searchTags, fetchTagsList, deleteTags} from "./container"
import {SkeletonList} from "modules/skeleton"
import {AuthConsumer} from "authentication/auth"
import DataList from "modules/dataList"
import Filters from "modules/filters"
import Pagination from "modules/pagination"
import {getParam, urlParams, getParams, updateParam} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import {showConfirm} from "plugins/confirm"
import empty from 'images/collection-empty.svg'
import axios from "axios"
import Can from "authentication/can"
import TagsList from "components/tagsList"

const TagsListPage = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = t('Thẻ ghi chú')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					result = await searchTags(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchTagsList({page: currentPage}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error;
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])

	const handleSearch = () => {
		const params = getParams()
		;(async () => {
			const result = await searchTags(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}

	const {ready, last_page, current_page, isSearched} = data
	let items = data.data || []
	items = items && items.length && items.map(item => {
		item.id = item._id
		return item
	})

	const handleFiltersChange = useCallback(
		(key, value) => {
			// Reset current page
			updateParam('page', '', null)
			// Update new filter
			appliedFilters[key] = value
			setAppliedFilters({...appliedFilters})
			for (const [key, value] of Object.entries(appliedFilters)) {
				updateParam(key, value + '', null)
			}
			// Run search with new filters
			handleSearch()
		},
		[],
	)

	const handleFiltersRemove = useCallback(
		(key) => {
			// Reset current page
			updateParam('page', '', null)
			delete appliedFilters[key]
			setAppliedFilters({...appliedFilters})
			updateParam(key, '', null)
			// Run search with new filters
			handleSearch()
		},
		[],
	)
	const handleFiltersClearAll = () => {
		for (const obj of Object.keys(appliedFilters)) {
			delete appliedFilters[obj]
		}
		setAppliedFilters({...appliedFilters})
	}

	const {visibility, status} = appliedFilters

	const filters = [
		{
			key: 'visibility',
			label: t('Visibility'),
			filter: (
				<ChoiceList
					title="Visibility"
					titleHidden
					choices={[
						{label: 'Visible', value: true},
						{label: 'Hidden', value: false}
					]}
					selected={[visibility]}
					onChange={v => handleFiltersChange('visibility', v[0])}
					// allowMultiple
				/>
			),
			shortcut: true,
		},
	]

	const promotedBulkActions = [
		{
			content: t('deleteWithCount', {count: selectedItems.length, resourceName: 'tag'}),
			onAction: () => {
				showConfirm({
					title: t('deleteResourceConfirm', {count: selectedItems.length, resourceName: 'tag'}),
					message: t('Are you sure you want to delete these tags? This can\'t be undone.'),
					confirm: t('Delete'),
					cancel: t('Cancel'),
					danger: true
				}).then(res => {
					if (res) {
						deleteTags(selectedItems)
					}
				})
				return false
			}
		},
	]

	const headers = [
		{
			id: 'description',
			title: t('Mô tả'),
			width: 55
		}
	]

	const renderItem = (item) => {
		const {_id, description, color, title} = item
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/managae/tags/edit/${_id}`)}
				accessibilityLabel={`View details for ${title}`}
				name={title}
			>
				<div className="item-wrapper">
					<div className="column title">
						<TagsList tags={[item]} />
					</div>
					<div className="column" style={{width: '55%'}}>
						<TextStyle>{description}</TextStyle>
					</div>
				</div>
			</ResourceItem>
		)
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				if (authenticated) {
					return (
						<Can
							role={user.role}
							perform="tags:list"
							yes={() => {
								return ready ? (
									<Page
										title={t('Thẻ ghi chú')}
										primaryAction={{content: t('Thêm tag'), onAction: () => navigate('/manage/tags/new')}}
									>
										<Layout>
											<Layout.Section>
												{items.length > 0 || isSearched ? (
													<Card sectioned>
														<Filters
															filters={[]}
															handleSearch={handleSearch}
															appliedFilters={appliedFilters}
															handleFiltersChange={handleFiltersChange}
															handleFiltersRemove={handleFiltersRemove}
															handleFiltersClearAll={handleFiltersClearAll}
														/>
														<DataList
															resourceName={{singular: t('thẻ'), plural: t('thẻ')}}
															items={items}
															selectedItems={selectedItems}
															onSelectionChange={setSelectedItems}
															promotedBulkActions={promotedBulkActions}
															headers={headers}
															renderItem={renderItem}
															pageType={'manage/tags'}
														/>
														<Pagination
															pageNumber={current_page}
															lastPage={last_page}
															path={'tags'}
														/>
													</Card>
												) : <EmptyPage
													image={empty}
													primaryAction={{
														content: t('Thêm tag'),
														onAction: () => navigate('/manage/tags/new')
													}}
												/>}
											</Layout.Section>
										</Layout>
									</Page>
								) : <SkeletonList fullWidth/>
							}}
						/>
					)
				}
				return <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}
export default TagsListPage
