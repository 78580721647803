import React from 'react'
import {Router} from "@reach/router"
import CollectionsList from "pages/collections/list"
import CreateCollection from "pages/collections/create"
import EditCollection from "pages/collections/edit"

export default function TypePages() {
  return (
	<Router>
	  <CollectionsList path="/" />
	  <CreateCollection path="/new" />
	  <EditCollection path="/edit/:typeID" />
	</Router>
  )
}
