import React, {useContext, useEffect, useState} from 'react'
import {Card, FormLayout, Select, Modal} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {OrderContext, updateOrder} from "../container"
import {showToast} from "plugins/toast"
import {useSubscription} from "global-state-hook"
import OrderStatusBadge from "modules/orderStatus"
import axios from "axios"

const UpdateStatus = ({open, handleToggle}) => {
	const [loading, setLoading] = useState(false)
	const [status, setStatus] = useState('')
	const orderSub = useContext(OrderContext)
	const {setState, state} = useSubscription(orderSub)

	const {t} = useTranslation()
	const {order_status, _id} = state

	useEffect(() => {
		setStatus(order_status)
	}, [order_status])

	const handleSave = () => {
		setLoading(true)
		axios.post(`/orders/forceUpdate/${_id}`, {
			order_status: status
		})
			.then(response => {
				console.log(response, 'response')
				showToast({
					message: 'Cập nhật thành công'
				})
				setState({order_status: status})
			})
			.catch(err => {
				showToast({
					message: t('Something went wrong, please try again'),
					error: true
				})
			}).finally(() => {
			setLoading(false)
			handleToggle()
		})
	}

	return (
		<Modal
			title={t('Chuyển trạng thái đơn hàng')}
			open={open}
			onClose={handleToggle}
			primaryAction={{
				content: t('Update'),
				onAction: handleSave,
				loading: loading

			}}
			secondaryActions={[
				{
					content: t('Cancel'),
					onAction: handleToggle
				}
			]}
		>
			<Card>
				<Card.Section>
					<FormLayout>
						<div style={{display: 'flex', alignItems: 'center'}}><span style={{paddingRight: 10}}>Trạng thái hiện tại:</span> <OrderStatusBadge order_status={order_status} /></div>
						<Select
							label="Chọn trạng thái mới"
							options={[
								{label: 'Đang chờ', value: 'open', disabled: order_status === 'open'},
								{label: 'Đang in', value: 'printing', disabled: order_status === 'printing'},
								{label: 'Đang hoàn thiện', value: 'finishing', disabled: order_status === 'finishing'},
								{label: 'Đang lưu kho', value: 'storing', disabled: order_status === 'storing'},
								{label: 'Đang vận chuyển', value: 'shipping', disabled: order_status === 'shipping'},
								{label: 'Ship thành công', value: 'shipped', disabled: order_status === 'shipped'},
								{label: 'Ship không thành công', value: 'incompleted', disabled: order_status === 'incompleted'},
								{label: 'Hủy', value: 'canceled', disabled: order_status === 'canceled'}
							]}
							onChange={v => setStatus(v)}
							value={status}
						/>
					</FormLayout>
				</Card.Section>
			</Card>
		</Modal>
	)
}
export default UpdateStatus
