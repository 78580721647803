import React from 'react'
import ReactDOM from 'react-dom'
// import '@shopify/polaris/styles.css'
import App from './App'
import SellerApp from './Seller'
import "./i18n"
import * as serviceWorker from './serviceWorker'

console.log(process.env.REACT_APP_MODE, 'process.env.PROJECT')
ReactDOM.render(
  <React.StrictMode>
		{process.env.REACT_APP_MODE === 'seller' ? <SellerApp/> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
