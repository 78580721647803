import React, {useContext, useEffect, useState} from 'react'
import {Card, FormLayout, TextField, Modal} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import CountrySelect from "components/countrySelect"
import StateSelect from "components/usStateSelect"
import {OrderContext, updateOrder} from "../container"
import {showToast} from "plugins/toast"
import {useSubscription} from "global-state-hook"
import {checkProvince, provinceName} from "helpers/utils"

const EditAddress = ({open, handleToggle, initialState}) => {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		address: '',
		address_2: '',
		city: '',
		province: '',
		country_code: '',
		zip_code: ''
	})
	const orderSub = useContext(OrderContext)
	const {setState, state} = useSubscription(orderSub)

	useEffect(() => {
		setData({...initialState})
	}, [initialState])

	const {t} = useTranslation()

	const {first_name, last_name, country_code, phone, city, province, address, address_2, zip_code} = data
	const {_id} = state

	const handleSave = () => {
		setLoading(true)
		const newData = {
			_id: _id,
			shipping_address: {...data}
		}
		if (_id) {
			updateOrder(newData).then(() => {
				showToast({
					message: "Địa chỉ đã được cập nhật"
				})
				setState({shipping_address: {...data}})
			}).catch((error) => {
				console.error(error)
				showToast({
					message: t('Something went wrong, please try again'),
					error: true
				})

			}).finally(() => {
				setLoading(false)
				handleToggle()
			})
		} else {
			setLoading(false)
			setState({shipping_address: {...data}})
			handleToggle()
		}
	}

	return (
		<Modal
			title={t('Edit shipping address')}
			open={open}
			onClose={handleToggle}
			primaryAction={{
				content: t('Update'),
				onAction: handleSave,
				loading: loading

			}}
			secondaryActions={[
				{
					content: t('Cancel'),
					onAction: handleToggle
				}
			]}
		>
			<Card>
				<Card.Section>
					<FormLayout>
						<FormLayout.Group>
							<TextField
								label={'First name'}
								value={first_name}
								onChange={v => {
									setData({...data, first_name: v})
								}}
							/>
							<TextField
								label={'Last name'}
								value={last_name}
								onChange={v => {
									setData({...data, last_name: v})
								}}
							/>
						</FormLayout.Group>
						<FormLayout.Group>
							<TextField
								label={'Phone number'}
								value={phone.toString()}
								onChange={v => {
									setData({...data, phone: v})
								}}
							/>
						</FormLayout.Group>
					</FormLayout>
				</Card.Section>
				<Card.Section>
					<FormLayout>
						<TextField
							label={'Address 1'}
							mutiline={2}
							value={address}
							onChange={v => {
								setData({...data, address: v})
							}}
						/>
						<TextField
							label={'Address 2'}
							mutiline={2}
							value={address_2}
							onChange={v => {
								setData({...data, address_2: v})
							}}
						/>
						<TextField
							label={'City'}
							value={city}
							onChange={v => {
								setData({...data, city: v})
							}}
						/>
						<FormLayout.Group>
							<CountrySelect
								value={country_code}
								onChange={v => {
									setData({...data, country_code: v, city: '', province: '', zip_code: ''})
								}}
							/>
							{checkProvince(country_code) && (
								<TextField
									label={t(provinceName(country_code))}
									value={province}
									onChange={v => {
										setData({...data, province: v})
									}}
								/>
							)}
							{country_code === 'US' && (
								<StateSelect
									value={data.state}
									onChange={v => {
										setData({...data, state: v})
									}}
								/>
							)}
							<TextField
								label={t('Postal code')}
								value={zip_code.toString()}
								onChange={v => {
									setData({...data, zip_code: v})
								}}
							/>
						</FormLayout.Group>
					</FormLayout>
				</Card.Section>
			</Card>
		</Modal>
	)
}
export default EditAddress
