import React, {Fragment, useEffect, useState} from 'react'
import {Modal, Card, Link, Badge, Spinner, TextField, Form, ButtonGroup, Button} from "@shopify/polaris"
import {fetchOrderItem, updateOrderItem} from "pages/orders/container"
import {showToast} from "plugins/toast"
import {useTranslation} from "react-i18next"
import Table from 'components/table'
import styled from "styled-components"
import ItemStatusBadge from "modules/itemStatus"

const Wrapper = styled.div`
	position: relative;
	.loading {
		background: #fff;
		z-index: 111;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export default function QuickProcess({code, active, handleToggle}) {
	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [data, setData] = useState({})
	const [number, setNumber] = useState(1)

	const {t} = useTranslation()
	useEffect(() => {
		// document.addEventListener('keypress', keypressHandler)
		if (code) {
			handleFetchData(code)
		}
	}, [active, code])

	const handleSave = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setSaving(true)
		console.log(code, 'code')
		if (!code) return false
		updateOrderItem({item_sku: code, quantity: number}).then((res) => {
			console.log(res, 'order res')
			showToast({
				message: 'Item đã được cập nhật'
			})
			setTimeout(() => {
				return handleClose()
			}, 200)
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		setData({})
		setReady(false)
		// document.removeEventListener('keypress', keypressHandler)
		handleToggle()
	}
	const handleFetchData = async (item_sku) => {
		const result = await fetchOrderItem(item_sku)
		if (result.success) {
			setData(result.data)
			setReady(true)
		} else {
			handleClose()
			return showToast({
				message: result.message,
				error: true
			})
		}
		return false
	}
	const keypressHandler = (event) => {
		if (event.keyCode === 13) {
			handleSave()
		}
	}

	const {title, item_sku, item_id, quantity, status, order_title, quantity_printed, order_id, design, mockup, _id} = data

	const itemQuantity = parseInt(quantity)

	return (
		<Fragment>
			<Modal
				title={`Chi tiết Item ${item_sku ? item_sku : ''}`}
				open={active}
				onClose={handleClose}
			>
				<Form onSubmit={handleSave}>
					<Wrapper>
						{!ready && (
							<div className="loading">
								<Spinner accessibilityLabel="Spinner example" size="large" color="teal"/>
							</div>
						)}
						<Card>
							<Card.Section>
								<Table bordered>
									<tbody>
									<tr>
										<th width={100}>Trạng thái:</th>
										<td colSpan={3}><ItemStatusBadge status={status} /></td>
									</tr>
									<tr>
										<th width={100}>Đơn hàng:</th>
										<td><Link external url={`/orders/edit/${order_id}`}>{order_title}</Link></td>
										<th width={80}>Mã Item:</th>
										<td><Link external url={`/items/edit/${_id}`}>{item_sku}</Link></td>
									</tr>
									<tr>
										<th>Sản phẩm:</th>
										<td colSpan={3}>{title} ({item_id})</td>
									</tr>
									<tr>
										<th>Số lượng:</th>
										<td>{quantity} cái</td>
										<th>Đã in:</th>
										<td>{quantity_printed} cái</td>
									</tr>
									<tr>
										<th>Design:</th>
										<td><Link external url={design}>Xem design</Link></td>
										<th>Mockup:</th>
										<td><Link external url={mockup}>Xem mockup</Link></td>
									</tr>
									</tbody>
								</Table>
							</Card.Section>
							<Card.Section>
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
									{status === 'pending' ? (<TextField
										label={'Đã in'}
										type={'number'}
										value={number.toString()}
										onChange={v => {
											console.log(v, 'v')
											if (v >= itemQuantity) v = quantity
											setNumber(parseInt(v))
										}}
										suffix={`/${quantity} cái`}
										max={itemQuantity}
										min={1}
										autoFocus
									/>) : <div />}
									<ButtonGroup>
										<Button disabled={saving} onClick={handleClose}>Đóng</Button>
										{status === 'pending' && (
											<Button primary onClick={handleSave} loading={saving} disabled={saving || number < 1}>Xác
												nhận</Button>)}
									</ButtonGroup>
								</div>
							</Card.Section>
						</Card>
					</Wrapper>
				</Form>
			</Modal>
		</Fragment>
	)
}
