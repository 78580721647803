import React from 'react'
import {Router} from "@reach/router"
import UsersList from "pages/users/list"
import CreateUser from "pages/users/create"
import EditUser from "pages/users/edit"
import TagsList from "pages/tags/list"
import CreateTag from "pages/tags/create"
import EditTag from "pages/tags/edit"
import EditProfile from "pages/users/profile"

export default function ManagePage() {
  return (
	<Router>
		<UsersList path="/users" />
		<CreateUser path="/users/new" />
		<EditUser path="/users/edit/:userID" />
		<TagsList path="/tags" />
		<CreateTag path="/tags/new" />
		<EditTag path="/tags/edit/:tagID" />
		<EditProfile path="/profile" />
	</Router>
  )
}
