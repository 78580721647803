import React from 'react'
import 'antd/es/select/style/index.css'
import {Select} from 'antd'
import styled from "styled-components"

const SelectWrapper = styled.div`
	.ant-select-selector {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2.4rem;
		text-transform: initial;
		letter-spacing: initial;
		position: relative;
		z-index: 20;
		flex: 1 1;
		width: 100%;
		min-width: 0;
		min-height: 3.6rem;
		margin: 0;
		padding: 0.5rem 1.2rem;
		background: none;
		font-weight: inherit;
		box-shadow: none !important;
		border: none !important;
		font-family: -apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;
		&:before {
			content: '';
			position: absolute;
			z-index: 10;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: .1rem solid var(--p-border,#c4cdd5);
			border-radius: var(--p-border-radius-base,3px);
			box-shadow: inset 0 1px 0 0 rgba(99,115,129,.05);
			pointer-events: none;
		}
	}
	&.error {
		.ant-select-selector:before {
			background-color: #fbeae5;
    		border-color: #bf0711;
		}
	}
	.ant-select-open {
		.ant-select-selector {
			&:before {
				border: var(--p-override-none, 0.2rem solid #5c6ac4);
			}	
		}
	}
`
const { Option } = Select

const AdvanceSelect = (props) => {
	const {error} = props
	return (
		<SelectWrapper className={error ? 'error' : undefined}>
			{props.label && (
				<div className="Polaris-Labelled__LabelWrapper">
					<div className="Polaris-Label">
						<label className="Polaris-Label__Text">
							{props.label}
						</label>
					</div>
				</div>
			)}
			<Select
				{...props}
				error={'true'}
				style={{width: '100%'}}
			>
				{props.options && props.options.length > 0 && props.options.map(item => {
					return (
						<Option key={item.value} value={item.value} title={item.label} disabled={!!item.disabled}>
							{item.label}
						</Option>
					)
				})}
			</Select>
		</SelectWrapper>
	)
}
export default AdvanceSelect