import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Layout, Page, TextStyle, Icon, Banner} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchOrder, initialState, OrderContext, updateOrder, deleteOrders} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import Summary from "./views/summary"
import Note from "./components/note"
import CNote from "./views/cnote"
import Customer from "./views/customer"
import Tag from "./components/cardTag"
import moment from 'moment'
import Seller from "pages/orders/components/seller"
import OrderStatusBadge from "modules/orderStatus"

const ViewOrder = (props) => {
	const orderSub = createSubscription({id: props.orderID, ...initialState})

	return <OrderContext.Provider value={orderSub}>
		<ViewOrderView {...props}/>
	</OrderContext.Provider>

}


const ViewOrderView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)

	useEffect(() => {
		document.title = t('Update order')
		const fetchData = async () => {
			const result = await fetchOrder(props.orderID)

			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.orderID])

	useEffect(() => {
		document.title = `Đơn hàng #${state.title}`
	}, [state.title])

	const handleSave = (data) => {
		setSaving(true)
		if (!data) data = state
		updateOrder(data).then((res) => {
			console.log(res.order_status, 'order res')
			showToast({
				message: 'Order was updated'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}
	const handleCancel = () => {
		showConfirm({
			title: `Hủy đơn hàng`,
			message: t('Bạn có chắc chắn muốn hủy đơn hàng này, hành động này sẽ không thể khôi phục lại.'),
			confirm: t('Hủy đơn hàng'),
			cancel: t('Giữ lại'),
			danger: true
		}).then(res => {
			if (res) {
				setState({...state, order_status: 'canceled'})
				handleSave({...state, order_status: 'canceled'})
			}
		})
		return false
	}
	const handleToggleArchive = (status = 'closed') => {
		setState({...state, order_status: status})
		handleSave({...state, order_status: status})
		return false
	}

	const handleConfirm = () => {
		showConfirm({
			title: `Xác nhận đơn hàng`,
			message: t('Hãy chắc chắn đơn hàng hợp lệ trước khi bấm xác nhận.'),
			confirm: t('Xác nhận đơn hàng'),
			cancel: t('Hủy')
		}).then(res => {
			if (res) {
				setState({...state, order_status: 'processing'})
				handleSave({...state, order_status: 'processing'})
			}
		})
		return false
	}

	const {title, created_at, order_status, _id, shipped_at, canceled_at} = state

	let bannerStatus, bannerTitle, bannerDesc
	switch (order_status) {
		case 'shipped':
			bannerStatus = 'success'
			bannerTitle = 'Giao hàng thành công'
			bannerDesc = 'Đơn hàng đã giao thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break
		case 'canceled':
			bannerStatus = 'critical'
			bannerTitle = 'Đơn hàng đã bị hủy'
			bannerDesc = 'Đơn hàng đã hủy lúc ' + moment(canceled_at).format('DD-MM-YY HH:mm')
			break
		case 'incompleted':
			bannerStatus = ''
			bannerTitle = 'Giao hàng không thành công'
			bannerDesc = 'Giao hàng không thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break

	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="orders:view"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<Page
											title={title}
											fullWidth
											subtitle={<TextStyle>{moment(created_at).format('YYYY-MM-DD HH:mm')}</TextStyle>}
											breadcrumbs={[{content: t('Orders'), onAction: () => navigate('/orders')}]}
											titleMetadata={<Fragment>
												<OrderStatusBadge order_status={order_status} />
											</Fragment>}
											primaryAction={{content: 'Sửa đơn hàng', onAction: () => navigate(`/orders/edit/${_id}`)}}
										>
											{['shipped', 'incompleted', 'canceled'].includes(order_status) && (
												<div style={{marginBottom: '2rem'}}>
													<Banner title={bannerTitle} status={bannerStatus}>
														<p>{bannerDesc}</p>
													</Banner>
												</div>
											)}
											<Layout>
												<Layout.Section>
													<Summary order={state} viewOnly={true} />
													<Note viewOnly={true} handleSave={handleSave}/>
												</Layout.Section>
												<Layout.Section secondary>
													<CNote order={state} viewOnly={true}/>
													<Seller order={state} viewOnly={true}/>
													<Customer order={state} viewOnly={true}/>
													{order_status !== 'open' && <Tag viewOnly={true}/>}
												</Layout.Section>
											</Layout>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default ViewOrder
