import React, {useContext} from 'react'
import {Card, ChoiceList} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {useSubscription} from "global-state-hook";
import {CollectionContext} from "../container";

export default function CardVisibility() {
	const {t} = useTranslation()

	const pageSub = useContext(CollectionContext)
	let {setState, state} = useSubscription(pageSub)
	const {visibility} = state
	return (
		<Card sectioned title={t('Status')}>
			<ChoiceList
				title={'Visibility'}
				titleHidden
				choices={[
					{label: t('Visible'), value: true},
					{label: t('Hidden'), value: false}
				]}
				selected={[visibility]}
				onChange={v => setState({visibility: v[0]})}
			/>
		</Card>
	)
}
