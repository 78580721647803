import React, {useEffect} from 'react'
import {Page, Layout} from "@shopify/polaris"
import {navigate, Redirect} from "@reach/router"
import {AuthConsumer} from "authentication/auth"
import {useTranslation} from "react-i18next"
import {getGreetingTime} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import moment from "moment"
import empty from 'images/empty-state.svg'

const Dashboard = () => {
	const {t} = useTranslation()
	useEffect(() => {
		document.title = t('Home')
	}, [])

	return (
		<Page>
			<AuthConsumer>
				{({authenticated, user}) => authenticated ? (
					<Layout>
						<EmptyPage
							title={t(`Good ${getGreetingTime(moment())}`) + ', ' + user.first_name}
							primaryAction={{content: t('Xem đơn hàng'), onAction: () => navigate('/orders')}}
							image={empty}
						/>
					</Layout>
				) : <Redirect to={'/login'} noThrow />}
			</AuthConsumer>
		</Page>
	)
}
export default Dashboard
