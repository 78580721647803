import {createSubscription, useSubscription} from "global-state-hook"
import axios from 'axios'
import {showToast} from "plugins/toast"
import {createContext} from "react"
import {navigate} from "@reach/router"

export const initialState = {
	id: '',
	title: '',
	color: '',
	description: '',
	visibility: true
}

export const colorOptions = [
	{ value: 'ocean', label: 'Ocean', color: '#00B8D9' },
	{ value: 'blue', label: 'Blue', color: '#0052CC' },
	{ value: 'purple', label: 'Purple', color: '#5243AA' },
	{ value: 'red', label: 'Red', color: 'red' },
	{ value: 'orange', label: 'Orange', color: '#FF8B00' },
	{ value: 'yellow', label: 'Yellow', color: '#FFC400' },
	{ value: 'green', label: 'Green', color: '#36B37E' },
	{ value: 'pink', label: 'Pink', color: '#f2798e' },
	{ value: 'brown', label: 'Brown', color: '#9F6A58' },
	{ value: 'forest', label: 'Forest', color: '#00875A' },
	{ value: 'slate', label: 'Slate', color: '#253858' },
	{ value: 'silver', label: 'Silver', color: '#666666' },
]

export const TagContext = createContext({...initialState})


export const createTag = (state) => {
	return new Promise((resolve, reject) => {
		axios.post('/tags', {
			...state
		})
			.then(resolve)
			.catch(reject)
	})
}
export const fetchTag = id => {
	return new Promise(resolve => {
		axios.get(`/tags?ids=${id}`)
			.then(response => {
				console.log(response, 'response')
				const {data} = response.data
				resolve(data && data[0])
			})
			.catch(function (error) {
				console.log(error)
				showToast({
					message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
					error: true
				})
			})
			.then(resolve)
	})
}

export const fetchTagName = id => {
	return new Promise(resolve => {
		axios.get(`/tags?ids=${id}`)
			.then(response => {
				const {data} = response.data
				let tag
				if (data && data[0]) {
					tag = {
						title: data[0].title,
						id: data[0]._id
					}
				}
				resolve(tag)
			})
			.then(resolve)
	})
}

export const updateTag = (data) => {
	const {_id, ...rest} = data
	return new Promise((resolve, reject) => {
		axios.post(`/tags/${_id}`, rest)
			.then(response => {
				console.log(response, 'response')
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const deleteTags = (ids, redirect = false) => {
	ids = ids.join(',')
	return new Promise((resolve, reject) => {
		axios.get(`/tags/delete?ids=${ids}`)
			.then(response => {
				console.log(response, 'response')
				if (response && response.status === 200 && response.data.success) {
					showToast({
						message: 'Tags deleted'
					})
					if (redirect) {
						navigate('/manage/tags')
					} else {
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					}
				}
				resolve(response)
			})
			.catch(reject)
			.then(resolve)
	})
}

export const fetchTagsList = (params, cancelToken) => {
	const newParams = Object.assign({
		limit: 20,
		page: 1
	}, params)
	return new Promise(resolve => {
		axios.get('/tags', {
			params: newParams,
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const searchTags = (params, cancelToken) => {
	console.log('run search')
	return new Promise(resolve => {
		axios.get('/tags', {
			params: {
				page: 1,
				limit: 20,
				...params
			},
			cancelToken: cancelToken
		})
			.then(response => {
				console.log(response, 'response')
				resolve(response.data)
			})
			.catch(function (error) {
				if (!axios.isCancel(error)) {
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				}
			})
			.then(resolve)
	})
}

export const tagsListSubscription = createSubscription({ready: false})
export const useTags = () => {
	let {state, setState} = useSubscription(tagsListSubscription)
	const fetchList = (params) => {
		const newParams = Object.assign({
			limit: 20,
			page: 1
		}, params)
		return new Promise(resolve => {
			axios.get('/tags', {
				params: newParams
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	const search = (params) => {
		console.log('run search')
		return new Promise(resolve => {
			axios.get('/tags', {
				params: {
					page: 1,
					limit: 20,
					...params
				}
			})
				.then(response => {
					console.log(response, 'response')
					const {data, current_page, total, last_page} = response.data
					setState({items: data, current_page: current_page, total: total, last_page: last_page, ready: true})
					resolve(response)
				})
				.catch(function (error) {
					console.log(error)
					showToast({
						message: 'Có lỗi xảy ra, vui lòng thử lại hoặc báo cho quản trị viên',
						error: true
					})
				})
				.then(resolve)
		})
	}

	return {state, setState, fetchList, search}
}
