import React, {useContext} from 'react'
import {Card, FormLayout, TextField, Layout} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {UserContext} from "../container"
import {useTranslation} from "react-i18next"

export default function CardInformation({error}) {
	const pageSub = useContext(UserContext)
	let {state, setState} = useSubscription(pageSub)
	const {first_name, last_name, phone, birthday} = state
	const {t} = useTranslation()
	return (
		<Layout.AnnotatedSection
			title={t('Personal information')}
		>
			<Card sectioned>
				<FormLayout>
					<FormLayout.Group>
						<TextField
							label={t('First name')}
							value={first_name}
							onChange={v => setState({first_name: v})}
							error={error.includes('first_name')}
						/>
						<TextField
							label={t('Last name')}
							value={last_name}
							onChange={v => setState({last_name: v})}
							error={error.includes('last_name')}
						/>
					</FormLayout.Group>
					<FormLayout.Group>
						<TextField
							label={t('Phone number')}
							value={phone}
							onChange={v => setState({phone: v})}
						/>
						<TextField
							type="date"
							value={birthday}
							label={t('Date of birth')}
						   	onChange={v => setState({birthday: v})}/>
					</FormLayout.Group>
				</FormLayout>
			</Card>
		</Layout.AnnotatedSection>
	)
}
