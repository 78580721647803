import React, {useCallback, useContext, useState} from 'react'
import {Card, FormLayout, Layout, TextField, Button, TextStyle} from "@shopify/polaris"
import {useSubscription} from "global-state-hook"
import {CustomerContext} from "../container"
import {useTranslation} from "react-i18next"
import {generatePassword} from "helpers/utils"
import {HideMinor, ViewMinor, EditMinor} from '@shopify/polaris-icons'

export default function CardLogin({error, disabledEmail}) {
	const [fieldType, setFieldType] = useState('password')
	const [disabled, setDisabled] = useState(true)
	const pageSub = useContext(CustomerContext)
	let {state, setState} = useSubscription(pageSub)
	const {email, password, _id} = state
	const {t} = useTranslation()

	const handleToggle = useCallback(() => setFieldType((fieldType) => {
		if (fieldType === 'password') return 'text'
		if (fieldType === 'text') return 'password'
	}), [fieldType])

	const handleToggleEdit = useCallback(() => {
		setDisabled(disabled => !disabled)
		setFieldType('text')
		setTimeout(() => {
			document.querySelector('[name="password"]').select()
		}, 100)
	}, [])

	const handleGenerate = useCallback(() => {
		setState({password: generatePassword(12)})
		setFieldType('text')
		setDisabled(false)
	}, [password])
	return (
		<Layout.AnnotatedSection
			title={t('Account information')}
			description={'Thông tin dùng để đăng nhập trên hệ thống'}
		>
			<Card sectioned>
				<FormLayout>
					<FormLayout.Group>
						<TextField
							label={t('Email')}
							value={email}
							onChange={v => setState({email: v})}
							error={error.includes('email')}
							disabled={disabledEmail}
						/>
						{_id && <TextField
							name={'password'}
							disabled={disabled}
							placeholder={disabled ? '**********' : ''}
							type={fieldType}
							label={t('Password')}
							value={password}
							onChange={v => setState({password: v})}
							labelAction={{
								content: t('Generate'),
								onAction: handleGenerate

							}}
							connectedRight={
								<Button pressed={!disabled} icon={EditMinor} onClick={handleToggleEdit}/>
							}
							error={error.includes('password')}
						/>}
					</FormLayout.Group>
					{!_id && <TextStyle>Mật khẩu ngẫu nhiên sẽ được gửi về email của seller.</TextStyle>}
				</FormLayout>
			</Card>
		</Layout.AnnotatedSection>
	)
}
