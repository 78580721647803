import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardLogin from "./components/cardLogin"
import CardInformation from "./components/cardInformation"
import CardRole from "./components/cardRole"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchUser, initialState, UserContext, updateUser, deleteUsers} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import {authContext} from "authentication/auth"

const EditProfile = (props) => {
	const pageSub = createSubscription({...initialState})

	return <UserContext.Provider value={pageSub}>
		<EditProfileView {...props}/>
	</UserContext.Provider>

}

const EditProfileView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(UserContext)
	const userAuth = useContext(authContext)
	let {setState, state} = useSubscription(pageSub)

	const {user} = userAuth
	useEffect(() => {
		document.title = t('Cập nhật tải khoản')

		const fetchData = async () => {
			const result = await fetchUser(user._id)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user._id])

	const handleSave = () => {
		let error = []
		const required = ['first_name', 'email', 'role']
		required.map(f => {
			if (!state[f]) error.push(f)
		})
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}
		setSaving(true)
		updateUser(state).then(() => {
			showToast({
				message: 'Cập nhật thành công'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="profile:update"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<StickyActions
											actions={(
												<ButtonGroup>
													<Button onClick={() => navigate('/')}>{t('Discard')}</Button>
													<Button
														primary
														onClick={handleSave}
														loading={saving}
													>
														{t('Update')}
											</Button>
												</ButtonGroup>
											)}
										/>
										<Page
											title={'Cập nhật tài khoản'}
										>
											<Layout>
												<Layout.Section>
													<CardLogin disabledEmail={true} error={mandatoryFields}/>
													<CardInformation error={mandatoryFields}/>
												</Layout.Section>
											</Layout>
											<PageActions
												primaryAction={{
													content: t('Update'),
													onAction: handleSave,
													loading: saving
												}}
											/>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditProfile
