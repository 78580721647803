import React, {useEffect, useState, useCallback, Fragment} from 'react'
import {Page, Layout, Card, ResourceItem, TextStyle, Caption, Badge} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {navigate, Redirect} from "@reach/router"
import {deleteCustomers, searchCustomers, fetchCustomersList} from "./container"
import {SkeletonList} from "modules/skeleton"
import {AuthConsumer} from "authentication/auth"
import DataList from "modules/dataList"
import Filters from "modules/filters"
import Pagination from "modules/pagination"
import {getParam, urlParams, getParams, updateParam} from "helpers/utils"
import EmptyPage from "modules/emptyPage"
import {showConfirm} from "plugins/confirm"
import empty from 'images/customer-empty.svg'
import axios from "axios"
import Can from "authentication/can"

const PagesList = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	useEffect(() => {
		document.title = t('Manage sellers')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					result = await searchCustomers(params, source.token)
					if (result) isSearched = true
				} else {
					result = await fetchCustomersList({page: currentPage}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error;
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [])

	const handleSearch = () => {
		const params = getParams()
		;(async () => {
			const result = await searchCustomers(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}

	const {ready, last_page, current_page, isSearched} = data
	let items = data.data || []
	items = items && items.length && items.map(item => {
		item.id = item._id
		return item
	})

	const handleFiltersChange = useCallback(
		(key, value) => {
			// Reset current page
			updateParam('page', '', null)
			// Update new filter
			appliedFilters[key] = value
			setAppliedFilters({...appliedFilters})
			for (const [key, value] of Object.entries(appliedFilters)) {
				updateParam(key, value + '', null)
			}
			// Run search with new filters
			handleSearch()
		},
		[],
	)

	const handleFiltersRemove = useCallback(
		(key) => {
			// Reset current page
			updateParam('page', '', null)
			delete appliedFilters[key]
			setAppliedFilters({...appliedFilters})
			updateParam(key, '', null)
			// Run search with new filters
			handleSearch()
		},
		[],
	)
	const handleFiltersClearAll = () => {
		for (const obj of Object.keys(appliedFilters)) {
			delete appliedFilters[obj]
		}
		setAppliedFilters({...appliedFilters})
	}

	// const filters = []

	const promotedBulkActions = [
		{
			content: t('deleteWithCount', {count: selectedItems.length, resourceName: 'seller'}),
			onAction: () => {
				showConfirm({
					title: t('deleteResourceConfirm', {count: selectedItems.length, resourceName: 'seller'}),
					message: t('Are you sure you want to delete these seller? This can\'t be undone.'),
					confirm: t('Delete'),
					cancel: t('Cancel'),
					danger: true
				}).then(res => {
					if (res) {
						deleteCustomers(selectedItems)
					}
				})
				return false
			}
		},
	]

	const headers = [
		{
			id: 'total_order',
			title: t('Số đơn hàng'),
			width: 15
		},
		{
			id: 'phone',
			title: t('Phone'),
			width: 15
		},
		{
			id: 'seller_type',
			title: t('Loại KH'),
		},
		{
			id: 'created_at',
			title: t('Joined at'),
			width: 15
		}
	]

	const MAP_SELLERS = {
		shopbase: 'ShopBase',
		burger_prints: 'Burger Prints',
		other: 'Khác',
	}
	const renderItem = (item) => {
		const {_id, first_name, last_name, email, phone, created_at, seller_id,	total_order, seller_type} = item
		const name = [first_name, last_name]
		return (
			<ResourceItem
				id={_id}
				onClick={() => navigate(`/sellers/edit/${_id}`)}
				accessibilityLabel={`View details for ${first_name}`}
				name={first_name}
			>
				<div className="item-wrapper">
					<div className="column title">
						<h3>
							<TextStyle variation="strong">{name.join(' ')} &nbsp;</TextStyle>
							<Badge status={'info'}>{seller_id}</Badge>
							<Caption>{email}</Caption>
						</h3>
					</div>
					<div className="column column-15">
						<TextStyle>{total_order}</TextStyle>
					</div>
					<div className="column column-15">
						<TextStyle>{phone}</TextStyle>
					</div>
					<div className="column">
						<TextStyle>{MAP_SELLERS[seller_type]}</TextStyle>
					</div>
					<div className="column column-15">
						<TextStyle>{created_at}</TextStyle>
					</div>
				</div>
			</ResourceItem>
		)
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				if (authenticated) {
					return (
						<Can
							role={user.role}
							perform="customers:list"
							yes={() => {
								return ready ? (
									<Page
										title={t('Sellers')}
										fullWidth
										primaryAction={{content: t('Add seller'), onAction: () => navigate('/sellers/new')}}
									>
										<Layout>
											<Layout.Section>
												{items.length > 0 || isSearched ? (
													<Card sectioned>
														<Filters
															filters={[]}
															handleSearch={handleSearch}
															appliedFilters={appliedFilters}
															handleFiltersChange={handleFiltersChange}
															handleFiltersRemove={handleFiltersRemove}
															handleFiltersClearAll={handleFiltersClearAll}
														/>
														<DataList
															resourceName={{singular: t('seller'), plural: t('seller')}}
															items={items}
															selectedItems={selectedItems}
															onSelectionChange={setSelectedItems}
															promotedBulkActions={promotedBulkActions}
															headers={headers}
															renderItem={renderItem}
															pageType={'sellers'}
														/>
														<Pagination
															pageNumber={current_page}
															lastPage={last_page}
															path={'sellers'}
														/>
													</Card>
												) : <EmptyPage
													image={empty}
													primaryAction={{
														content: 'Thêm seller',
														onAction: () => navigate('/sellers/new')
													}}
												/>}
											</Layout.Section>
										</Layout>
									</Page>
								) : <SkeletonList fullWidth/>
							}}
						/>
					)
				}
				return <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default PagesList
