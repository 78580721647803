import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react'
import {
	Button,
	ButtonGroup,
	Layout,
	Page,
	Banner,
	FormLayout,
	Select,
	TextStyle,
	Modal,
	PageActions
} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchOrder, initialState, OrderContext, updateOrder, deleteOrders, createLabel} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import Summary from "./components/summary"
import Shipping from "./components/shipping"
import Note from "./components/note"
import CNote from "./components/cnote"
import Customer from "./components/customer"
import Tag from "./components/cardTag"
import {CancelSmallMinor, DeleteMinor} from '@shopify/polaris-icons'
import moment from 'moment'
import Seller from "pages/orders/components/seller"
import OrderStatusBadge from "modules/orderStatus"
import ShippingStatusBadge from "modules/shippingStatus"
import UpdateStatus from "pages/orders/components/updateStatus"

const EditOrder = (props) => {
	const orderSub = createSubscription({id: props.orderID, ...initialState})

	return <OrderContext.Provider value={orderSub}>
		<EditOrderView {...props}/>
	</OrderContext.Provider>
}

export const Confirm = ({id, open, handleToggle, handleSave, loading}) => {
	const [shipping_carrier, setShipping_carrier] = useState('multrans')
	const handleConfirm = () => {
		handleSave({_id: id, shipping_carrier: shipping_carrier, order_status: 'printing'})
		return false
	}
	return (
		<Modal
			open={open}
			title={'Xác nhận đơn hàng'}
			onClose={handleToggle}
			primaryAction={{
				content: 'Xác nhận đơn hàng',
				onAction: handleConfirm,
				disabled: !shipping_carrier,
				loading: loading
			}}
			secondaryActions={[
				{
					content: 'Đóng',
					onAction: handleToggle,
					disabled: loading
				}
			]}
		>
			<Modal.Section>
				<FormLayout>
					<Select
						label={'Đơn vị vận chuyển'}
						value={shipping_carrier}
						options={[
							{label: "Chọn đơn vị vận chuyển", value: ''},
							{label: "Multrans", value: 'multrans'},
							{label: "Other", value: 'other'}
						]}
						onChange={v => setShipping_carrier(v)}
					/>
					<p>Và hãy chắc chắn đơn hàng hợp lệ trước khi bấm xác nhận.</p>
				</FormLayout>
			</Modal.Section>
		</Modal>
	)
}


const EditOrderView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [changeStatus, setChangeStatus] = useState(false)
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)

	useEffect(() => {
		document.title = t('Update order')
		const fetchData = async () => {
			const result = await fetchOrder(props.orderID)
			if (result) {
				setState(result)
				setReady(true)
			}
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.orderID])

	useEffect(() => {
		document.title = `Update #${state.title}`
	}, [state.title])

	const handleSave = (data) => {
		setSaving(true)
		if (!data) data = state
		data._id = state._id
		updateOrder(data).then((res) => {
			if (res.success) {
				showToast({
					message: t(res.message)
				})
			} else {
				showToast({
					message: res.message,
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleConfirm = (data) => {
		setSaving(true)
		const {shipping_carrier} = data
		updateOrder(data).then((res) => {
			console.log(res, 'res confirm')
			if (res.data.success) {
				setState({order_status: 'printing'})
				showToast({
					message: 'Đơn hàng đã được xác nhận'
				})
				if (shipping_carrier === 'multrans') {
					const {title} = state
					createLabel({title: title}).then(res => {
						console.log(res, 'create label res')
					})
				}
			} else {
				return showToast({
					message: 'Xác nhận không thành công, vui lòng thử lại',
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
			handleToggle()
		})
	}

	const handleCancel = () => {
		showConfirm({
			title: `Hủy đơn hàng`,
			message: t('Bạn có chắc chắn muốn hủy đơn hàng này, hành động này sẽ không thể khôi phục lại.'),
			confirm: t('Hủy đơn hàng'),
			cancel: t('Giữ lại'),
			danger: true
		}).then(res => {
			if (res) {
				setState({...state, order_status: 'canceled'})
				handleSave({...state, order_status: 'canceled'})
			}
		})
		return false
	}

	const handleChange = (status) => {
		showConfirm({
			title: `Xác nhận`,
			message: t('Hãy chắc chắn với hành động này.'),
			confirm: t('Xác nhận'),
			cancel: t('Đóng'),
		}).then(res => {
			if (res) {
				setState({...state, order_status: status})
				handleSave({...state, order_status: status})
			}
		})
		return false
	}

	const handleDelete = () => {
		const {_id, title} = state
		showConfirm({
			title: `Xóa đơn hàng ${title}?`,
			message: 'Hãy chắc chắn rằng bạn muốn xóa đơn hàng này, hành động này sẽ không thể khôi phục lại.',
			confirm: t('Tôi muốn xóa'),
			cancel: t('Đóng'),
			danger: true
		}).then(res => {
			if (res) {
				deleteOrders([_id], true)
			}
		})
		return false
	}

	const handleToggle = useCallback(() => setOpenConfirm((openConfirm) => !openConfirm), [])
	const handleToggleStatus = useCallback(() => setChangeStatus((changeStatus) => !changeStatus), [])

	const {title, created_at, order_status, _id, shipped_at, canceled_at, shipping_status} = state

	let bannerStatus, bannerTitle, bannerDesc
	switch (order_status) {
		case 'shipped':
			bannerStatus = 'success'
			bannerTitle = 'Giao hàng thành công'
			bannerDesc = 'Đơn hàng đã giao thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break
		case 'canceled':
			bannerStatus = 'critical'
			bannerTitle = 'Đơn hàng đã bị hủy'
			bannerDesc = 'Đơn hàng đã hủy lúc ' + moment(canceled_at).format('DD-MM-YY HH:mm')
			break
		case 'incompleted':
			bannerStatus = ''
			bannerTitle = 'Giao hàng không thành công'
			bannerDesc = 'Giao hàng không thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break

	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="orders:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										{!['shipped', 'incompleted'].includes(order_status) && (
											<StickyActions
												actions={(
													<ButtonGroup>
														<Can
															role={user.role}
															perform="orders:changeStatus"
															yes={() => (
																<>
																	<UpdateStatus open={changeStatus} handleToggle={handleToggleStatus} />
																	<Button destructive primary onClick={handleToggleStatus}>{t('Sửa trạng thái')}</Button>
																</>
															)}
															no={() => false}
														/>
														{!['canceled', 'shipped', 'incompleted', 'shipping'].includes(order_status) && (
															<Can
																role={user.role}
																perform="orders:cancel"
																yes={() => (
																	<Button destructive monochrome outline icon={CancelSmallMinor} onClick={handleCancel}>{t('Hủy đơn')}</Button>
																)}
																no={() => false}
															/>
														)}
														{order_status === 'open' && (
															<Can
																role={user.role}
																perform="orders:confirm"
																yes={() => (
																	<Button primary onClick={handleToggle} loading={saving}>{t('Xác nhận đơn hàng')}</Button>
																)}
																no={() => false}
															/>
														)}
														{order_status === 'storing' && <Button primary onClick={() => handleChange('shipping')} loading={saving}>{t('Bắt đầu vận chuyển')}</Button>}
														{order_status === 'shipping' &&<Button outline onClick={() => handleChange('incompleted')} loading={saving}>{t('Giao không thành công')}</Button>}
														{order_status === 'shipping' && <Button primary onClick={() => handleChange('shipped')} loading={saving}>{t('Giao thành công')}</Button>}
													</ButtonGroup>
												)}
											/>
										)}
										{order_status === 'open' && <Confirm id={_id} loading={saving} handleToggle={handleToggle} handleSave={handleConfirm} open={openConfirm} />}
										<Page
											title={title}
											fullWidth
											subtitle={<TextStyle>{moment(created_at).format('DD-MM-YYYY HH:mm')}</TextStyle>}
											breadcrumbs={[{content: t('Orders'), onAction: () => window.history.back()}]}
											titleMetadata={<div style={{display: 'flex'}}>
												<OrderStatusBadge order_status={order_status} />
												{order_status === 'shipping' && <ShippingStatusBadge shipping_status={shipping_status} />}
											</div>}
										>
											{['shipped', 'incompleted', 'canceled'].includes(order_status) && (
												<div style={{marginBottom: '2rem'}}>
													<Banner title={bannerTitle} status={bannerStatus}>
														<p>{bannerDesc}</p>
													</Banner>
												</div>
											)}
											<Layout>
												<Layout.Section>
													<Summary user={user} />
													{order_status !== 'open' && <Shipping/>}
													<Note handleSave={handleSave}/>
												</Layout.Section>
												<Layout.Section secondary>
													<CNote/>
													<Seller/>
													<Customer user={user}/>
													{order_status !== 'open' && <Tag/>}
												</Layout.Section>
											</Layout>
											{order_status !== 'shipped' && order_status !== 'shipping' && (
												<Can
													role={user.role}
													perform="orders:delete"
													yes={() => (
														<PageActions
															secondaryActions={[
																{
																	content: t('Delete'),
																	destructive: true,
																	icon: DeleteMinor,
																	onAction: handleDelete
																}
															]}
														/>
													)}
													no={() => false}
												/>
											)}
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
						no={() => <Redirect to={`/orders/view/${props.orderID}`} noThrow />}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditOrder
