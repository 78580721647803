import React, {Component, createContext} from "react"
import axios from 'axios'
import {ADMIN_URL, API_URL, TOKEN_API} from "stores/config"
import {showToast} from "plugins/toast"
import {navigate} from "@reach/router"
import BarcodeListener from "components/barcodeReader"

export const initialUserAuth = {
	authenticated: false,
	user: {
		role: 'visitor'
	},
	accessToken: ''
}

axios.defaults.baseURL = API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'

axios.defaults.validateStatus = (status) => {
	if (status === 401) {
		localStorage.setItem('userAuth', JSON.stringify({
			...initialUserAuth,
			authenticated: false
		}))
		showToast({
			message: 'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại.',
			error: true
		})
		setTimeout(() => {
			window.location.href = ADMIN_URL + '/login'
		}, 1500)
	}
	if (status === 404) {
		window.location.href = ADMIN_URL + '/404'
	}
	return (status >= 200 && status < 300) || status === 401 || status === 404;
}

export const authContext = createContext({
	authenticated: false, // to check if authenticated or not
	user: {
		role: 'visitor'
	}, // store all the user details
	accessToken: "", // accessToken of user for Auth0
	handleLogin: () => {
	}, // to start the login process
	logout: () => {
	} // logout the user
})

export const AuthProvider = authContext.Provider
export const AuthConsumer = authContext.Consumer

class Auth extends Component {
	state = {
		...initialUserAuth
	}
	constructor(state) {
		super(state)
		this.state = {...this.state, ...state}
		const userAuth = localStorage.getItem('userAuth') && JSON.parse(localStorage.getItem('userAuth'))
		if (userAuth && userAuth.accessToken) {
			this.state = {...this.state, ...userAuth}
			axios.defaults.headers.common['Authorization'] = `Bearer ${userAuth && userAuth.accessToken}`
			if (userAuth.user.role === 'seller') {
				axios.defaults.headers.common['TokenApi'] = TOKEN_API
			}
		}
	}
	// componentDidMount() {
	// 	const userAuth = localStorage.getItem('userAuth') && JSON.parse(localStorage.getItem('userAuth'))
	// 	if (userAuth && userAuth.accessToken) {
	// 		this.setState({...userAuth})
	// 		axios.defaults.headers.common['Authorization'] = `Bearer ${userAuth && userAuth.accessToken}`
	// 	}
	// }

	handleLogin = (email, password) => {
		return new Promise((resolve, reject) => {
			axios.post('/staffs/login', {
				email: email,
				password: password
			})
				.then(response => {
					if (response.data.success) {
						const {token, staff} = response.data.data
						const userAuth = {
							authenticated: true,
							user: {
								...staff
							},
							accessToken: token
						}
						localStorage.setItem('userAuth', JSON.stringify(userAuth))
						this.setState(userAuth)
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
					}
					resolve(response.data)
				})
				.catch(reject)
		})
	}

	handleSellerLogin = (email, password) => {
		return new Promise((resolve, reject) => {
			axios.post('/customers/login', {
				email: email,
				password: password
			})
				.then(response => {
					if (response.data.success) {
						const {token, customer} = response.data.data
						const userAuth = {
							authenticated: true,
							user: {
								...customer,
								role: 'seller'
							},
							accessToken: token
						}
						localStorage.setItem('userAuth', JSON.stringify(userAuth))
						this.setState(userAuth)
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
						axios.defaults.headers.common['TokenApi'] = TOKEN_API
					}
					resolve(response.data)
				})
				.catch(reject)
		})
	}

	logout = () => {
		console.log('log out')
		localStorage.setItem('userAuth', JSON.stringify({
			...initialUserAuth,
			authenticated: false
		}))
		showToast({
			message: 'You are logged out'
		})
		setTimeout(() => {
			this.setState({
				...initialUserAuth,
				authenticated: false
			})
			// window.location.href = ADMIN_URL + '/login'
			return navigate('/login')
		}, 1500)
	}

	render() {
		const authProviderValue = {
			...this.state,
			handleLogin: this.handleLogin,
			handleSellerLogin: this.handleSellerLogin,
			logout: this.logout
		}
		return (
			<AuthProvider value={authProviderValue}>
				<BarcodeListener />
				{this.props.children}
			</AuthProvider>
		)
	}
}

export default Auth
