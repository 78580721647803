import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardTitle from "./components/cardTitle"
import CardVisibility from "./components/cardVisibility"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchCollection, initialState, CollectionContext, updateCollection, deleteCollections} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"

const EditCollection = (props) => {
	const pageSub = createSubscription({id: props.typeID, ...initialState})

	return <CollectionContext.Provider value={pageSub}>
		<EditCollectionView {...props}/>
	</CollectionContext.Provider>

}


const EditCollectionView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(CollectionContext)
	let {setState, state} = useSubscription(pageSub)
	useEffect(() => {
		document.title = t('Edit collection')
		const fetchData = async () => {
			const result = await fetchCollection(props.typeID)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.collectionID])

	const handleSave = () => {
		const {title} = state
		let error = []
		if (!title) {
			error.push('title')
		}
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}
		setSaving(true)
		updateCollection(state).then(() => {
			showToast({
				message: 'Collection was updated'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleDelete = () => {
		const {_id, title} = state
		showConfirm({
			title: `Delete ${title}?`,
			message: t('Are you sure you want to delete this collection? This can\'t be undone.'),
			confirm: t('Delete'),
			cancel: t('Cancel'),
			danger: true
		}).then(res => {
			if (res) {
				deleteCollections([_id], true)
			}
		})
		return false
	}

	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="collections:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										<StickyActions
											actions={(
												<ButtonGroup>
													<Button onClick={() => navigate('/types')}>Cancel</Button>
													<Button
														primary
														onClick={handleSave}
														loading={saving}
													>
														{t('Save')}
											</Button>
												</ButtonGroup>
											)}
										/>
										<Page
											title={t('Edit collection')}
											breadcrumbs={[{content: t('Collections'), onAction: () => navigate('/types')}]}
										>
											<Layout>
												<Layout.Section>
													<CardTitle error={mandatoryFields}/>
												</Layout.Section>
												<Layout.Section secondary>
													<CardVisibility />
												</Layout.Section>
											</Layout>
											<PageActions
												primaryAction={{
													content: t('Save'),
													onAction: handleSave,
													loading: saving
												}}
												secondaryActions={[
													{
														content: t('Delete'),
														destructive: true,
														onAction: handleDelete

													}
												]}
											/>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditCollection
