import React, {Fragment, useContext, useEffect, useState, useRef} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import CardTitle from "./components/cardTitle"
import CardStatus from "./components/cardStatus"
import CardOrganization from "./components/cardOrganization"
import CardSizes from "pages/products/components/cardSizes"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {
	fetchProduct,
	initialState,
	ProductContext,
	updateProduct,
	deleteProducts
} from "./container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import axios from "axios"
import { HotKeys } from "react-hotkeys"
import {keyMaps} from "stores/keymap"

const EditProduct = (props) => {
	const pageSub = createSubscription({id: props.productID, ...initialState})

	return <ProductContext.Provider value={pageSub}>
		<EditProductView {...props}/>
	</ProductContext.Provider>

}

const EditProductView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])

	const pageSub = useContext(ProductContext)
	let {setState, state} = useSubscription(pageSub)

	const source = axios.CancelToken.source()
	useEffect(() => {
		document.title = t('Edit product')
		let result
		const fetchData = async () => {
			try {
				result = await fetchProduct(props.productID)
				if (result) {
					setState({...result})
					setReady(true)
				}

			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error;
				}
			}
		}
		fetchData()
		return () => source.cancel()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.productID])

	const handleSave = () => {
		let error = []
		const required = ['title', 'item_id', 'width', 'height', 'length', 'weight']
		required.map(f => {
			if (!state[f]) error.push(f)
		})
		setMandatoryFields(error)
		if (error.length > 0) {
			window.scrollTo({top: 0, behavior: 'smooth'})
			return showToast({
				message: t('Please fill in all the mandatory fields'),
				error: true
			})
		}

		setSaving(true)
		updateProduct(state).then((res) => {
			if (res.data.success) {
				return showToast({
					message: 'Cập nhật thành công'
				})
			} else {
				return showToast({
					message: res.data.message,
					error: true
				})
			}
		}).catch((error) => {
			console.error(error)
			showToast({
				message: t('Something went wrong, please try again'),
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}

	const handleDelete = () => {
		const {_id, title} = state
		showConfirm({
			title: `Delete ${title}?`,
			message: t('Are you sure you want to delete this product? This can\'t be undone.'),
			confirm: t('Delete'),
			cancel: t('Cancel'),
			danger: true
		}).then(res => {
			if (res) {
				deleteProducts([_id], true)
			}
		})
		return false
	}
	const {title} = state

	const handlers = {
		SAVE: e => {
			e.preventDefault()
			handleSave()
		}
	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="products:edit"
						yes={() => (
							<HotKeys keyMap={keyMaps} handlers={handlers}>
								{ready ? (
									<Fragment>
										<StickyActions
											actions={(
												<ButtonGroup>
													<Button onClick={() => navigate('/products')}>{t('Discard')}</Button>
													<Button
														primary
														onClick={handleSave}
														loading={saving}
													>
														{t('Save')}
											</Button>
												</ButtonGroup>
											)}
										/>
										<Page
											title={title}
											breadcrumbs={[{content: t('Products'), onAction: () => navigate('/products')}]}
										>
											<Layout>
												<Layout.Section>
													<CardTitle error={mandatoryFields} />
													<CardSizes error={mandatoryFields} />
												</Layout.Section>
												<Layout.Section secondary>
													<CardStatus/>
													<CardOrganization/>
												</Layout.Section>
											</Layout>
											<PageActions
												primaryAction={{
													content: t('Save'),
													onAction: handleSave,
													loading: saving
												}}
												secondaryActions={[
													{
														content: t('Delete'),
														destructive: true,
														onAction: handleDelete

													}
												]}
											/>
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</HotKeys>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditProduct
