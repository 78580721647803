import React, {useContext, useEffect, useState} from 'react'
import {Card, FormLayout, TextField, Modal, Select} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {OrderContext, updateOrder} from "core/pages/orders/container"
import {useSubscription} from "global-state-hook"
import {itemInitalState} from "core/pages/orders/container"
import ResourceSelector from "components/resourceSelector"
import {useProducts} from "pages/products/container"
import {showToast} from "plugins/toast"

const AddItems = ({open, handleClose, initialState, currentIndex}) => {
	const [isNew, setIsNew] = useState(true)
	const [data, setData] = useState({...itemInitalState})
	const [loading, setLoading] = useState(false)
	const orderSub = useContext(OrderContext)
	const {setState, state} = useSubscription(orderSub)

	const {line_items, _id, order_status} = state

	useEffect(() => {
		setData({...initialState})
		if (initialState.item_id) setIsNew(false)
	}, [initialState])

	const {t} = useTranslation()

	const {item_id, design, quantity, mockup, laser, color} = data
	const handleSave = () => {
		setLoading(true)
		if (!isNew) {
			line_items[currentIndex] = {...data}
		}
		if (_id) {
			updateOrder({_id: _id, order_status: order_status, line_items: isNew ? [...line_items, {...data}] : line_items}).then((res) => {
				const result = res.data
				const {data, success} = result
				if (res && result && success) {
					const {width, height, length, weight} = data
					setState({line_items: data.line_items, width: width, height: height, weight: weight, length: length})
					showToast({
						message: 'Cập nhật thành công'
					})
				} else {
					showToast({
						message: 'Cập nhật không thành công, vui lòng thử lại',
						error: true
					})
				}

			})
				.catch((error) => console.log(error))
				.finally(() => {
					setLoading(false)
					handleClose()
				})
		} else {
			if (isNew) {
				setState({line_items: [...line_items, {...data}]})
			}
			setLoading(false)
			handleClose()
		}
	}
	console.log(isNew, 'isNew')
	return (
		<Modal
			title={isNew ? 'Thêm sản phẩm' : 'Sửa sản phẩm'}
			open={open}
			onClose={handleClose}
			primaryAction={{
				content: t(isNew ? 'Thêm' : 'Cập nhật'),
				onAction: handleSave,
				disabled: !item_id || !quantity || !mockup || !design,
				loading: loading

			}}
			secondaryActions={[
				{
					content: t('Cancel'),
					onAction: handleClose
				}
			]}
		>
			<Card>
				<Card.Section>
					<FormLayout>
						<FormLayout.Group>
							<ResourceSelector
								label={'Loại sản phẩm'}
								source={useProducts}
								getData={'item_id'}
								placeholder={t('Chọn loại sản phẩm')}
								value={item_id}
								show_id={true}
								onChange={v => setData({...data, item_id: v})}
							/>
							<TextField
								type={'number'}
								label={'Số lượng'}
								value={quantity.toString()}
								min={1}
								onChange={v => {
									setData({...data, quantity: parseInt(v)})
								}}
							/>
						</FormLayout.Group>
						<TextField
							label={'Link thiết kế'}
							value={design}
							placeholder={'http://'}
							onChange={v => {
								setData({...data, design: v})
							}}
						/>
						<TextField
							label={'Link mockup'}
							value={mockup}
							placeholder={'http://'}
							onChange={v => {
								setData({...data, mockup: v})
							}}
						/>
						<TextField
							label={'Link laser'}
							value={laser}
							placeholder={'http://'}
							helpText={'Chỉ dành cho leather'}
							onChange={v => {
								setData({...data, laser: v})
							}}
						/>
						<TextField
							label={'Màu sắc'}
							value={color}
							placeholder={'Ví dụ: Nâu'}
							helpText={'Chỉ dành cho leather'}
							onChange={v => {
								setData({...data, color: v})
							}}
						/>
					</FormLayout>
				</Card.Section>
			</Card>
		</Modal>
	)
}
export default AddItems
