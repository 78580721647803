import React, {useContext} from 'react'
import {Card, TextField, FormLayout} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import {useSubscription} from "global-state-hook";
import {ProductContext} from "../container"

export default function CardSizes({error}) {
	const {t} = useTranslation()

	const pageSub = useContext(ProductContext)
	let {setState, state} = useSubscription(pageSub)
	const {width, weight, height, length} = state
	return (
		<Card sectioned title={t('Kích thước & cân nặng')}>
			<FormLayout>
				<FormLayout.Group condensed>
					<TextField
						type={'number'}
						suffix={'mm'}
						label={'Dài'}
						value={length}
						onChange={v => setState({length: v})}
						error={error.includes('length')}
					/>
					<TextField
						type={'number'}
						suffix={'mm'}
						label={'Rộng'}
						value={width}
						onChange={v => setState({width: v})}
						error={error.includes('width')}
					/>
					<TextField
						type={'number'}
						suffix={'mm'}
						label={'Cao'}
						value={height}
						onChange={v => setState({height: v})}
						error={error.includes('height')}
					/>
				</FormLayout.Group>

				<FormLayout.Group>
					<TextField
						type={'number'}
						suffix={'gram'}
						label={'Trọng lượng'}
						value={weight}
						onChange={v => setState({weight: v})}
						error={error.includes('weight')}
					/>
				</FormLayout.Group>
			</FormLayout>
		</Card>
	)
}
