import React from 'react'
import {Router} from "@reach/router"
import LoginPage from "authentication/login"

export default function AuthPages() {
  return (
	<Router>
	  <LoginPage path="/login" />
	</Router>
  )
}